import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./Sitemap.scss";

function Sitemap() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box className="pagesWrapper">
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h2 className="mb-6">Sitemap</h2>
          </Grid>
          <Grid md={4}>
            <h5>Company</h5>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT_US);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRIVACY_POLICY);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION);
                  }}
                >
                  Refunds & Cancellation
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.DISCLAIMER);
                  }}
                >
                  Disclaimer
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid md={4}>
            <h5>Services</h5>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CONTACT_US);
                  }}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CLAIMS);
                  }}
                >
                  Free Claim Assistance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CARRIER);
                  }}
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.NEED_HELP);
                  }}
                >
                  Need Help?
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid md={4}>
            <h5>Products</h5>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                  }}
                >
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_CAR);
                  }}
                >
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TW);
                  }}
                >
                  Two-Wheeler Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TERM);
                  }}
                >
                  Term Insurance
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Sitemap;
