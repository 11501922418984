
import HomeInsuranceForm from "../../../Pages/Desktop/HomeInsurance/Form/HomeInsuranceForm";
import MHomeInuranceForm from "../../../Pages/Mobile/HomeInsurance/FormPage/MHomeInsranceForm";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function HIFormContainer() {
  const isMobile = useIsMobile();


  return (
    <>
      {isMobile ? (
        <MHomeInuranceForm />
      ) : (
        <HomeInsuranceForm />
      )}
    </>
  );
}

export default HIFormContainer;
