import axios, { AxiosResponse, Canceler } from "axios";
import { toast } from "react-toastify";
import { SESSION_CONSTANTS } from "../Services/Constants/SessionConstants";
import { generateRandomNumberString } from "../SupportingFiles/HelpingFunction";
import { CommonURLs } from "../URLCollection/Common/CommonURLs";

async function generateAccessToken(
  method: "GET" | "POST",
  DTO: any,
  url: string,
  onSuccess: Function,
  onError: Function
) {
  const tokenEndpoint: string = CommonURLs.GENERATE_TOKEN;

  const username: string | undefined = process.env.REACT_APP_USERNAME;
  const password: string | undefined = process.env.REACT_APP_PASSWORD;
  try {
    const authHeader: string = `Basic ${btoa(`${username}:${password}`)}`;
    const session_id: string = generateRandomNumberString(10);
    const response: AxiosResponse = await axios.post(tokenEndpoint, null, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authHeader,
        UserSessionId: session_id,
      },
    });

    const accessToken: string = response.data.results.response;

    if (accessToken && session_id) {
      sessionStorage.setItem(SESSION_CONSTANTS.ACCESS_TOKEN, accessToken);
      sessionStorage.setItem(SESSION_CONSTANTS.SESSION_ID, session_id);

      if (method === "POST") {
        PostAPI.call(url, DTO, onSuccess, onError);
      } else {
        GetAPI.call(url, DTO, onSuccess, onError);
      }
      // callback();
    } else {
      console.log("Access token or session ID not retrieved properly");
    }
  } catch (error) {
    console.error("Error generating access token:", error);
  }
}

const { CancelToken } = axios;

/**
 * Axios API instance wrapper with cancellation and error handling.
 */
export default class AxiosInstanceToken {
  public api: any;
  public cancelApi: Canceler;

  /**
   * Constructs an instance of AxiosInstanceToken.
   */
  constructor() {
    const token = sessionStorage.getItem(SESSION_CONSTANTS.ACCESS_TOKEN);
    const session_id = sessionStorage.getItem(SESSION_CONSTANTS.SESSION_ID);
    if (!token) {
      // Redirect the user to the login page or handle unauthorized access
      // window.location.pathname = ALL_ROUTES.PROPOSAL_PAGE;
    }

    if (!token || !session_id) {
      console.log("Access token or session ID not retrieved properly");
    }

    const source = CancelToken.source();

    this.api = axios.create({
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AccessToken: `${token}`,
        UserSessionId: session_id,
      },
    });

    this.api.interceptors.request.use(
      (config: any) => {
        // Add the authorization token to the headers
        // config.headers["AccessToken"] = `${token}`;
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        const originalRequest = error.config;
        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          // Refresh the token using an authentication service
          // return AUTH_SERVICE.getRefreshToken().then(
          //   (newToken) => {
          //     // Update the authorization header with the new token
          //     originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

          //     // Retry the original request
          //     return axios(originalRequest);
          //   },
          //   (refreshError) => {
          //     // Handle the error when refreshing the token fails
          //     return Promise.reject(refreshError);
          //   }
          // );
        }

        return Promise.reject(error);
      }
    );

    this.cancelApi = source.cancel;
  }
}

export { CancelToken };

/**
 * Utility class for making POST requests using Axios.
 */
export class PostAPI {
  /**
   * Makes a POST request to the specified URL.
   * @param url - The URL to make the request to.
   * @param dto - The data to send in the request body.
   * @param onSuccess - The callback function to execute on successful response.
   * @param config - Additional Axios request configuration.
   * @param onError - The callback function to execute on error.
   * @param onFinal - The callback function to execute after the request is completed (success or error).
   * @returns The canceler function to cancel the request.
   */
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosInstanceToken();
    api.api
      .post(url, dto)
      .then((res: any) => {
        const error = res?.data?.results?.error;
        const message = res?.data?.results?.message;

        if (
          error &&
          (message === "AccessToken Expired" ||
            message === "UserSessionId required")
        ) {
          generateAccessToken("POST", dto, url, onSuccess, onError);
        } else {
          onSuccess(res);
        }
      })
      .catch((err: any) => {
        console.log(err);
        const error = err?.response?.data?.error;
        if (error) {
          const message = err?.response?.data?.message;
          toast.error(message);
        } else {
          const message = err?.code;
          toast.error(message);
        }

        onError(err);
        // Handle the error using React Toastify
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

/**
 * Utility class for making GET requests using Axios.
 */
export class GetAPI {
  /**
   * Makes a GET request to the specified URL.
   * @param url - The URL to make the request to.
   * @param onSuccess - The callback function to execute on successful response.
   * @param config - Additional Axios request configuration.
   * @param onError - The callback function to execute on error.
   * @param onFinal - The callback function to execute after the request is completed (success or error).
   * @returns The canceler function to cancel the request.
   */
  static call(
    url: string,
    query?: any,
    onSuccess: Function = () => {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosInstanceToken();
    api.api
      .get(url, query)
      .then((res: any) => {
        const error = res?.data?.results?.error;
        const message = res?.data?.results?.message;
        if (
          error &&
          (message === "AccessToken Expired" ||
            message === "UserSessionId required")
        ) {
          generateAccessToken("GET", query, url, onSuccess, onError);
        } else {
          onSuccess(res);
        }
      })
      .catch((err: any) => {
        onError(err);
        // Handle the error using React Toastify
        toast.error(err?.response?.data?.message ?? err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class PutAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosInstanceToken();
    api.api
      .put(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class PatchAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosInstanceToken();
    api.api
      .patch(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class DeleteAPI {
  static call(
    url: string,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosInstanceToken();
    api.api
      .delete(url, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}
