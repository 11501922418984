import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./CustomCheckbox.scss"; // Import the CSS module

const CustomCheckbox = ({
  label,
  defaultChecked = false,
  value,
  value_update,
  attrName,
  disabled,
  selectedValue,
  checked,
  isChecked,
}: {
  label: string | any;
  defaultChecked?: boolean;
  value?: boolean | string;
  attrName?: any;
  value_update?: Function;
  disabled?: boolean;
  selectedValue?: string;
  checked?: boolean;
  isChecked?: boolean;
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={label}
          disabled={disabled}
          defaultChecked={defaultChecked}
          icon={
            <span
              style={{
                borderWidth: 1,
                borderRadius: 3,
                borderColor: "#000000",
                borderStyle: "solid",
                height: "18px",
                width: "18px",
              }}
            />
          } // Apply inline styles to the icon
          checkedIcon={
            <span
              className="checkIcon"
              style={{
                background: "#00B587",
                borderWidth: 1,
                borderRadius: 3,
                borderColor: "#00B587",
                borderStyle: "solid",
                height: "18px",
                width: "18px",
              }}
            />
          } // Apply inline styles to the checked icon
          checked={isChecked}
          onChange={(e) => {
            value_update && value_update(attrName, e.target.checked);
          }}
        />
      }
      label={label}
      className="checkbox" // Apply the custom styles from the CSS module
      value={selectedValue}
    />
  );
};

export default CustomCheckbox;
