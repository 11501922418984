import { Helmet } from "react-helmet";
import { FRONTEND_DOMAIN } from "../URLCollection/Domain";
import { useLocation } from "react-router-dom";

const HelmetComponent = ({
  title = "",
  description,
  name = "description",
  showCanonicalTag,
}: {
  title?: string;
  description?: string;
  name?: string;
  showCanonicalTag?: boolean ;
}) => {
  const location = useLocation();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name={name} content={description} />
      <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
      {showCanonicalTag && <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} /> }
    </Helmet>
  );
};

export default HelmetComponent;
