import { ChangeEvent, useRef, useState } from "react";
import Careers from "../../Pages/Desktop/CMS/Careers/Careers";
import MCareers from "../../Pages/Mobile/CMS/MCareers/MCareers";
import { HOME_SERVICES } from "../../Services/Home/HomeServices";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CarrierConatiner() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [complaintFields, setcomplaintFields] = useState<{
    username: { value: string; warning: boolean };
    number: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    productName: { value: string; warning: boolean };
  }>({
    username: { value: "", warning: false },
    number: { value: "", warning: false },
    email: { value: "", warning: false },
    productName: { value: "", warning: false },
  });
  const [selectedFileName, setSelectedFileName] = useState<any>(null);
  const [base64String, setBase64String] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileChange = (e: any) => {
    setSelectedFileName(e.target.files[0].name)
    setBase64String(e.target.files[0])
  }

  const validate_Form = () => {
    let hasError = false; // Initialize hasError to false
    let data = { ...complaintFields };
    console.log("complaintFields", data.username.warning);
    data = {
      ...data,
      username: {
        ...data.username,
        warning: !validateFullName(data.username.value),
      },
      number: {
        ...data.number,
        warning: !validateMobileNumber(data.number.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      productName: {
        ...data.productName,
        warning: isEmpty(data.productName.value),
      },
    };
    setcomplaintFields(data);

    // Check if any field has a warning (i.e., validation error)
    if (
      data.username.warning ||
      data.email.warning ||
      data.number.warning ||
      data.productName.warning
    ) {
      hasError = true; // Set hasError to true if any field has a warning
    }

    if (!hasError && base64String) {
      Career_Data(); // Call Career_Data if validation passes
    }
    if (selectedFileName === "") {
      toast.error("Select a file ");
    }
  };

  const Career_Data = () => {
    const toastID = toast.loading("Please wait...");
    const formData = new FormData();
    formData.append('username', complaintFields.username.value);
    formData.append('email', complaintFields.email.value);
    formData.append('number', complaintFields.number.value);
    formData.append('files', base64String);
    formData.append('productName', complaintFields.productName.value);

    const onSuccess = (data: any) => {
      console.log("fgnfgnnfggn")
      navigate("/");
      const results = data?.results;
      const error = results?.error;
      const message = results?.message;
      toast.dismiss(toastID);
      if (error) {
        toast.error(`${message}`);
      } else {
        setcomplaintFields({
          username: { value: "", warning: false },
          number: { value: "", warning: false },
          email: { value: "", warning: false },
          productName: { value: "", warning: false },
        });
        toast.success(
          "Thank you for your query! Our team will reach out to you shortly."
        );
      }
    };
    const onError = (data: any) => { };
    HOME_SERVICES.SUBMIT_CAREER(onSuccess, onError, formData);
  };

  const applyfor_data = [
    {
      label: "Business Development Officer",
      value: "Business Development Officer",
    },
    { label: "Sales Officer", value: "Sales Officer" },
  ];

  const fieldsUpdateState = (attrName: any, value: any) => {
    console.log(attrName, "attrName", value)
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: {
        ...[attrName[0]],
        [attrName[1]]: value,
        warning:
          attrName[0] === "number"
            ? !validateMobileNumber(value)
            : attrName[0] === "email"
              ? !validateEmail(value)
              : attrName[0] === "username"
                ? !validateFullName(value)
                : isEmpty(value),
      },
    });
  };

  return isMobile ? (
    <MCareers
      validate_Form={validate_Form}
      complaintFields={complaintFields}
      fieldsUpdateState={fieldsUpdateState}
      applyfor_data={applyfor_data}
      setcomplaintFields={setcomplaintFields}
      handleFileSelect={handleFileSelect}
      handleFileChange={handleFileChange}
      selectedFileName={selectedFileName}
      fileInputRef={fileInputRef}
    />
  ) : (
    <Careers
      validate_Form={validate_Form}
      complaintFields={complaintFields}
      fieldsUpdateState={fieldsUpdateState}
      applyfor_data={applyfor_data}
      setcomplaintFields={setcomplaintFields}
      handleFileSelect={handleFileSelect}
      handleFileChange={handleFileChange}
      selectedFileName={selectedFileName}
      fileInputRef={fileInputRef}
    />
  );
}

export default CarrierConatiner;
