import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import { useAppSelector } from "../../../../Store/hooks";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import "./ContactUs.scss";

function ContactUs({
  complaintFields,
  setcomplaintFields,
  validateForm,
  loader,
  fieldsUpdateState,
  interestedin_data,
}: {
  complaintFields: any;
  setcomplaintFields: Function;
  validateForm: Function;
  loader: any;
  fieldsUpdateState: Function;
  interestedin_data: any;
}) {
  const { footer } = useAppSelector((state) => state.Home);

  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      <Navbar />
      <div className="contact-parent">
        <div className="contact-us-parent">
          <div className="contact-us">
            <div className="contact-us1">
              <h1>Contact Us</h1>
            </div>
          </div>
          <div className="were-here-to">
            We're here to listen, assist and collaborate with you!
          </div>
        </div>
        <div className="frame-wrapper">
          <div className="frame-group">
            <div className="frame-container">
              <div className="g158-wrapper">
                <img
                  className="g158-icon"
                  alt=""
                  src="./images/contact_main_img.svg"
                />
              </div>
              <div className="frame-div">
                <div className="support-mail-parent">
                  <div className="support-mail">Support mail:</div>
                  <div className="carebimastreetcom">
                    {footer.support_email}
                  </div>
                </div>
                <div className="other-query-parent">
                  <div className="support-mail">Other Query:</div>
                  <div className="carebimastreetcom">
                    {footer.for_other_query}
                  </div>
                </div>
                <div className="phone-number-parent">
                  <div className="support-mail">Phone Number:</div>
                  <div className="div">{footer.phone_number}</div>
                </div>
              </div>
            </div>
            <div className="frame-wrapper1">
              <div className="bimastreet-amaze-insurance-bro-parent">
                <div className="bimastreet-amaze-insurance-container">
                  <p className="bimastreet">{footer.company_name}</p>
                  <p className="bimastreet">{footer.product_name}</p>
                </div>
                <div className="the-legend-metro">
                  <span style={{ fontWeight: 600 }}>Corporate Office :-</span>{" "}
                  {footer.address}
                </div>
              </div>
            </div>
            <div className="contact-parent1">
              <div className="fill-the-details-parent">
                <div className="bimastreet-amaze-insurance-container">
                  Fill the Details
                </div>
                <div className="share-your-information">
                  Share your information and expect a swift response!
                </div>
              </div>
              <div className="input-field1">
                <RKTextField
                  class_name="inputField"
                  title={"Full Name"}
                  validation_type="NAME"
                  value={complaintFields.fullname.value}
                  attrName={[
                    "fullname",
                    "value",
                    complaintFields,
                    setcomplaintFields,
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={complaintFields.fullname.warning}
                  error_message={
                    isEmpty(complaintFields.fullname.value)
                      ? "Enter Full Name"
                      : "Enter Valid Full Name"
                  }
                />
              </div>
              <div className="input-field-parent">
                <div className="input-field2">
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={complaintFields.mobile.value}
                    attrName={[
                      "mobile",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.mobile.warning}
                    error_message={
                      isEmpty(complaintFields.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    validation_type="NUMBER"
                    max_length={10}
                  />
                </div>
                <div className="input-field2">
                  <RKTextField
                    class_name="inputField"
                    title={"Email"}
                    value={complaintFields.email.value}
                    attrName={[
                      "email",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.email.warning}
                    error_message={
                      isEmpty(complaintFields.email.value)
                        ? "Ener Email"
                        : "Enter Valid Email"
                    }
                  />
                </div>
              </div>
              <div className="input-field1">
                <SelectDropdown
                  class_name="inputField"
                  title="Interested In"
                  value={complaintFields.interestedin.value}
                  attrName={[
                    "interestedin",
                    "value",
                    complaintFields,
                    setcomplaintFields,
                  ]}
                  value_update={fieldsUpdateState}
                  data={interestedin_data}
                  warn_status={complaintFields.interestedin.warning}
                  error_message="Select Interested In"
                />
              </div>
              <div className="ctaBtn">
                <Buttons
                  class_name="greenLgBtn"
                  text_name="Submit Details"
                  loading={loader}
                  onClickFunction={() => {
                    validateForm();
                  }}
                />
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1903.1510371637585!2d78.468154!3d17.4452502!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb974284c44b61%3A0x6e5314445adadd92!2sAmaze%20Insurance%20Brokers%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1723444565472!5m2!1sen!2sin"
            width="100%"
            height="450px"
            loading="lazy"
            style={{ border: "0", borderRadius: "12px", marginTop: "60px" }}
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
