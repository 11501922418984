export enum EHomeSectionName {
  HEADER = "header",
  AWARDS = "awards",
  HOW_IT_WORKS = "how_it_works",
  OUR_CLIENTS = "our_clients",
  OUR_PARTNERS = "our_partners",
  OUR_PRODUCTS = "our_products",
  TESTIMONIALS = "testimonials",
  TOP_RATED = "top_rated",
  FOOTER = "footer",
}
export const ECompanyCode = {
  GO_DIGIT: "G033",
  IFFCO_TOKIO: "G003",
  LIBERTY_VIDEOCON: "G030",
  UNIVERSAL_SOMPO: "G018",
  KOTAK: "G031",
  FUTURE_GENERALI: "G017",
  STAR: "G015",
  CARE: "G025",
  ICIC:"G002"
};
