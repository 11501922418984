import { createSlice } from "@reduxjs/toolkit";
import { TPirSlice } from "../../../Type/PIR/TPir";
import { PIR_REDUCERS } from "./PirReducer";

const initialState: TPirSlice = {
  PERSONAL: {
    firstname: { value: "", warning: false },
    lastname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    pincode: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    incomeAmount: { value: 0, warning: false },
  },
  INSUREDFOR: {
    insuredMember: [
      { value: "Self", warning: false },
      { value: "Spouse", warning: false },
      { value: "Parents", warning: false },
      { value: "Childrens", warning: false },
    ],
  },
  FINANCIAL: {
    incomeAmount: { value: 0, warning: false },
    monthlyExpenses: { value: 0, warning: false },
    // loans: {
    outstandingLoans: { value: "No", warning: false },
    outstandingAmount: { value: 0, warning: false },
    // },
    // invesments: {
    investments: { value: "No", warning: false },
    investmentsAmount: { value: 0, warning: false },
    // },
  },
  INSURANCEPOLICY: {
    healthInsurance: { value: "No", warning: false },
    healthCoverageAmount: { value: 0, warning: false },

    termInsurance: { value: "No", warning: false },
    termCoverageAmount: { value: 0, warning: false },
    smoke: { value: "No", warning: false },
  },
  OWNHOUSE: {
    ownHouse: { value: "", warning: false },
    houseValueAmount: { value: 0, warning: false },
    householdItemsAmount: { value: 0, warning: false },
  },
  HAVEANYVEHICLE: {
    haveAnyVehicle: {
      value: "No",
      warning: false,
    },
  },
  HAVEVEHICLE: [
    {
      haveAnyVehicle: "",
      vehicleType: { value: "", warning: false },
      makeModel: { value: "", warning: false },
      fuelType: { value: "", warning: false },
      variantCC: { value: "", warning: false },
      registrationDate: { value: "", warning: false },
      policyExpiryDate: { value: "", warning: false },
      regNo: { value: "", warning: false },
      previousInsurer: { value: "", warning: false },
      make_model_data: [],
      fuel_type_data: [],
      variant_data: [],
    },
  ],
  RELATION: {
    adult: {
      value: "1A",
      warning: false,
    },
    haveChildren: {
      value: "No",
      warning: false,
    },
    childrenCount: {
      value: 0,
      warning: false,
    },
    second_adult_dob: {
      value: "",
      warning: false,
    },
    child_1_dob: {
      value: "",
      warning: false,
    },
    child_2_dob: {
      value: "",
      warning: false,
    },
    child_3_dob: {
      value: "",
      warning: false,
    },
    child_4_dob: {
      value: "",
      warning: false,
    },
    relation: {
      value: "",
      warning: false,
    },
    child_1_relation: {
      value: "",
      warning: false,
    },
    child_2_relation: {
      value: "",
      warning: false,
    },
    child_3_relation: {
      value: "",
      warning: false,
    },
    child_4_relation: {
      value: "",
      warning: false,
    },
  },
  step: 0,
  sumInsured: 0,
  termSumInsured: 0,
};

export const PirSlice = createSlice({
  name: "PirSlice",
  initialState,
  reducers: PIR_REDUCERS,
});

export const {
  PIR_DATA,
  PIR_DATA_SUMINSURED,
  PIR_RESET_DATA,
  PIR_DATA_PERSONAL,
  PIR_DATA_INSURANCE_FOR,
  PIR_DATA_FINANCIAL,
  PIR_DATA_INSURANCE_POLICY,
  PIR_DATA_OWN_HOUSE,
  PIR_DATA_VEHICLE,
  PIR_STEP,
  PIR_DATA_SUMINSURED_TERM,
  PIR_UPDATE_REPORT,
  PIR_UPDATE_EMAIL_IN_REPORT,
} = PirSlice.actions;

export default PirSlice.reducer;
