import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import ToggleSwitch from "../../../../Component/Common/ToggleSwitch/ToggleSwitch";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import {
  formatCurrencyAccToUser,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { PIR_DATA_OWN_HOUSE } from "../../../../Store/Slice_Reducer/PIR/PirSlice";
import { toast } from "react-toastify";
interface IProperty {
  // step: (s: (v: number) => number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
}
const PIRProperty = ({ incrementStep, decrementStep }: IProperty) => {
  const dispatch = useAppDispatch();
  const { OWNHOUSE } = useAppSelector((state) => state.PIR);

  const [ownHouse, setOwnHouse] = useState<{
    ownHouse: { value: string; warning: boolean };
    houseValueAmount: { value: number; warning: boolean };
    householdItemsAmount: { value: number; warning: boolean };
  }>({
    ownHouse: { value: "", warning: false },
    houseValueAmount: { value: 0, warning: false },
    householdItemsAmount: { value: 0, warning: false },
  });
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  useEffect(() => {
    setOwnHouse(OWNHOUSE);
    setIsBoxOpen(OWNHOUSE.ownHouse.value == "Owned" || "Rented" ? true : false);
  }, [OWNHOUSE]);

  const isBoxSelected2 = (boxName: string) => {
    return ownHouse.ownHouse.value.includes(boxName);
  };
  const handleBoxClick11 = (option: string) => {
    switch (option) {
      case "Owned":
        // setLoad(false);
        // toast.dismiss("");
        setOwnHouse((prevOwnHouse) => ({
          ...prevOwnHouse,
          ownHouse: { value: "Owned", warning: false },
        }));
        setIsBoxOpen(true);
        break;
      case "Rented":
        // setLoad(false);
        setOwnHouse((prevOwnHouse) => ({
          ...prevOwnHouse,
          ownHouse: { value: "Rented", warning: false },
          houseValueAmount: {
            value: 0,
            warning: false,
          },
        }));
        setIsBoxOpen(true);
        break;
      default:
        break;
    }
  };
  const updateMasterState = (attrName: string[], value: string) => {
    let newValue: string | number = value;
    if (["houseValueAmount", "householdItemsAmount"].includes(attrName[1])) {
      newValue = value.replaceAll(",", "");
      const data = newValue.split(" ");

      if (data[1]) {
        newValue = +data[1];
      }
    }

    // if (["houseValueAmount", "householdItemsAmount"].includes(attrName[1])) {
    //   const data = value.split(" ");

    //   if (data[1]) {
    //     newValue = +data[1];
    //   }
    // }
    if (attrName[0] === "ownHouse") {
      setOwnHouse((prev) => ({
        ...prev,
        [attrName[1]]: { value: newValue, warning: isEmpty(value) },
      }));
    }
  };
  const handlePrevious = () => {
    decrementStep();
  };

  const handleContinue = () => {
    let local_own_house_state = { ...ownHouse };
    local_own_house_state.ownHouse = {
      ...local_own_house_state.ownHouse,
      warning: isEmpty(ownHouse.ownHouse.value),
    };
    if (local_own_house_state.ownHouse.warning) {
      //   setLoad(true);
      toast.error("Please select one of them");
    }
    // setLoad(false);
    if (local_own_house_state.ownHouse.value === "Rented") {
      local_own_house_state = {
        ...local_own_house_state,
        houseValueAmount: {
          ...local_own_house_state.houseValueAmount,
          value: 0,
        },
      };
    }
    if (local_own_house_state.ownHouse.value === "Rented") {
      // debugger;
      if (isEmpty(local_own_house_state.householdItemsAmount.value)) {
        // debugger;
        local_own_house_state = {
          ...local_own_house_state,
          householdItemsAmount: {
            ...local_own_house_state.householdItemsAmount,
            warning: true,
          },
        };
      }
    }
    if (local_own_house_state.ownHouse.value === "Owned") {
      // debugger;
      //   if (isEmpty(local_own_house_state.householdItemsAmount.value)) {
      //     // debugger;
      //     local_own_house_state = {
      //       ...local_own_house_state,
      //       householdItemsAmount: {
      //         ...local_own_house_state.householdItemsAmount,
      //         warning: true,
      //       },
      //     };
      //   }
      if (isEmpty(local_own_house_state.houseValueAmount.value)) {
        // debugger;
        local_own_house_state = {
          ...local_own_house_state,
          houseValueAmount: {
            ...local_own_house_state.houseValueAmount,
            warning: true,
          },
        };
      }
    }
    let isValid = Object.values(local_own_house_state).every(
      (field) => !field.warning
    );
    setOwnHouse(local_own_house_state);

    // return isValid;
    if (isValid) {
      // step((s: number) => s + 1);

      dispatch(PIR_DATA_OWN_HOUSE(ownHouse));
      incrementStep();
    }
  };
  const handleSkip = () => {
    incrementStep();
  };
  const formatCurrency = (value: number) => {
    const stringValue = value.toString();
    const ruppeeValue = "₹ ";
    if (stringValue === "" || stringValue === "0") {
      return ruppeeValue.concat("0");
    }
    return ruppeeValue.concat(stringValue);
    // return `${("₹", value.toString())}`;
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <h2>Do you own a house or rent one?</h2>
          <p className="mt-4">"If yes, please provide your house details."</p>

          <Box margin="24px -16px">
            <Grid
              container
              spacing={3}
              // rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems="center"
            >
              <Grid xs={12} display="flex" gap="12px">
                <Box
                  className={`memberBox ${
                    isBoxSelected2("Owned") ? "active" : ""
                  }`}
                  onClick={() => handleBoxClick11("Owned")}
                >
                  <Box className="checkbox" />
                  <img src="./images/ownhouse.svg" alt="OwnHouse" />
                  Own House
                </Box>
                <Box
                  className={`memberBox ${
                    isBoxSelected2("Rented") ? "active" : ""
                  }`}
                  onClick={() => handleBoxClick11("Rented")}
                >
                  <Box className="checkbox" />
                  <img src="./images/rentedhouse.svg" alt="Rented" />
                  Rented
                </Box>
              </Grid>
              <Grid xs={12}>
                <p style={{ fontWeight: "500" }}>Enter your house details</p>
              </Grid>
              {isBoxOpen && isBoxSelected2("Owned") && (
                <>
                  <Grid xs={12} sm={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Value of your house (Rs.)"}
                      validation_type="NUMERIC"
                      // value={formatCurrencyAccToUser(
                      //   ownHouse.houseValueAmount.value
                      // )}
                      value={
                        ownHouse.houseValueAmount.value
                          ? formatCurrencyAccToUser(
                              ownHouse.houseValueAmount.value
                            )
                          : ""
                      }
                      attrName={["ownHouse", "houseValueAmount"]}
                      value_update={updateMasterState}
                      warn_status={ownHouse.houseValueAmount.warning}
                      error_message={" Enter house value"}
                      min_length={9}
                      max_length={18}
                      required
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Value of your household items (Rs.)"}
                      value={
                        ownHouse.householdItemsAmount.value
                          ? formatCurrencyAccToUser(
                              ownHouse.householdItemsAmount.value
                            )
                          : ""
                      }
                      validation_type="NUMERIC"
                      attrName={["ownHouse", "householdItemsAmount"]}
                      value_update={updateMasterState}
                      warn_status={ownHouse.householdItemsAmount.warning}
                      error_message={" Enter household value"}
                      min_length={9}
                      max_length={18}
                    />
                  </Grid>
                </>
              )}
              {isBoxOpen && isBoxSelected2("Rented") && (
                <Grid xs={12} sm={6}>
                  <RKTextField
                    class_name="inputField"
                    value={
                      ownHouse.householdItemsAmount.value
                        ? formatCurrencyAccToUser(
                            ownHouse.householdItemsAmount.value
                          )
                        : ""
                    }
                    title={"Value of your household items (Rs.)"}
                    validation_type="NUMERIC"
                    attrName={["ownHouse", "householdItemsAmount"]}
                    value_update={updateMasterState}
                    warn_status={ownHouse.householdItemsAmount.warning}
                    error_message={" Enter householdItemsAmount value"}
                    min_length={9}
                    max_length={18}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} md={6} textAlign="center" className="mHide">
          <img src="./images/own-house.svg" width="100%" alt="img" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Box className="ctaBtn my-6 bottomButtons" display="flex" gap="24px">
          <Buttons
            onClickFunction={handlePrevious}
            class_name="textMdBtn"
            text_name="Previous"
          />
          <Buttons
            onClickFunction={handleContinue}
            class_name="greenMdBtn roundedBtn"
            text_name="Continue"
            loading={false}
          />
          <Buttons
            onClickFunction={handleSkip}
            class_name="textMdBtn"
            text_name="Skip"
          />
        </Box>
      </Grid>
    </>
  );
};

export default PIRProperty;
