import { TFieldData, TFieldDataURL } from "../Common/TFieldData";

export type ClaimUserData = {
  Common: {
    claimFor:
      | "Car Insurance"
      | "Health Insurance"
      | "Two Wheeler Insurance"
      | "Term Insurance";
    fullname: TFieldData;
    mobile: TFieldData;
    email: TFieldData;
  };
  CarIns: {
    claimType: "Own Damage Claim" | "Theft Claim" | "Third Party Claim";
    OwnDamage: {
      vehicleNumber: TFieldData;
      copyOfRC: TFieldDataURL;
      copyOfDrivingLicence: TFieldDataURL;
      copyOfComplaint: TFieldDataURL;
      copyOfPolicyDoc: TFieldDataURL;
      dateTimeOfAccident: TFieldData;
      placeOfAccident: TFieldData;
      driverAtTimeOfAccident: TFieldData;
      drivingLicenseNo: TFieldData;
      drivingLicenseValidUpto: TFieldData;
      typeOfDrivingLicense: TFieldData;
      reportedAtPoliceStation: boolean;
      insurer: TFieldData;
      policyNumber: TFieldData;
      policyStartDate: TFieldData;
      policyEndDate: TFieldData;
      descriptionAboutAccident: TFieldData;
      claimStatus: TFieldData;
      whatDoYouExpect: TFieldData;
      accidentPhotos: {warning: boolean; accidentPhotos: Array<any>};
    };
    Theft: {
      vehicleNumber: TFieldData;
      copyOfRC: TFieldDataURL;
      copyOfPolicyDoc: TFieldDataURL;
      copyOfComplaint: TFieldDataURL;
      copyOfFinalReport: TFieldDataURL;
      dateOfLossNoticed: TFieldData;
      placeOfAccident: TFieldData;
      insurer: TFieldData;
      policyNumber: TFieldData;
      policyStartDate: TFieldData;
      policyEndDate: TFieldData;
      descriptionAboutAccident: TFieldData;
      claimStatus: TFieldData;
      whatDoYouExpect: TFieldData;
    };
    ThirdParty: {
      vehicleNumber: TFieldData;
      copyOfRC: TFieldDataURL;
      copyOfDrivingLicence: TFieldDataURL;
      copyOfComplaint: TFieldDataURL;
      copyOfPolicyDoc: TFieldDataURL;
      dateTimeOfAccident: TFieldData;
      placeOfAccident: TFieldData;
      driverAtTimeOfAccident: TFieldData;
      dateOfLossNoticed: TFieldData;
      drivingLicenseNo: TFieldData;
      drivingLicenseValidUpto: TFieldData;
      typeOfDrivingLicense: TFieldData;
      reportedAtPoliceStation: boolean;
      insurer: TFieldData;
      policyNumber: TFieldData;
      policyStartDate: TFieldData;
      policyEndDate: TFieldData;
      descriptionAboutAccident: TFieldData;
      detailsOfThirdParty: TFieldData;
      deathOfThirdParty: TFieldData;
      detailsOfThirdPropertyDamage: TFieldData;
      claimStatus: TFieldData;
      whatDoYouExpect: TFieldData;
      accidentPhotos: {warning: boolean; accidentPhotos: Array<any>};
    };
  };
  HealthIns: {
    claimType: "Cashless" | "Reimbursement" | "Claim Partially Settled";
    cashLess: {
      claimStatus: "Rejected" | "Pending";
      customerType: "Bimastreet" | "Other";
      dateOfAdmission: TFieldData;
      dateOfDiscahrge: TFieldData;
      reasonForPending: TFieldData;
      reasonForRejection: TFieldData;
      dischargeSummary: TFieldData;
      finalBillBreakup: TFieldData;
      whatDoYouExpect: TFieldData;
      copyOfPolicyDoc: TFieldDataURL;
      copyOfHealthCard: TFieldDataURL;
      copyOfRejectionLetter: TFieldDataURL;
      copyOfCashReceipt: TFieldDataURL;
      copyOfInvestigationReport: TFieldDataURL;
      copyOfPharmacyBill: TFieldDataURL;
      copyOfCaseSheet: TFieldDataURL;
    };
    reimbursement: {
      claimStatus: "Pending" | "Rejected";
      customerType: "Bimastreet" | "Other";
      dateOfAdmission: TFieldData;
      dateOfDiscahrge: TFieldData;
      reasonForRejection: TFieldData;
      reasonForPending: TFieldData;
      dischargeSummary: TFieldData;
      finalBillBreakup: TFieldData;
      whatDoYouExpect: TFieldData;
      copyOfPolicyDoc: TFieldDataURL;
      copyOfHealthCard: TFieldDataURL;
      copyOfRejectionLetter: TFieldDataURL;
      copyOfCashReceipt: TFieldDataURL;
      copyOfInvestigationReport: TFieldDataURL;
      copyOfPharmacyBill: TFieldDataURL;
      copyOfCaseSheet: TFieldDataURL;
    };
    ClaimPartiallySettled: {
      claimStatus: "Pending" | "Rejected";
      customerType: "Bimastreet" | "Other";
      dateOfAdmission: TFieldData;
      dateOfDiscahrge: TFieldData;
      reasonForPending: TFieldData;
      reasonForRejection: TFieldData;
      dischargeSummary: TFieldData;
      finalBillBreakup: TFieldData;
      whatDoYouExpect: TFieldData;
      copyOfPolicyDoc: TFieldDataURL;
      copyOfRejectionLetter: TFieldDataURL;
      copyOfHealthCard: TFieldDataURL;
      copyOfCashReceipt: TFieldDataURL;
      copyOfInvestigationReport: TFieldDataURL;
      copyOfPharmacyBill: TFieldDataURL;
      copyOfCaseSheet: TFieldDataURL;
    };
  };
  TwIns: {
    claimType: "Own Damage Claim" | "Theft Claim" | "Third Party Claim";
    OwnDamage: {
      vehicleNumber: TFieldData;
      copyOfRC: TFieldDataURL;
      copyOfDrivingLicence: TFieldDataURL;
      copyOfComplaint: TFieldDataURL;
      copyOfPolicyDoc: TFieldDataURL;
      dateTimeOfAccident: TFieldData;
      placeOfAccident: TFieldData;
      driverAtTimeOfAccident: TFieldData;
      drivingLicenseNo: TFieldData;
      drivingLicenseValidUpto: TFieldData;
      typeOfDrivingLicense: TFieldData;
      reportedAtPoliceStation: boolean;
      insurer: TFieldData;
      policyNumber: TFieldData;
      policyStartDate: TFieldData;
      policyEndDate: TFieldData;
      descriptionAboutAccident: TFieldData;
      claimStatus: TFieldData;
      whatDoYouExpect: TFieldData;
      accidentPhotos: {warning: boolean; accidentPhotos: Array<any>};
    };
    Theft: {
      vehicleNumber: TFieldData;
      copyOfRC: TFieldDataURL;
      copyOfPolicyDoc: TFieldDataURL;
      copyOfComplaint: TFieldDataURL;
      copyOfFinalReport: TFieldDataURL;
      dateOfLossNoticed: TFieldData;
      placeOfAccident: TFieldData;
      insurer: TFieldData;
      policyNumber: TFieldData;
      policyStartDate: TFieldData;
      policyEndDate: TFieldData;
      descriptionAboutAccident: TFieldData;
      claimStatus: TFieldData;
      whatDoYouExpect: TFieldData;
    };
    ThirdParty: {
      vehicleNumber: TFieldData;
      copyOfRC: TFieldDataURL;
      copyOfDrivingLicence: TFieldDataURL;
      copyOfComplaint: TFieldDataURL;
      copyOfPolicyDoc: TFieldDataURL;
      dateTimeOfAccident: TFieldData;
      placeOfAccident: TFieldData;
      driverAtTimeOfAccident: TFieldData;
      drivingLicenseNo: TFieldData;
      drivingLicenseValidUpto: TFieldData;
      typeOfDrivingLicense: TFieldData;
      reportedAtPoliceStation: boolean;
      insurer: TFieldData;
      policyNumber: TFieldData;
      policyStartDate: TFieldData;
      policyEndDate: TFieldData;
      dateOfLossNoticed: TFieldData;
      descriptionAboutAccident: TFieldData;
      detailsOfThirdParty: TFieldData;
      deathOfThirdParty: TFieldData;
      detailsOfThirdPropertyDamage: TFieldData;
      claimStatus: TFieldData;
      whatDoYouExpect: TFieldData;
      accidentPhotos: {warning: boolean; accidentPhotos: Array<any>};
    };
  };
  termIns: {
    claimType: "Pending" | "Rejected";
    dateOfDeath: TFieldData;
    dateOfSubmisionOfDoc: TFieldData;
    reasonForPending: TFieldData;
    reasonForRejection: TFieldData;
    whatDoYouExpect: TFieldData;
    policyDoc: TFieldDataURL;
  };
};

export const insurerCompanies = [
  { label: "Acko General", value: "Acko General" },
  { label: "Bajaj Allianz", value: "Bajaj Allianz" },
  { label: "Bharti Axa", value: "Bharti Axa" },
  { label: "Cholamandalam", value: "Cholamandalam" },
  { label: "DHFL", value: "DHFL" },
  { label: "EDELWEISS", value: "EDELWEISS" },
  { label: "Future Generali", value: "Future Generali" },
  { label: "Go Digit", value: "Go Digit" },
  { label: "HDFC ERGO", value: "HDFC ERGO" },
  { label: "ICICI Lombard", value: "ICICI Lombard" },
  { label: "IFFCO TOKIO", value: "IFFCO TOKIO" },
  { label: "KOTAK", value: "KOTAK" },
  { label: "Liberty Videocon", value: "Liberty Videocon" },
  { label: "Magma HDI", value: "Magma HDI" },
  { label: "National Insurance", value: "National Insurance" },
  { label: "Navi Insurance", value: "Navi Insurance" },
  { label: "Raheja QBE", value: "Raheja QBE" },
  { label: "Reliance General", value: "Reliance General" },
  { label: "Royal Sundaram", value: "Royal Sundaram" },
  { label: "Shriram General", value: "Shriram General" },
  { label: "TATA AIG", value: "TATA AIG" },
  { label: "The New India", value: "The New India" },
  { label: "The Oriental", value: "The Oriental" },
  { label: "United Insurance", value: "United Insurance" },
  { label: "Universal Sompo", value: "Universal Sompo" },
];

export const typesOfDrivingLicense = [
  { label: "LMV", value: "LMV - Light goods vehicle" },
  {
    label: "Trailer",
    value: "Trailer - Heavy motor vehicle license holders can apply",
  },
  { label: "HMV ", value: "HMV - Heavy motor vehicles" },
  { label: "MGV", value: "MGV - Medium goods vehicle" },
  {
    label: "HPMV",
    value:
      "HPMV - Commercial purpose vehicles that come with an All India Permit",
  },
  { label: "HGMV", value: "HGMV - Large trucks and trailers" },
  {
    label: "MCWG",
    value: "MCWG - Motorcycles that come without and with gears",
  },
  {
    label: "MC EX50CC",
    value:
      "MC EX50CC - Motorcycles that come with gears and have an engine capacity of 50cc and above",
  },
  { label: "FVG", value: "FVG - Mopeds and scooters(without gears)" },
  {
    label: "LMV-NT",
    value:
      "LMV-NT - Motor cars and jeeps that are used for non-transport purposes",
  },
  {
    label: "MC 50CC",
    value:
      "MC 50CC - The engine capacity of the vehicle must be 50cc and below",
  },
];

export const typesOfClaimStatus = [
  { label: "Pending", value: "Pending" },
  { label: "Rejected", value: "Rejected" },
];
