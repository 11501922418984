import { PayloadAction } from "@reduxjs/toolkit";
import { TPaymentGateway } from "../../../Type/PaymentGateway/TPaymentGateway";

const UPDATE_DATA = (
  state: TPaymentGateway,
  action: PayloadAction<TPaymentGateway>
) => {
  const data: TPaymentGateway = {
    ...state,
    ...action.payload,
  };

  return data;
};

export const PAYMENT_GATEWAY_REDUCERS = {
  UPDATE_DATA,
};
