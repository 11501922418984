import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "../CMS.scss";
import { useAppSelector } from "../../../../Store/hooks";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

function TermsAndConditions() {
  const isMobile = useIsMobile();
  const { terms_condition } = useAppSelector((state) => state.CMS);
  const { footer } = useAppSelector((state) => state.Home);
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box className="cmsWrapper">
        <Grid container columnSpacing={3}>
          <Grid xs={12} textAlign="center">
            <h1>{terms_condition.title}</h1>
          </Grid>
          <Grid xs={12}>
            <p
              dangerouslySetInnerHTML={{
                __html: terms_condition.desc,
              }}
            />

            {terms_condition.data.data.map((data, index) => (
              <>
                <h3>{`${index + 1}) ${data.attributes.title}`}</h3>
                <p>{data.attributes.desc}</p>
                {data.attributes.data.map((sub_data, sub_index) => (
                  <p>
                    <span>{`${index + 1}.${sub_index + 1} ${
                      sub_data.title
                    }: `}</span>
                    {sub_data.desc}
                  </p>
                ))}
              </>
            ))}
            <p>
              <span>Bimastreet</span>
              <br />
              <span>Amaze Insurance Brokers Pvt. Ltd.</span>
              <br />
              <span>Corporate Office:</span>
              {footer.address}
            </p>
            <p>
              <span>Phone:</span> {footer.phone_number}
            </p>
            <p>{terms_condition.thankyou_message}</p>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </>
  );
}
export default TermsAndConditions;
