import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import ToggleSwitch from "../../../../Component/Common/ToggleSwitch/ToggleSwitch";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import {
  formatCurrencyAccToUser,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { PIR_DATA_INSURANCE_POLICY } from "../../../../Store/Slice_Reducer/PIR/PirSlice";
interface IInsurancePolicy {
  // step: (s: (v: number) => number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
}
const PIRInsurancePolicy = ({
  incrementStep,
  decrementStep,
}: IInsurancePolicy) => {
  const dispatch = useAppDispatch();
  const { INSURANCEPOLICY } = useAppSelector((state) => state.PIR);
  const [toggleStatus3, setToggleStatus3] = useState<boolean | undefined>(
    false
  );
  const [toggleStatus4, setToggleStatus4] = useState<boolean | undefined>(
    false
  );
  const [toggleStatus5, setToggleStatus5] = useState<boolean | undefined>(
    false
  );
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);

  const data = DROPDOWN_DATA.SUM_INSURED.map((e) => {
    return {
      label: `₹ ${e.label}`,
      value: `₹ ${e.value}`,
    };
  });

  const [insurancePolicy, setInsurancePolicy] = useState<{
    healthInsurance: { value: string; warning?: boolean };
    healthCoverageAmount: { value: number; warning: boolean };
    termInsurance: { value: string; warning?: boolean };
    termCoverageAmount: { value: number; warning: boolean };
    smoke: { value: string; warning: boolean };
  }>({
    healthInsurance: { value: "No" },
    healthCoverageAmount: { value: 0, warning: false },
    termInsurance: { value: "No" },
    termCoverageAmount: { value: 0, warning: false },
    smoke: { value: "No", warning: false },
  });
  useEffect(() => {
    setInsurancePolicy(INSURANCEPOLICY);
    setToggleStatus3(
      INSURANCEPOLICY.healthInsurance.value == "Yes" ? true : false
    );
    setToggleStatus4(
      INSURANCEPOLICY.termInsurance.value == "Yes" ? true : false
    );
    setToggleStatus5(INSURANCEPOLICY.smoke.value == "Yes" ? true : false);
  }, [INSURANCEPOLICY]);
  const updateToggleStatus = (attrName: any, value: boolean) => {
    switch (attrName) {
      case "attrName3":
        // case "attrName3":
        
        setToggleStatus3(value);
        setInsurancePolicy((prevFinancial) => {
          const newhealthInsuranceValue = value ? "Yes" : "No";
          const newhealthCoverageAmountValue = value
            ? prevFinancial.healthCoverageAmount.value
            : 0;
          let newWarning = value ? !!newhealthCoverageAmountValue : false;

          // if (newhealthCoverageAmountValue) {
          //   newWarning = false;
          // } else {
          //   newWarning = true;
          // }
          return {
            ...prevFinancial,
            healthInsurance: {
              ...prevFinancial.healthInsurance,
              value: newhealthInsuranceValue,
            },
            healthCoverageAmount: {
              ...prevFinancial.healthCoverageAmount,
              value: newhealthCoverageAmountValue,
              warning: newWarning,
            },
          };
        });
        break;

      // case "attrName4":
      //   setToggleStatus4(value);
      //   setInsurancePolicy((prevFinancial) => ({
      //     ...prevFinancial,
      //     termInsurance: {
      //       ...prevFinancial.termInsurance,
      //       value: value ? "Yes" : "No",
      //     },
      //   }));
      //   break;
      case "attrName4":
        // case "attrName3":
        setToggleStatus4(value);
        setInsurancePolicy((prevFinancial) => {
          const newhealthInsuranceValue = value ? "Yes" : "No";
          const newhealthCoverageAmountValue = value
            ? prevFinancial.termCoverageAmount.value
            : 0;
          let newWarning = value ? !!newhealthCoverageAmountValue : false;

          // if (newhealthCoverageAmountValue) {
          //   newWarning = false;
          // } else {
          //   newWarning = true;
          // }
          return {
            ...prevFinancial,
            termInsurance: {
              ...prevFinancial.termInsurance,
              value: newhealthInsuranceValue,
            },
            termCoverageAmount: {
              ...prevFinancial.termCoverageAmount,
              value: newhealthCoverageAmountValue,
              warning: newWarning,
            },
          };
        });
        break;
      case "attrName5":
        // case "attrName3":

        setToggleStatus5(value);
        setInsurancePolicy((prevFinancial) => {
          const newhealthInsuranceValue = value ? "Yes" : "No";

          // if (newhealthCoverageAmountValue) {
          //   newWarning = false;
          // } else {
          //   newWarning = true;
          // }
          return {
            ...prevFinancial,
            smoke: {
              ...prevFinancial.smoke,
              value: newhealthInsuranceValue,
            },
          };
        });
        break;
      default:
        break;
    }
  };
  const updateMasterState = (attrName: string[], value: string) => {
    let newValue: string | number = value;
    if (["healthCoverageAmount"].includes(attrName[1])) {
      const d = value.split(" ");

      newValue = d[1];
    }
    if (["healthCoverageAmount"].includes(attrName[1])) {
      newValue = +newValue;
    }
    if (["termCoverageAmount"].includes(attrName[1])) {
      newValue = value.replaceAll(",", "");
      console.log("aaaaaaaa", newValue);
      const data = newValue.split(" ");
      if (data[1]) {
        newValue = +data[1];
      }
    }
    // if (["termCoverageAmount"].includes(attrName[1])) {
    //   const data = newValue.split(" ");

    //   if (data[1]) {
    //     newValue = +data[1];
    //   }
    // }

    if (attrName[0] === "insurancePolicy") {
      setInsurancePolicy((prev) => ({
        ...prev,
        [attrName[1]]: { value: newValue, warning: isEmpty(value) },
      }));
    }
  };
  const handlePrevious = () => {
    decrementStep();
  };
  const handleContinue = () => {
    let local_insurance_policy_state = { ...insurancePolicy };
    if (toggleStatus3) {
      local_insurance_policy_state.healthCoverageAmount = {
        ...local_insurance_policy_state.healthCoverageAmount,
        warning:
          !insurancePolicy.healthCoverageAmount.value ||
          insurancePolicy.healthCoverageAmount.value < 100000 ||
          insurancePolicy.healthCoverageAmount.value > 60000000,
      };
    }
    if (toggleStatus4) {
      local_insurance_policy_state.termCoverageAmount = {
        ...local_insurance_policy_state.termCoverageAmount,
        warning:
          !insurancePolicy.termCoverageAmount.value ||
          insurancePolicy.termCoverageAmount.value < 100000 ||
          insurancePolicy.termCoverageAmount.value > 60000000,
      };
    }
    let isValid = Object.values(local_insurance_policy_state).every(
      (field) => !field.warning
    );
    setInsurancePolicy(local_insurance_policy_state);

    // return isValid;

    if (isValid) {
      // step((s: number) => s + 1);

      dispatch(PIR_DATA_INSURANCE_POLICY(insurancePolicy));
      incrementStep();
    }
  };
  const formatCurrency = (value: number) => {
    const stringValue = value.toString();
    const ruppeeValue = "₹ ";

    if (stringValue === "" || stringValue === "0") {
      return ruppeeValue.concat("0");
    }
    return ruppeeValue.concat(stringValue);
    // return `${("₹", value.toString())}`;
  };
  const toggleUpdateMasterState = (val: any) => {
    setInsurancePolicy((prevPolicy) => ({
      ...prevPolicy,
      smoke: { value: val, warning: false },
    }));
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <h2>Do you have an Insurance Policy?</h2>
          <p className="mt-4">
            "If yes, please enter the details of your coverage amount."
          </p>

          <Box margin="24px -16px">
            <Grid
              container
              columnSpacing={3}
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems="center"
            >
              <Grid
                sm={12}
                display="flex"
                gap="12px"
                justifyContent="space-between"
                alignItems="center"
              >
                <h6>Do you currently have a health insurance policy?</h6>
                <Box className="greenToggle">
                  <ToggleSwitch
                    toggle_status={toggleStatus3}
                    value_update={updateToggleStatus}
                    attrName="attrName3"
                  />
                </Box>
              </Grid>
              {toggleStatus3 ? (
                <Box className="gradientDashBox">
                  <Box className="inrIcon">
                    {/* <RKTextField
                        class_name="inputField"
                        title={"Enter Coverage Amount"}
                        validation_type="NUMBER"
                        value={insurancePolicy.healthCoverageAmount.value}
                        attrName={["insurancePolicy", "healthCoverageAmount"]}
                        value_update={updateMasterState}
                        max_length={6}
                        warn_status={
                          insurancePolicy.healthCoverageAmount.warning
                        }
                        error_message={
                          "Enter min amount of 3 lakhs to 6 crores"
                        }
                      /> */}
                    <SelectDropdown
                      class_name="inputField"
                      title={"Enter Coverage Amount"}
                      value={formatCurrency(
                        insurancePolicy.healthCoverageAmount.value
                      )}
                      attrName={["insurancePolicy", "healthCoverageAmount"]}
                      value_update={updateMasterState}
                      warn_status={insurancePolicy.healthCoverageAmount.warning}
                      error_message={"Enter min amount of 1 lakhs to 6 crores"}
                      data={data}
                    />
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#4f4f4f",
                        marginLeft: "14px",
                      }}
                    >
                      {/* Rs. {pirFields.healthCoverageAmount.words} */}
                    </p>
                  </Box>
                </Box>
              ) : null}

              <Grid
                sm={12}
                display="flex"
                gap="12px"
                justifyContent="space-between"
                alignItems="center"
              >
                <h6>Do you currently have a term insurance policy?</h6>
                <Box className="greenToggle">
                  <ToggleSwitch
                    toggle_status={toggleStatus4}
                    value_update={updateToggleStatus}
                    attrName="attrName4"
                    // disable={true}
                  />
                </Box>
              </Grid>
              {toggleStatus4 ? (
                <>
                  <Box className="gradientDashBox">
                    <Box className="inrIcon">
                      <RKTextField
                        class_name="inputField"
                        title={"Enter Coverage Amount"}
                        validation_type="NUMERIC"
                        // value={formatCurrencyAccToUser(
                        //   insurancePolicy.termCoverageAmount.value
                        // )}
                        value={
                          insurancePolicy.termCoverageAmount.value
                            ? formatCurrencyAccToUser(
                                insurancePolicy.termCoverageAmount.value
                              )
                            : ""
                        }
                        attrName={["insurancePolicy", "termCoverageAmount"]}
                        value_update={updateMasterState}
                        max_length={12}
                        warn_status={insurancePolicy.termCoverageAmount.warning}
                        error_message={"Enter min amount of 1 lakh to 6 crores"}
                      />
                      <p
                        style={{
                          fontSize: "10px",
                          color: "#4f4f4f",
                          marginLeft: "14px",
                        }}
                      >
                        {/* Rs. {pirFields.termCoverageAmount.words} */}
                      </p>
                    </Box>
                  </Box>
                  <Grid
                    sm={12}
                    display="flex"
                    gap="12px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h6>Do you smoke?</h6>
                    <Box className="greenToggle">
                      <ToggleSwitch
                        toggle_status={toggleStatus5}
                        value_update={updateToggleStatus}
                        attrName="attrName5"
                      />
                    </Box>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} md={6} textAlign="center" className="mHide">
          <img
            src="./images/insurance-policy.svg"
            width="100%"
            style={{ maxWidth: "492px" }}
            alt="img"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Box className="ctaBtn my-6 bottomButtons" display="flex" gap="24px">
          <Buttons
            onClickFunction={handlePrevious}
            class_name="textMdBtn"
            text_name="Previous"
          />
          <Buttons
            onClickFunction={handleContinue}
            class_name="greenMdBtn roundedBtn"
            text_name="Continue"
            loading={false}
          />
        </Box>
      </Grid>
    </>
  );
};

export default PIRInsurancePolicy;
