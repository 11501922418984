import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import "./MContactUs.scss";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

export default function MContactUs({
  complaintFields,
  setcomplaintFields,
  validateForm,
  loader,
  fieldsUpdateState,
  interestedin_data,
}: {
  complaintFields: any;
  setcomplaintFields: Function;
  validateForm: Function;
  loader: any;
  fieldsUpdateState: Function;
  interestedin_data: any;
}) {
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      <MNavbar />
      <div className="contact_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12} className="main_heading mb-8">
            <h1>
              Contact Us
              <span>
                We're here to listen, assist and collaborate with you!
              </span>
            </h1>
          </Grid>
          <Grid xs={6}>
            <div className="support_mail_parent mb-3">
              <div className="support-mail">Support mail:</div>
              <div className="carebimastreetcom">care@bimastreet.com</div>
            </div>
            <div className="other_query_parent mb-3">
              <div className="support-mail">Other Query:</div>
              <div className="carebimastreetcom">info@bimastreet.com</div>
            </div>
            <div className="phone_number_parent">
              <div className="support-mail">Phone Number:</div>
              <div className="carebimastreetcom">(+91) 9391009482/84/89</div>
            </div>
          </Grid>
          <Grid xs={6}>
            <img
              src="./images/contactus_img.svg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Grid>
          <Grid xs={12}>
            <div className="form_details">
              <h6>
                Fill the Details{" "}
                <span>Share your information and expect a swift response!</span>
              </h6>
              <Grid container rowSpacing={3}>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    validation_type="NAME"
                    value={complaintFields.fullname.value}
                    attrName={[
                      "fullname",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.fullname.warning}
                    error_message={
                      isEmpty(complaintFields.fullname.value)
                        ? "Enter Full Name"
                        : "Enter Valid Full Name"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={complaintFields.mobile.value}
                    attrName={[
                      "mobile",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.mobile.warning}
                    error_message={
                      isEmpty(complaintFields.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid mobile number"
                    }
                    max_length={10}
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Email"}
                    value={complaintFields.email.value}
                    attrName={[
                      "email",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.email.warning}
                    error_message={
                      isEmpty(complaintFields.email.value)
                        ? "Ener Email"
                        : "Enter Valid Email"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Interested In"
                    value={complaintFields.interestedin.value}
                    attrName={[
                      "interestedin",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    data={interestedin_data}
                    warn_status={complaintFields.interestedin.warning}
                    error_message={"Select Interested In"}
                  />
                </Grid>
                <Grid xs={12} className="text-center">
                  <div className="ctaBtn">
                    <Buttons
                      class_name="greenLgBtn"
                      text_name="Submit Details"
                      loading={loader}
                      onClickFunction={() => {
                        validateForm();
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className="amaze_address">
              <h6> Amaze Insurance Brokers Pvt. Ltd.</h6>
              <p>
                <span style={{ fontWeight: 600 }}>Corporate Office :-</span>{" "}
                #403, THE LEGEND, Metro Pillar C1338, Motilal Nehru Nagar,
                Begumpet, Hyderabad – 500016
              </p>
            </div>
          </Grid>
          <Grid xs={12}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1903.1510371637585!2d78.468154!3d17.4452502!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb974284c44b61%3A0x6e5314445adadd92!2sAmaze%20Insurance%20Brokers%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1723444565472!5m2!1sen!2sin"
              width="100%"
              height="400px"
              loading="lazy"
              style={{ border: "0", borderRadius: "12px", marginTop: "60px" }}
            ></iframe>
          </Grid>
        </Grid>
      </div>
      <MFooter />
    </>
  );
}
