import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import "../../../App.css";
import AboutConatiner from "../../../Container/CMS/AboutContainer";
import CarrierConatiner from "../../../Container/CMS/CarrierConatiner";
import ClaimsContainer from "../../../Container/CMS/ClaimsContainer";
import ContactUsConatiner from "../../../Container/CMS/ContactUsContainer";
import DisclaimerConatiner from "../../../Container/CMS/DisclaimerContainer";
import FreeClaimAssistanceConatiner from "../../../Container/CMS/FreeClaimAssistance";
import NeedHelpConatiner from "../../../Container/CMS/NeedHelpContainer";
import PrivacyPolicyConatiner from "../../../Container/CMS/PrivacyPolicyContainer";
import RefundCancellationContainer from "../../../Container/CMS/RefundCancellationContainer";
import TermConditionConatiner from "../../../Container/CMS/TermConditionContainer";
import HomeContainer from "../../../Container/Home/HomeContainer";
import PIR from "../../../Pages/Desktop/PIR/PIR";
import Report from "../../../Pages/Desktop/PIR/Report";
import CarInsurance from "../../../Pages/Desktop/ProductPages/CarInsurance/CarInsurance";
import HealthInsurance from "../../../Pages/Desktop/ProductPages/HealthInsurance/HealthInsurance";
import TWInsurance from "../../../Pages/Desktop/ProductPages/TWInsurance/TWInsurance";
import TermInsurance from "../../../Pages/Desktop/ProductPages/TermInsurance/TermInsurance";
import { changeTitleAndMeta } from "../../../SupportingFiles/faviconUtils";
import { COMMON_ROUTES } from "../../Path/CommonRoutes";
import PageNotFound from "../../../Component/Common/ProposalComponents/Desktop/PageNotFound/PageNotFound";
import Sitemap from "../../../Pages/Desktop/Sitemap/Sitemap";
import HIFormContainer from "../../../Container/HomeInsurance/Form/HIFormContainer";
import TravelFormContainer from "../../../Container/Travel/Form/TravelformContainer";

function CommonRoutes() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // changeTitleAndMeta();
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.PIR} element={<PIR />} />
        <Route path={COMMON_ROUTES.PIR_REPORT} element={<Report />} />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicyConatiner />}
        />
        <Route
          path={COMMON_ROUTES.TERM_AND_CONDTION}
          element={<TermConditionConatiner />}
        />
        <Route path={COMMON_ROUTES.NEED_HELP} element={<NeedHelpConatiner />} />
        <Route
          path={COMMON_ROUTES.DISCLAIMER}
          element={<DisclaimerConatiner />}
        />
        <Route
          path={COMMON_ROUTES.REFUND_AND_CANCELLATION}
          element={<RefundCancellationContainer />}
        />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<AboutConatiner />} />
        <Route
          path={COMMON_ROUTES.CONTACT_US}
          element={<ContactUsConatiner />}
        />
        <Route
          path={COMMON_ROUTES.FREE_CLAIM_ASSISTANCE}
          element={<FreeClaimAssistanceConatiner />}
        />
        <Route path={COMMON_ROUTES.CARRIER} element={<CarrierConatiner />} />
        <Route
          path={COMMON_ROUTES.MCONTACT_US}
          element={<ContactUsConatiner />}
        />
        <Route
          path={COMMON_ROUTES.MCOMPLAINT}
          element={<NeedHelpConatiner />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthInsurance />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarInsurance />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<TWInsurance />} />
        <Route path={COMMON_ROUTES.PRODUCT_TERM} element={<TermInsurance />} />
        <Route path={COMMON_ROUTES.CLAIMS} element={<ClaimsContainer />} />
        <Route path={COMMON_ROUTES.SITEMAP} element={<Sitemap />} />
        <Route path="*" element={<PageNotFound />} /> {/* 404 Page Route */}
        <Route path={COMMON_ROUTES.HOME_FORM} element={<HIFormContainer />} />
        <Route
          path={COMMON_ROUTES.TRAVEL_FORM}
          element={<TravelFormContainer />}
        />
      </Routes>
    </div>
  );
}

export default CommonRoutes;
