import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";
import { useAppSelector } from "../../../../../Store/hooks";

function MDCEO() {
  const { md_ceo } = useAppSelector((state) => state.CMS);
  return (
    <div className="murlidhara-reddy-parent">
      <div className="murlidhara-reddy">
        <div className="frame-parent">
          <div className="director-parent">
            <div className="dr-muralidhara-reddy">
              {/* {md_ceo.data.attributes.directors_name} */}
              Dr. K Muralidhara Reddy
            </div>
            <div className="managing-director">
              {md_ceo.data.attributes.directors_desg}
            </div>
            <div className="managing-director">
              Insurance Industry Veteran with 35 Years of Experience
            </div>
          </div>
          <div className="dr-k-muralidhara-reddy-also-wrapper">
            {md_ceo.data.attributes.directors_desc}
          </div>
          <div className="dr-k-muralidhara-reddy-also-wrapper">
            {md_ceo.data.attributes.awards.few_words_sub_desc_2}
          </div>
        </div>
        <div className="murlidhara-reddy-inner">
          <div className="md-sir-1-wrapper">
            <img
              width="100%"
              alt=""
              src={
                STRAPI_DOMAIN +
                md_ceo.data.attributes.directors_image.data.attributes.url
              }
            />
          </div>
        </div>
      </div>
      <div className="awards">
        <img
          className="image-icon"
          alt=""
          src={
            STRAPI_DOMAIN +
            md_ceo.data.attributes.awards.image.data.attributes.url
          }
        />
        <div className="awards-content">
          <div className="awardsrecognition">
            <h2>{md_ceo.data.attributes.awards.title}</h2>
          </div>
          <p style={{ fontSize: "20px", fontWeight: 600 }}>
            Dr. Reddy's contributions have been recognized through numerous
            accolades, including:
          </p>
          <div className="points">
            {md_ceo.data.attributes.awards.data.map((data, index) => (
              <div className="point01">
                <img
                  className="charmcircle-tick-icon1"
                  alt=""
                  src="./images/list-tick-icon.svg"
                />
                <div className="dr-k-muralidhara-container">{data.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="few-words">
        <div className="title1">
          <div className="futureEndeavours">
            <h2>{md_ceo.data.attributes.awards.few_words_heading}</h2>
          </div>
        </div>
        {/* <div className="dr-reddy-worked">
          {md_ceo.data.attributes.awards.few_words_desc}
        </div> */}
        <div className="dr-reddy-belongs">
          {md_ceo.data.attributes.awards.few_words_sub_desc}
        </div>
        {/* <div className="dr-reddy-belongs">
          {md_ceo.data.attributes.awards.few_words_sub_desc_2}
        </div> */}
      </div>
      <div className="summary">
        <div className="title1">
          <div className="awardsrecognition">
            <h2>{md_ceo.data.attributes.Experience.title}</h2>
          </div>
          <div className="dr-reddy-has">
            {md_ceo.data.attributes.Experience.desc}
          </div>
        </div>
        <div className="all-points">
          {md_ceo.data.attributes.Experience.data.map((data) => (
            <div className="point011">
              <img
                className="corpora-2-icon1"
                alt=""
                src={STRAPI_DOMAIN + data.image.data.attributes.url}
              />
              <div className="compliance-with-irdai1">{data.desc}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="client-solutions">
        <div className="client-solutions-inner">
          <div className="title1">
            <div className="awardsrecognition">
              <h2>{md_ceo.data.attributes.Solutions.title}</h2>
            </div>
            <div className="dr-reddy-has1">
              {md_ceo.data.attributes.Solutions.desc}
            </div>
          </div>
          <div className="various-area">
            {md_ceo.data.attributes.Solutions.data.map((data) => (
              <div className="various-points01">
                <div className="inner">
                  <div className="title3">
                    <div className="frame1">
                      <img
                        className="vector-icon"
                        alt="img"
                        src={STRAPI_DOMAIN + data.image.data.attributes.url}
                      />
                    </div>
                    <div className="project-insurances">{data.title}</div>
                  </div>
                  <div className="earcarcpmmarine-cum-ear">{data.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {md_ceo.data.attributes.Achievements.map((data) => (
        <div className="achievements">
          <div className="description">
            <div className="dr-reddys-tenure">
              <h2>{data.title}</h2>
            </div>
          </div>
          <div className="content">
            <div className="description">
              <div className="dr-k-muralidhara-container">{data.desc}</div>
            </div>
            <div className="points">
              {data.data.map((sub_data) => (
                <div className="point012">
                  <div className="icon">
                    <img
                      alt=""
                      src="./images/green-tick.svg"
                      height="24px"
                      width="22px"
                    />
                  </div>
                  <div className="desctiption">
                    <div className="dr-reddy-belongs">{sub_data.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <div className="few-words">
        <div className="title1">
          <div className="futureEndeavours">
            <h2>Legacy of Excellence</h2>
          </div>
        </div>
        <div className="dr-reddy-belongs">
          Throughout his career, Dr. Reddy has pioneered unique insurance
          schemes, facilitated corporate partnerships, and provided invaluable
          risk management solutions. His dedication to client satisfaction and
          industry innovation continues to define his legacy in the insurance
          landscape.
        </div>
      </div>

      <div className="few-words">
        <div className="title1">
          <div className="futureEndeavours">
            <h2>The Journey Continues</h2>
          </div>
        </div>
        <div className="dr-reddy-belongs">
          As Managing Director & Principal Officer of Amaze Insurance Brokers
          Pvt Ltd since January 2016, Dr. Reddy remains steadfast in his mission
          to deliver world-class insurance solutions, driving positive change
          and elevating the reputation of Indian Insurance Brokers on the global
          stage.
        </div>
      </div>
    </div>
  );
}

export default MDCEO;
