// import React, { useEffect } from "react";
// import Progress_Bar from "./Progress_Bar";
// import "./RiskScore.scss";

// interface Props {
//   progress_one: number;
//   class_name: string;
// }

// const RiskScore: React.FC<Props> = ({ progress_one, class_name }) => {
//   const [loading, setLoading] = React.useState(true);
//   const [progress, setProgress] = React.useState(0);
//   const [labelProgress, setLabelProgress] = React.useState(0);
//   const loadingDuration = 3000; // 3 seconds
//   const loadingDuration_label = 1500; // 1.5 seconds

//   useEffect(() => {
//     let loadingTimeout = setTimeout(() => {
//       if (labelProgress >= progress_one) return;
//       setLabelProgress(labelProgress + 1);
//     }, loadingDuration_label / 100);

//     let loadingTimeout_one = setTimeout(() => {
//       if (progress >= Math.round(progress_one / 1.66)) return;
//       setProgress(progress + 1);
//     }, loadingDuration / 100);

//     if (labelProgress == progress_one) {
//       setLoading(false);
//       clearTimeout(loadingTimeout);
//       clearTimeout(loadingTimeout_one);
//     }

//     document.title = "Bimastreet PIR";

//     return () => {
//       clearTimeout(loadingTimeout);
//     };
//   }, [progress, labelProgress, loading]);

//   return (
//     <Progress_Bar
//       class_name={class_name}
//       size={280}
//       progress={progress}
//       label_progress={labelProgress}
//       trackWidth={24}
//       trackColor={"#EEF6FE"}
//       indicatorWidth={24}
//       indicatorCap={"round"}
//       label={""}
//       labelColor={"#333"}
//       spinnerMode={false}
//       spinnerSpeed={1}
//       indicatorColor={"red"}
//     />
//   );
// };

// export default RiskScore;
import React, { useEffect, useState } from "react";
import Progress_Bar from "./Progress_Bar";
import "./RiskScore.scss";

interface Props {
  progress_one: number;
  class_name: string;
}

const RiskScore: React.FC<Props> = ({ progress_one, class_name }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [labelProgress, setLabelProgress] = useState(0);
  const loadingDuration = 3000; // 3 seconds
  const loadingDuration_label = 1500; // 1.5 seconds

  useEffect(() => {
    setProgress(0);
    setLabelProgress(0);

    let labelProgressInterval = setInterval(() => {
      setLabelProgress((prev) => {
        if (prev >= progress_one) {
          clearInterval(labelProgressInterval);
          return prev;
        }
        return prev + 1;
      });
    }, loadingDuration_label / 100);

    let progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= Math.round(progress_one / 1.66)) {
          clearInterval(progressInterval);
          setLoading(false);
          return prev;
        }
        return prev + 1;
      });
    }, loadingDuration / 100);

    document.title = "Bimastreet PIR";

    return () => {
      clearInterval(labelProgressInterval);
      clearInterval(progressInterval);
    };
  }, [progress_one]);

  return (
    <Progress_Bar
      class_name={class_name}
      size={280}
      progress={progress}
      label_progress={labelProgress}
      trackWidth={24}
      trackColor={"#EEF6FE"}
      indicatorWidth={24}
      indicatorCap={"round"}
      label={""}
      labelColor={"#333"}
      spinnerMode={false}
      spinnerSpeed={1}
      indicatorColor={"red"}
    />
  );
};

export default RiskScore;
