import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { PIR_DATA_INSURANCE_FOR } from "../../../../Store/Slice_Reducer/PIR/PirSlice";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import { subDays, subYears } from "date-fns";
import {
  FORMAT_DD_MM_YYYY,
  formatToCurrency,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import { toast } from "react-toastify";
interface IInsuranceFor {
  // step: (s: (v: number) => number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
}
const PIRInsuranceFor = ({ incrementStep, decrementStep }: IInsuranceFor) => {
  const dispatch = useAppDispatch();
  const { RELATION, PERSONAL } = useAppSelector((state) => state.PIR);
  const [insureFor, setInsureFor] = useState<{
    adult: {
      value: string;
      warning: boolean;
    };

    childrenCount: {
      value: number;
      warning: boolean;
    };

    haveChildren: {
      value: string;
      warning: boolean;
    };
    second_adult_dob: {
      value: string;
      warning: boolean;
    };
    child_1_dob: {
      value: string;
      warning: boolean;
    };
    child_2_dob: {
      value: string;
      warning: boolean;
    };
    child_3_dob: {
      value: string;
      warning: boolean;
    };
    child_4_dob: {
      value: string;
      warning: boolean;
    };
    relation: {
      value: string;
      warning: boolean;
    };
    child_1_relation: {
      value: string;
      warning: boolean;
    };
    child_2_relation: {
      value: string;
      warning: boolean;
    };
    child_3_relation: {
      value: string;
      warning: boolean;
    };
    child_4_relation: {
      value: string;
      warning: boolean;
    };
  }>({
    adult: {
      value: "1A",
      warning: false,
    },

    childrenCount: {
      value: 0,
      warning: false,
    },
    haveChildren: {
      value: "No",
      warning: false,
    },
    second_adult_dob: {
      value: "",
      warning: false,
    },
    child_1_dob: {
      value: "",
      warning: false,
    },
    child_2_dob: {
      value: "",
      warning: false,
    },
    child_3_dob: {
      value: "",
      warning: false,
    },
    child_4_dob: {
      value: "",
      warning: false,
    },
    relation: {
      value: "",
      warning: false,
    },
    child_1_relation: {
      value: "",
      warning: false,
    },
    child_2_relation: {
      value: "",
      warning: false,
    },
    child_3_relation: {
      value: "",
      warning: false,
    },
    child_4_relation: {
      value: "",
      warning: false,
    },
  });
  const [load, setLoad] = useState(false);
  const ADULT_VALUES = {
    "1A": "1A",
    "2A": "2A",
  } as const;
  type BoxName = keyof typeof ADULT_VALUES | "Children";
  const isBoxSelected = (boxName: BoxName) => {
    // return insuredFor.insuredMember.value.includes(boxName);
    if (boxName === "Children") {
      return insureFor.childrenCount.value > 0;
    }
    return insureFor.adult.value === ADULT_VALUES[boxName];
  };
  const handleBoxClick = (boxName: BoxName) => {
    if (insureFor.adult.value === boxName) {
      return;
    }
    // debugger;
    if (boxName === "Children") {
      if (insureFor.haveChildren.value == "No") {
        setInsureFor((prevState) => {
          return {
            ...prevState,
            child_1_dob: {
              value: "",
              warning: false,
            },
            child_2_dob: {
              value: "",
              warning: false,
            },
            child_3_dob: {
              value: "",
              warning: false,
            },
            child_4_dob: {
              value: "",
              warning: false,
            },
            child_1_relation: {
              value: "",
              warning: false,
            },
            child_2_relation: {
              value: "",
              warning: false,
            },
            child_3_relation: {
              value: "",
              warning: false,
            },
            child_4_relation: {
              value: "",
              warning: false,
            },
          };
        });
      }
      setInsureFor((prevState) => {
        const isAlreadySelected = prevState.childrenCount.value > 0;

        return {
          ...prevState,
          childrenCount: {
            value: isAlreadySelected ? 0 : 1, // Default to 1 if not already selected
            warning: false,
          },
          haveChildren: {
            value: !isAlreadySelected ? "Yes" : "No",
            warning: false,
          },
        };
      });
    } else {
      setInsureFor((prevState) => {
        const newValue = ADULT_VALUES[boxName];
        const isAlreadySelected = prevState.adult.value === newValue;
        return {
          ...prevState,
          adult: {
            value: isAlreadySelected ? "" : newValue,
            warning: false,
          },
          relation: {
            value: boxName != "2A" ? "" : "Spouse",
            warning: false,
          },
        };
      });
    }
  };
  useEffect(() => {
    setInsureFor(RELATION);
  }, [RELATION]);

  // useEffect(() => {
  //   setLoad(false);
  //   console.log("loooooooooooooo", load);
  // }, [load]);
  const decrement = () => {
    // setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
    setInsureFor((prevState) => ({
      ...prevState,
      childrenCount: {
        value: Math.max(prevState.childrenCount.value - 1, 0),
        warning: false,
      },
      [`child_${prevState.childrenCount.value}_dob`]: {
        value: "",
        warning: false,
      },
      [`child_${prevState.childrenCount.value}_relation`]: {
        value: "",
        warning: false,
      },
    }));
  };
  const increment = () => {
    // setCount((prevCount) => (prevCount <= 3 ? prevCount + 1 : 4));

    setInsureFor((prevState) => ({
      ...prevState,
      childrenCount: {
        value: Math.min(prevState.childrenCount.value + 1, 4),
        warning: false,
      },
    }));
  };
  const handlePrevious = () => {
    // setInsureFor(RELATION);
    decrementStep();
  };
  const handleContinue = () => {
    // console.log("setloadd", load);
    // setLoad(() => true);
    // console.log("setloadd=====>", load);
    let local_insured_for_state = { ...insureFor };
    if (insureFor.adult.value == "2A") {
      local_insured_for_state.relation = {
        ...local_insured_for_state.relation,
        warning: !insureFor.relation.value,
      };
      local_insured_for_state.second_adult_dob = {
        ...local_insured_for_state.second_adult_dob,
        warning: !insureFor.second_adult_dob.value,
      };
    }
    if (insureFor.adult.value == "") {
      local_insured_for_state.adult = {
        ...local_insured_for_state.adult,
        warning: !local_insured_for_state.adult.value,
      };
      // setLoad(false);
      // toast.error("select one of them");
      // setLoad(false);
    }

    Array.from({ length: insureFor.childrenCount.value }).map((e, index) => {
      (local_insured_for_state as any)[`child_${index + 1}_dob`] = {
        ...(local_insured_for_state as any)[`child_${index + 1}_dob`],
        warning: !(insureFor as any)[`child_${index + 1}_dob`].value,
      };
      (local_insured_for_state as any)[`child_${index + 1}_relation`] = {
        ...(local_insured_for_state as any)[`child_${index + 1}_relation`],
        warning: !(insureFor as any)[`child_${index + 1}_relation`].value,
      };
    });
    let isValid = Object.values(local_insured_for_state).every(
      (field) => !field.warning
    );
    setInsureFor(local_insured_for_state);

    if (isValid) {
      dispatch(PIR_DATA_INSURANCE_FOR(insureFor));
      incrementStep();
    }
    //   }
  };
  const adult_relation_data = [
    {
      label: "Mother",
      value: "Mother",
    },
    {
      label: "Father",
      value: "Father",
    },
    {
      label: "Spouse",
      value: "Spouse",
    },
  ];

  const child_relation_data = [
    {
      label: "Son",
      value: "Son",
    },
    {
      label: "Daughter",
      value: "Daughter",
    },
  ];
  const convertDateToOriginalFormat = (dateString: string | null) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const convertDateFormat = (dateString: string) => {
    // Split the date string by "-"
    const [day, month, year] = dateString.split("-");

    // Return the new format "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
  };
  const updateMasterState = (attrName: string[], value: string) => {
    let newValue: string | number = value;

    if (
      [
        "second_adult_dob",
        "child_1_dob",
        "child_2_dob",
        "child_3_dob",
        "child_4_dob",
      ].includes(attrName[1])
    ) {
      newValue = convertDateFormat(value);
    }
    setInsureFor((prev) => ({
      ...prev,
      [attrName[1]]: { value: newValue, warning: isEmpty(value) },
    }));
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <h2>Are you responsible for care giving to others?</h2>
          <p className="mt-4">
            For the report to suit you best, I need some additional information
            info. Your details help me customize recommendations that fit your
            insurance needs and preferences, exactly.
          </p>

          <Box
            margin="24px 0px"
            display="flex"
            gap="12px"
            flexWrap="wrap"
            className="members"
            // border="2px solid blue"
          >
            <Box
              className={`memberBox ${isBoxSelected("1A") ? "active" : ""}`}
              onClick={() => handleBoxClick("1A")}
            >
              <Box className="checkbox" />
              <img src="./images/myself.svg" alt="Self" />
              Self
            </Box>
            <Box
              className={`memberBox ${isBoxSelected("2A") ? "active" : ""}`}
              onClick={() => handleBoxClick("2A")}
            >
              <Box className="checkbox" />
              <img src="./images/self-spouse.svg" alt="Self+Spouse" />
              Self + Spouse
            </Box>
            {/* <Box
              className={`memberBox ${
                isBoxSelected("Parents") ? "active" : ""
              }`}
              onClick={() => handleBoxClick("Parents")}
            >
              <Box className="checkbox" />
              <img src="./images/parents.svg" alt="Parents" />
              Parents
            </Box> */}
            <Box>
              <Box
                className={`memberBox ${
                  isBoxSelected("Children") ? "active" : ""
                }`}
                onClick={() => handleBoxClick("Children")}
              >
                <Box className="checkbox" />
                <img src="./images/children.svg" alt="Children" />
                Children
              </Box>
              {/* Child Count */}

              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  padding: "0px 8px",
                  borderRadius: "4px",
                  boxShadow: "0px 8px 16px rgba(0,0,0,0.1)",
                  marginTop: "4px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: insureFor.childrenCount.value > 0 ? "flex" : "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "86px",
                }}
              >
                <button
                  onClick={decrement}
                  style={{
                    color: "#00B587",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    background: "transparent",
                    padding: "4px",
                  }}
                >
                  -
                </button>
                <h6>{insureFor.childrenCount.value}</h6>
                <button
                  onClick={increment}
                  style={{
                    color: "#00B587",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    background: "transparent",
                    padding: "4px",
                  }}
                >
                  +
                </button>
              </Box>
              {/* {insureFor.adult.value == "" ? (
                <Box
                  sx={{
                    color: "red",
                    border: "1px solid red",
                    marginTop: "10px",
                  }}
                >
                  <p> Please Select One of them.</p>
                </Box>
              ) : null} */}
            </Box>

            {/* Age and Relationship */}

            {insureFor.adult.value == "2A" ? (
              <Box className="gradientDashBox" display="flex" gap="12px">
                <DatePicker
                  class_name="inputField"
                  title={"Second Adult Dob"}
                  value={convertDateToOriginalFormat(
                    insureFor.second_adult_dob.value as unknown as string
                  )}
                  attrName={["insureFor", "second_adult_dob"]}
                  value_update={updateMasterState}
                  error_message="Select Dob"
                  warn_status={insureFor.second_adult_dob.warning}
                  min_date={110}
                  max_date={18}
                  date_validation_type="YEARS"
                  default_date={subYears(new Date(), 18)}
                  required
                />

                {/* <SelectDropdown
                  class_name="inputField"
                  title={"Select Relation With Adult"}
                  value={insureFor.relation.value}
                  attrName={["insureFor", "relation"]}
                  value_update={updateMasterState}
                  warn_status={insureFor.relation.warning}
                  error_message={"Select Relation"}
                  data={adult_relation_data}
                /> */}
                <RKTextField
                  class_name="inputField"
                  title={"Relation"}
                  validation_type="FIRSTNAME"
                  value={insureFor.relation.value}
                  attrName={["personal", "firstname"]}
                  value_update={updateMasterState}
                  warn_status={insureFor.relation.warning}
                  error_message={"Enter first name"}
                  disabled={true}
                  // required
                  // alphabet_only={true}
                />
              </Box>
            ) : null}

            {/* {insureFor.haveChildren.value === "Yes" && (
              <Box className="gradientDashBox" display="flex" gap="12px">
                <DatePicker
                  class_name="inputField"
                  title="Second Adult Dob"
                  value="29-09-1997"
                  attrName={["personal", "dob"]}
                  value_update={() => {}}
                  error_message="Select Dob"
                  warn_status={false}
                  min_date={110}
                  max_date={18}
                  date_validation_type="YEARS"
                  default_date={subYears(new Date(), 18)}
                  required
                />

                <SelectDropdown
                  class_name="inputField"
                  title="Select Relation With Adult"
                  attrName={["insurancePolicy", "healthCoverageAmount"]}
                  error_message="Enter min amount of 1 lakhs to 6 crores"
                />
              </Box>
            )} */}

            {Array.from({ length: insureFor.childrenCount.value }).map(
              (_, index) => (
                <Box
                  key={index}
                  className="gradientDashBox"
                  display="flex"
                  gap="12px"
                >
                  <DatePicker
                    class_name="inputField"
                    title={`Child ${index + 1} Dob`}
                    value={FORMAT_DD_MM_YYYY(
                      (insureFor as any)[`child_${index + 1}_dob`].value
                    )}
                    attrName={["insureFor", `child_${index + 1}_dob`]}
                    value_update={updateMasterState}
                    error_message={
                      isEmpty(
                        (insureFor as any)[`child_${index + 1}_dob`].value
                      )
                        ? "Select Dob"
                        : "Select Dob"
                    }
                    warn_status={
                      (insureFor as any)[`child_${index + 1}_dob`].warning
                    }
                    min_date={25 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                    default_date={subDays(new Date(), 90)}
                    required
                  />

                  <SelectDropdown
                    class_name="inputField"
                    title={`Select Relation With Child ${index + 1}`}
                    value={
                      (insureFor as any)[`child_${index + 1}_relation`].value
                    }
                    attrName={["insureFor", `child_${index + 1}_relation`]}
                    error_message="Select Relation"
                    warn_status={
                      (insureFor as any)[`child_${index + 1}_relation`].warning
                    }
                    data={child_relation_data}
                    value_update={updateMasterState}
                  />
                </Box>
              )
            )}
          </Box>
        </Grid>
        <Grid xs={12} md={6} textAlign="center" className="mHide">
          <img
            src="./images/members.svg"
            width="100%"
            style={{ maxWidth: "508px" }}
            alt="img"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Box className="ctaBtn my-6 bottomButtons" display="flex" gap="24px">
          <Buttons
            onClickFunction={handlePrevious}
            class_name="textMdBtn"
            text_name="Previous"
          />
          <Buttons
            onClickFunction={handleContinue}
            class_name="greenMdBtn roundedBtn"
            text_name="Continue"
            loading={load}
          />
        </Box>
      </Grid>
    </>
  );
};

export default PIRInsuranceFor;
