import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../../../../Component/Common/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import { HOME_SERVICES } from "../../../../Services/Home/HomeServices";
import {
  isEmpty,
  LeadingSpaceRemover,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validateRegnoNewMotor,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { ClaimUserData } from "../../../../Type/Claims/ClaimUserData";
import CarClaim from "./Car/CarClaim";
import "./Claims.scss";
import HealthClaim from "./Health/HealthClaim";
import TermClaim from "./Term/TermClaim";
import TwoWheelerClaim from "./Tw/TwoWheelerClaim";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

const Claims = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<ClaimUserData>({
    Common: {
      claimFor: "Car Insurance",
      fullname: { value: "", warning: false },
      mobile: { value: "", warning: false },
      email: { value: "", warning: false },
    },
    CarIns: {
      claimType: "Own Damage Claim",
      OwnDamage: {
        vehicleNumber: { value: "", warning: false },
        copyOfRC: { value: "", url: "", warning: false, docFile: [] },
        copyOfDrivingLicence: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfComplaint: { value: "", url: "", warning: false },
        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        dateTimeOfAccident: { value: "", warning: false },
        placeOfAccident: { value: "", warning: false },
        driverAtTimeOfAccident: { value: "", warning: false },
        drivingLicenseNo: { value: "", warning: false },
        drivingLicenseValidUpto: { value: "", warning: false },
        typeOfDrivingLicense: { value: "", warning: false },
        reportedAtPoliceStation: false,
        insurer: { value: "", warning: false },
        policyNumber: { value: "", warning: false },
        policyStartDate: { value: "", warning: false },
        policyEndDate: { value: "", warning: false },
        descriptionAboutAccident: { value: "", warning: false },
        claimStatus: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },
        accidentPhotos: { warning: false, accidentPhotos: [] },
      },
      Theft: {
        vehicleNumber: { value: "", warning: false },
        copyOfRC: { value: "", url: "", warning: false, docFile: [] },
        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfComplaint: { value: "", url: "", warning: false, docFile: [] },
        copyOfFinalReport: { value: "", url: "", warning: false, docFile: [] },
        dateOfLossNoticed: { value: "", warning: false },
        placeOfAccident: { value: "", warning: false },
        insurer: { value: "", warning: false },
        policyNumber: { value: "", warning: false },
        policyStartDate: { value: "", warning: false },
        policyEndDate: { value: "", warning: false },
        descriptionAboutAccident: { value: "", warning: false },
        claimStatus: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },
      },
      ThirdParty: {
        vehicleNumber: { value: "", warning: false },
        copyOfRC: { value: "", url: "", warning: false, docFile: [] },
        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfComplaint: { value: "", url: "", warning: false, docFile: [] },
        copyOfDrivingLicence: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        dateTimeOfAccident: { value: "", warning: false },
        placeOfAccident: { value: "", warning: false },
        dateOfLossNoticed: { value: "", warning: false },
        driverAtTimeOfAccident: { value: "", warning: false },
        drivingLicenseNo: { value: "", warning: false },
        drivingLicenseValidUpto: { value: "", warning: false },
        typeOfDrivingLicense: { value: "", warning: false },
        reportedAtPoliceStation: false,
        insurer: { value: "", warning: false },
        policyNumber: { value: "", warning: false },
        policyStartDate: { value: "", warning: false },
        policyEndDate: { value: "", warning: false },
        descriptionAboutAccident: { value: "", warning: false },
        detailsOfThirdParty: { value: "", warning: false },
        deathOfThirdParty: { value: "", warning: false },
        detailsOfThirdPropertyDamage: { value: "", warning: false },
        claimStatus: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },
        accidentPhotos: { warning: false, accidentPhotos: [] },
      },
    },
    HealthIns: {
      claimType: "Cashless",
      cashLess: {
        claimStatus: "Pending",
        customerType: "Bimastreet",
        dateOfAdmission: { value: "", warning: false },
        dateOfDiscahrge: { value: "", warning: false },
        reasonForPending: { value: "", warning: false },
        reasonForRejection: { value: "", warning: false },
        dischargeSummary: { value: "", warning: false },
        finalBillBreakup: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },

        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfHealthCard: { value: "", url: "", warning: false, docFile: [] },
        copyOfRejectionLetter: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfCashReceipt: { value: "", url: "", warning: false, docFile: [] },
        copyOfInvestigationReport: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfPharmacyBill: { value: "", url: "", warning: false, docFile: [] },
        copyOfCaseSheet: { value: "", url: "", warning: false, docFile: [] },
      },
      reimbursement: {
        claimStatus: "Pending",
        customerType: "Bimastreet",
        dateOfAdmission: { value: "", warning: false },
        dateOfDiscahrge: { value: "", warning: false },
        reasonForRejection: { value: "", warning: false },
        reasonForPending: { value: "", warning: false },
        dischargeSummary: { value: "", warning: false },
        finalBillBreakup: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },

        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfHealthCard: { value: "", url: "", warning: false, docFile: [] },
        copyOfRejectionLetter: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfCashReceipt: { value: "", url: "", warning: false, docFile: [] },
        copyOfInvestigationReport: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfPharmacyBill: { value: "", url: "", warning: false, docFile: [] },
        copyOfCaseSheet: { value: "", url: "", warning: false, docFile: [] },
      },
      ClaimPartiallySettled: {
        claimStatus: "Pending",
        customerType: "Bimastreet",
        dateOfAdmission: { value: "", warning: false },
        dateOfDiscahrge: { value: "", warning: false },
        reasonForPending: { value: "", warning: false },
        reasonForRejection: { value: "", warning: false },
        dischargeSummary: { value: "", warning: false },
        finalBillBreakup: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },

        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfHealthCard: { value: "", url: "", warning: false, docFile: [] },
        copyOfCashReceipt: { value: "", url: "", warning: false, docFile: [] },
        copyOfRejectionLetter: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfInvestigationReport: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfPharmacyBill: { value: "", url: "", warning: false, docFile: [] },
        copyOfCaseSheet: { value: "", url: "", warning: false, docFile: [] },
      },
    },
    TwIns: {
      claimType: "Own Damage Claim",
      OwnDamage: {
        vehicleNumber: { value: "", warning: false },
        copyOfRC: { value: "", url: "", warning: false, docFile: [] },
        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfComplaint: { value: "", url: "", warning: false, docFile: [] },
        copyOfDrivingLicence: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        dateTimeOfAccident: { value: "", warning: false },
        placeOfAccident: { value: "", warning: false },
        driverAtTimeOfAccident: { value: "", warning: false },
        drivingLicenseNo: { value: "", warning: false },
        drivingLicenseValidUpto: { value: "", warning: false },
        typeOfDrivingLicense: { value: "", warning: false },
        reportedAtPoliceStation: false,
        insurer: { value: "", warning: false },
        policyNumber: { value: "", warning: false },
        policyStartDate: { value: "", warning: false },
        policyEndDate: { value: "", warning: false },
        descriptionAboutAccident: { value: "", warning: false },
        claimStatus: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },
        accidentPhotos: { warning: false, accidentPhotos: [] },
      },
      Theft: {
        vehicleNumber: { value: "", warning: false },
        copyOfRC: { value: "", url: "", warning: false, docFile: [] },
        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfComplaint: { value: "", url: "", warning: false, docFile: [] },
        copyOfFinalReport: { value: "", url: "", warning: false, docFile: [] },
        dateOfLossNoticed: { value: "", warning: false },
        placeOfAccident: { value: "", warning: false },
        insurer: { value: "", warning: false },
        policyNumber: { value: "", warning: false },
        policyStartDate: { value: "", warning: false },
        policyEndDate: { value: "", warning: false },
        descriptionAboutAccident: { value: "", warning: false },
        claimStatus: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },
      },
      ThirdParty: {
        vehicleNumber: { value: "", warning: false },
        copyOfRC: { value: "", url: "", warning: false, docFile: [] },
        copyOfPolicyDoc: { value: "", url: "", warning: false, docFile: [] },
        copyOfDrivingLicence: {
          value: "",
          url: "",
          warning: false,
          docFile: [],
        },
        copyOfComplaint: { value: "", url: "", warning: false, docFile: [] },
        dateTimeOfAccident: { value: "", warning: false },
        placeOfAccident: { value: "", warning: false },
        driverAtTimeOfAccident: { value: "", warning: false },
        drivingLicenseNo: { value: "", warning: false },
        drivingLicenseValidUpto: { value: "", warning: false },
        typeOfDrivingLicense: { value: "", warning: false },
        reportedAtPoliceStation: false,
        insurer: { value: "", warning: false },
        policyNumber: { value: "", warning: false },
        policyStartDate: { value: "", warning: false },
        policyEndDate: { value: "", warning: false },
        dateOfLossNoticed: { value: "", warning: false },
        descriptionAboutAccident: { value: "", warning: false },
        detailsOfThirdParty: { value: "", warning: false },
        deathOfThirdParty: { value: "", warning: false },
        detailsOfThirdPropertyDamage: { value: "", warning: false },
        claimStatus: { value: "", warning: false },
        whatDoYouExpect: { value: "", warning: false },
        accidentPhotos: { warning: false, accidentPhotos: [] },
      },
    },
    termIns: {
      claimType: "Pending",
      dateOfDeath: { value: "", warning: false },
      dateOfSubmisionOfDoc: { value: "", warning: false },
      reasonForPending: { value: "", warning: false },
      reasonForRejection: { value: "", warning: false },
      whatDoYouExpect: { value: "", warning: false },
      policyDoc: { value: "", url: "", warning: false },
    },
  });

  // car ins
  const copyOfRCOwn = useRef<any>(null);
  const copyOfDrivingLicenseOwn = useRef<any>(null);
  const copyOfComplaintOwn = useRef<any>(null);
  const copyOfPolicyDocOwn = useRef<any>(null);
  const accidentPhotosOwn = useRef<any>(null);
  const copyOfRCTheft = useRef<any>(null);
  const copyOfPolicyDocTheft = useRef<any>(null);
  const copyOfComplaintTheft = useRef<any>(null);
  const copyOfFinalReportTheft = useRef<any>(null);
  const copyOfRCThird = useRef<any>(null);
  const copyOfDrivingLicenseThird = useRef<any>(null);
  const copyOfComplaintThird = useRef<any>(null);
  const copyOfPolicyDocThird = useRef<any>(null);
  const accidentPhotosThird = useRef<any>(null);
  // health ins
  const copyOfPolicyDocCash = useRef<any>(null);
  const copyOfHealthCardCash = useRef<any>(null);
  const copyOfRejectionLetterCash = useRef<any>(null);
  const copyOfCashReceiptCash = useRef<any>(null);
  const copyOfInvestigationReportCash = useRef<any>(null);
  const copyOfPharmacyBillCash = useRef<any>(null);
  const copyOfCaseSheetCash = useRef<any>(null);
  const copyOfPolicyDocRe = useRef<any>(null);
  const copyOfHealthCardRe = useRef<any>(null);
  const copyOfRejectionLetterRe = useRef<any>(null);
  const copyOfCashReceiptRe = useRef<any>(null);
  const copyOfInvestigationReportRe = useRef<any>(null);
  const copyOfPharmacyBillRe = useRef<any>(null);
  const copyOfCaseSheetRe = useRef<any>(null);
  const copyOfPolicyDocClaim = useRef<any>(null);
  const copyOfHealthCardClaim = useRef<any>(null);
  const copyOfCashReceiptClaim = useRef<any>(null);
  const copyOfInvestigationReportClaim = useRef<any>(null);
  const copyOfPharmacyBillClaim = useRef<any>(null);
  const copyOfCaseSheetClaim = useRef<any>(null);
  const copyOfRejectionLetterPartially = useRef<any>(null);
  // tw ins
  const TWcopyOfRCOwn = useRef<any>(null);
  const TWcopyOfDrivingLicenseOwn = useRef<any>(null);
  const TWcopyOfComplaintOwn = useRef<any>(null);
  const TWcopyOfPolicyDocOwn = useRef<any>(null);
  const TWaccidentPhotosOwn = useRef<any>(null);
  const TWcopyOfRCTheft = useRef<any>(null);
  const TWcopyOfPolicyDocTheft = useRef<any>(null);
  const TWcopyOfComplaintTheft = useRef<any>(null);
  const TWcopyOfFinalReportTheft = useRef<any>(null);
  const TWcopyOfRCThird = useRef<any>(null);
  const TWcopyOfDrivingLicenseThird = useRef<any>(null);
  const TWcopyOfComplaintThird = useRef<any>(null);
  const TWcopyOfPolicyDocThird = useRef<any>(null);
  const TWaccidentPhotosThird = useRef<any>(null);
  const policyDoc = useRef<any>(null);

  const fieldsUpdateState = (atr: any, value: any) => {
    if (atr[0] === "Common") {
      setInputFields((prev: any) => ({
        ...prev,
        [atr[0]]: {
          ...prev[atr[0]],
          [atr[1]]: {
            value:
              typeof value === "string" ? LeadingSpaceRemover(value) : value,
            warning: isEmpty(value),
          },
        },
      }));
    } else if (
      atr[0] === "CarIns" ||
      atr[0] === "HealthIns" ||
      atr[0] === "TwIns"
    ) {
      setInputFields((prev: any) => ({
        ...prev,
        [atr[0]]: {
          ...prev[atr[0]],
          [atr[1]]: {
            ...prev[atr[0]][atr[1]],
            [atr[2]]: {
              value:
                typeof value === "string" ? LeadingSpaceRemover(value) : value,
              warning: isEmpty(value),
            },
          },
        },
      }));
    } else if (atr[0] === "termIns") {
      setInputFields((prev: any) => ({
        ...prev,
        [atr[0]]: {
          ...prev[atr[0]],
          [atr[1]]: {
            value:
              typeof value === "string" ? LeadingSpaceRemover(value) : value,
            warning: isEmpty(value),
          },
        },
      }));
    }
  };

  const selectFile = (name: string) => {
    if (inputFields.Common.claimFor === "Car Insurance") {
      if (inputFields.CarIns.claimType === "Own Damage Claim") {
        if (name === "copyOfRC") {
          if (copyOfRCOwn.current) {
            copyOfRCOwn.current.click();
          }
        } else if (name === "copyOfDrivingLicense") {
          if (copyOfDrivingLicenseOwn.current) {
            copyOfDrivingLicenseOwn.current.click();
          }
        } else if (name === "copyOfComplaint") {
          if (copyOfComplaintOwn.current) {
            copyOfComplaintOwn.current.click();
          }
        } else if (name === "copyOfPolicyDoc") {
          if (copyOfPolicyDocOwn.current) {
            copyOfPolicyDocOwn.current.click();
          }
        } else if (name === "accidentPhotos") {
          if (accidentPhotosOwn.current) {
            accidentPhotosOwn.current.click();
          }
        }
      } else if (inputFields.CarIns.claimType === "Theft Claim") {
        if (name === "copyOfRC") {
          if (copyOfRCTheft.current) {
            copyOfRCTheft.current.click();
          }
        } else if (name === "copyOfPolicyDoc") {
          if (copyOfPolicyDocTheft.current) {
            copyOfPolicyDocTheft.current.click();
          }
        } else if (name === "copyOfComplaint") {
          if (copyOfComplaintTheft.current) {
            copyOfComplaintTheft.current.click();
          }
        } else if (name === "copyOfFinalReport") {
          if (copyOfFinalReportTheft.current) {
            copyOfFinalReportTheft.current.click();
          }
        }
      } else if (inputFields.CarIns.claimType === "Third Party Claim") {
        if (name === "copyOfRC") {
          if (copyOfRCThird.current) {
            copyOfRCThird.current.click();
          }
        } else if (name === "copyOfDrivingLicense") {
          if (copyOfDrivingLicenseThird.current) {
            copyOfDrivingLicenseThird.current.click();
          }
        } else if (name === "copyOfComplaint") {
          if (copyOfComplaintThird.current) {
            copyOfComplaintThird.current.click();
          }
        } else if (name === "copyOfPolicyDoc") {
          if (copyOfPolicyDocThird.current) {
            copyOfPolicyDocThird.current.click();
          }
        } else if (name === "accidentPhotos") {
          if (accidentPhotosThird.current) {
            accidentPhotosThird.current.click();
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Health Insurance") {
      if (inputFields.HealthIns.claimType === "Cashless") {
        if (name === "copyOfPolicyDoc") {
          if (copyOfPolicyDocCash.current) {
            copyOfPolicyDocCash.current.click();
          }
        } else if (name === "copyOfHealthCard") {
          if (copyOfHealthCardCash.current) {
            copyOfHealthCardCash.current.click();
          }
        } else if (name === "copyOfRejectionLetter") {
          if (copyOfRejectionLetterCash.current) {
            copyOfRejectionLetterCash.current.click();
          }
        } else if (name === "copyOfCashReceipt") {
          if (copyOfCashReceiptCash.current) {
            copyOfCashReceiptCash.current.click();
          }
        } else if (name === "copyOfInvestigationReport") {
          if (copyOfInvestigationReportCash.current) {
            copyOfInvestigationReportCash.current.click();
          }
        } else if (name === "copyOfPharmacyBill") {
          if (copyOfPharmacyBillCash.current) {
            copyOfPharmacyBillCash.current.click();
          }
        } else if (name === "copyOfCaseSheet") {
          if (copyOfCaseSheetCash.current) {
            copyOfCaseSheetCash.current.click();
          }
        }
      } else if (inputFields.HealthIns.claimType === "Reimbursement") {
        if (name === "copyOfPolicyDoc") {
          if (copyOfPolicyDocRe.current) {
            copyOfPolicyDocRe.current.click();
          }
        } else if (name === "copyOfHealthCard") {
          if (copyOfHealthCardRe.current) {
            copyOfHealthCardRe.current.click();
          }
        } else if (name === "copyOfRejectionLetter") {
          if (copyOfRejectionLetterRe.current) {
            copyOfRejectionLetterRe.current.click();
          }
        } else if (name === "copyOfCashReceipt") {
          if (copyOfCashReceiptRe.current) {
            copyOfCashReceiptRe.current.click();
          }
        } else if (name === "copyOfInvestigationReport") {
          if (copyOfInvestigationReportRe.current) {
            copyOfInvestigationReportRe.current.click();
          }
        } else if (name === "copyOfPharmacyBill") {
          if (copyOfPharmacyBillRe.current) {
            copyOfPharmacyBillRe.current.click();
          }
        } else if (name === "copyOfCaseSheet") {
          if (copyOfCaseSheetRe.current) {
            copyOfCaseSheetRe.current.click();
          }
        }
      } else if (
        inputFields.HealthIns.claimType === "Claim Partially Settled"
      ) {
        if (name === "copyOfPolicyDoc") {
          if (copyOfPolicyDocClaim.current) {
            copyOfPolicyDocClaim.current.click();
          }
        } else if (name === "copyOfHealthCard") {
          if (copyOfHealthCardClaim.current) {
            copyOfHealthCardClaim.current.click();
          }
        } else if (name === "copyOfCashReceipt") {
          if (copyOfCashReceiptClaim.current) {
            copyOfCashReceiptClaim.current.click();
          }
        } else if (name === "copyOfInvestigationReport") {
          if (copyOfInvestigationReportClaim.current) {
            copyOfInvestigationReportClaim.current.click();
          }
        } else if (name === "copyOfPharmacyBill") {
          if (copyOfPharmacyBillClaim.current) {
            copyOfPharmacyBillClaim.current.click();
          }
        } else if (name === "copyOfCaseSheet") {
          if (copyOfCaseSheetClaim.current) {
            copyOfCaseSheetClaim.current.click();
          }
        } else if (name === "copyOfRejectionLetter") {
          if (copyOfRejectionLetterPartially.current) {
            copyOfRejectionLetterPartially.current.click();
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Two Wheeler Insurance") {
      if (inputFields.TwIns.claimType === "Own Damage Claim") {
        if (name === "copyOfRC") {
          if (TWcopyOfRCOwn.current) {
            TWcopyOfRCOwn.current.click();
          }
        } else if (name === "copyOfDrivingLicense") {
          if (TWcopyOfDrivingLicenseOwn.current) {
            TWcopyOfDrivingLicenseOwn.current.click();
          }
        } else if (name === "copyOfComplaint") {
          if (TWcopyOfComplaintOwn.current) {
            TWcopyOfComplaintOwn.current.click();
          }
        } else if (name === "copyOfPolicyDoc") {
          if (TWcopyOfPolicyDocOwn.current) {
            TWcopyOfPolicyDocOwn.current.click();
          }
        } else if (name === "accidentPhotos") {
          if (TWaccidentPhotosOwn.current) {
            TWaccidentPhotosOwn.current.click();
          }
        }
      } else if (inputFields.TwIns.claimType === "Theft Claim") {
        if (name === "copyOfRC") {
          if (TWcopyOfRCTheft.current) {
            TWcopyOfRCTheft.current.click();
          }
        } else if (name === "copyOfPolicyDoc") {
          if (TWcopyOfPolicyDocTheft.current) {
            TWcopyOfPolicyDocTheft.current.click();
          }
        } else if (name === "copyOfComplaint") {
          if (TWcopyOfComplaintTheft.current) {
            TWcopyOfComplaintTheft.current.click();
          }
        } else if (name === "copyOfFinalReport") {
          if (TWcopyOfFinalReportTheft.current) {
            TWcopyOfFinalReportTheft.current.click();
          }
        }
      } else if (inputFields.TwIns.claimType === "Third Party Claim") {
        if (name === "copyOfRC") {
          if (TWcopyOfRCThird.current) {
            TWcopyOfRCThird.current.click();
          }
        } else if (name === "copyOfDrivingLicense") {
          if (TWcopyOfDrivingLicenseThird.current) {
            TWcopyOfDrivingLicenseThird.current.click();
          }
        } else if (name === "copyOfComplaint") {
          if (TWcopyOfComplaintThird.current) {
            TWcopyOfComplaintThird.current.click();
          }
        } else if (name === "copyOfPolicyDoc") {
          if (TWcopyOfPolicyDocThird.current) {
            TWcopyOfPolicyDocThird.current.click();
          }
        } else if (name === "accidentPhotos") {
          if (TWaccidentPhotosThird.current) {
            TWaccidentPhotosThird.current.click();
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Term Insurance") {
      if (name === "policyDoc") {
        if (policyDoc.current) {
          policyDoc.current.click();
        }
      }
    }
  };
  console.log("input", inputFields);

  const fileChange = (name: string, value: { name: string; url: string }) => {
    let selectedFile;
    if (inputFields.Common.claimFor === "Car Insurance") {
      if (inputFields.CarIns.claimType === "Own Damage Claim") {
        if (name === "copyOfRC") {
          selectedFile = copyOfRCOwn.current.files[0];
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = copyOfDrivingLicenseOwn.current.files[0];
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintOwn.current.files[0];
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocOwn.current.files[0];
        }
        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfRC") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  OwnDamage: {
                    ...data.CarIns.OwnDamage,
                    copyOfRC: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfDrivingLicense") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  OwnDamage: {
                    ...data.CarIns.OwnDamage,
                    copyOfDrivingLicence: {
                      value: value?.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfComplaint") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  OwnDamage: {
                    ...data.CarIns.OwnDamage,
                    copyOfComplaint: {
                      value: value?.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  OwnDamage: {
                    ...data.CarIns.OwnDamage,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }
            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      } else if (inputFields.CarIns.claimType === "Theft Claim") {
        if (name === "copyOfRC") {
          selectedFile = copyOfRCTheft.current.files[0];
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocTheft.current.files[0];
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintTheft.current.files[0];
        } else if (name === "copyOfFinalReport") {
          selectedFile = copyOfFinalReportTheft.current.files[0];
        }
        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfRC") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  Theft: {
                    ...data.CarIns.Theft,
                    copyOfRC: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  Theft: {
                    ...data.CarIns.Theft,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfComplaint") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  Theft: {
                    ...data.CarIns.Theft,
                    copyOfComplaint: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfFinalReport") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  Theft: {
                    ...data.CarIns.Theft,
                    copyOfFinalReport: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }
            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      } else if (inputFields.CarIns.claimType === "Third Party Claim") {
        if (name === "copyOfRC") {
          selectedFile = copyOfRCThird.current.files[0];
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = copyOfDrivingLicenseThird.current.files[0];
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintThird.current.files[0];
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocThird.current.files[0];
        }
        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfRC") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  ThirdParty: {
                    ...data.CarIns.ThirdParty,
                    copyOfRC: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfDrivingLicense") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  ThirdParty: {
                    ...data.CarIns.ThirdParty,
                    copyOfDrivingLicence: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfComplaint") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  ThirdParty: {
                    ...data.CarIns.ThirdParty,
                    copyOfComplaint: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                CarIns: {
                  ...data.CarIns,
                  ThirdParty: {
                    ...data.CarIns.ThirdParty,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }

            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Health Insurance") {
      if (inputFields.HealthIns.claimType === "Cashless") {
        if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocCash.current.files[0];
        } else if (name === "copyOfHealthCard") {
          selectedFile = copyOfHealthCardCash.current.files[0];
        } else if (name === "copyOfRejectionLetter") {
          selectedFile = copyOfRejectionLetterCash.current.files[0];
        } else if (name === "copyOfCashReceipt") {
          selectedFile = copyOfCashReceiptCash.current.files[0];
        } else if (name === "copyOfInvestigationReport") {
          selectedFile = copyOfInvestigationReportCash.current.files[0];
        } else if (name === "copyOfPharmacyBill") {
          selectedFile = copyOfPharmacyBillCash.current.files[0];
        } else if (name === "copyOfCaseSheet") {
          selectedFile = copyOfCaseSheetCash.current.files[0];
        }

        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfHealthCard") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfHealthCard: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfRejectionLetter") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfRejectionLetter: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfCashReceipt") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfCashReceipt: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfInvestigationReport") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfInvestigationReport: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPharmacyBill") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfPharmacyBill: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfCaseSheet") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  cashLess: {
                    ...data.HealthIns.cashLess,
                    copyOfCaseSheet: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }

            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      } else if (inputFields.HealthIns.claimType === "Reimbursement") {
        if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocRe.current.files[0];
        } else if (name === "copyOfHealthCard") {
          selectedFile = copyOfHealthCardRe.current.files[0];
        } else if (name === "copyOfRejectionLetter") {
          selectedFile = copyOfRejectionLetterRe.current.files[0];
        } else if (name === "copyOfCashReceipt") {
          selectedFile = copyOfCashReceiptRe.current.files[0];
        } else if (name === "copyOfInvestigationReport") {
          selectedFile = copyOfInvestigationReportRe.current.files[0];
        } else if (name === "copyOfPharmacyBill") {
          selectedFile = copyOfPharmacyBillRe.current.files[0];
        } else if (name === "copyOfCaseSheet") {
          selectedFile = copyOfCaseSheetRe.current.files[0];
        }

        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfHealthCard") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfHealthCard: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfRejectionLetter") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfRejectionLetter: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfCashReceipt") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfCashReceipt: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfInvestigationReport") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfInvestigationReport: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPharmacyBill") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfPharmacyBill: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfCaseSheet") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  reimbursement: {
                    ...data.HealthIns.reimbursement,
                    copyOfCaseSheet: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }

            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      } else if (
        inputFields.HealthIns.claimType === "Claim Partially Settled"
      ) {
        if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocClaim.current.files[0];
        } else if (name === "copyOfHealthCard") {
          selectedFile = copyOfHealthCardClaim.current.files[0];
        } else if (name === "copyOfCashReceipt") {
          selectedFile = copyOfCashReceiptClaim.current.files[0];
        } else if (name === "copyOfInvestigationReport") {
          selectedFile = copyOfInvestigationReportClaim.current.files[0];
        } else if (name === "copyOfPharmacyBill") {
          selectedFile = copyOfPharmacyBillClaim.current.files[0];
        } else if (name === "copyOfCaseSheet") {
          selectedFile = copyOfCaseSheetClaim.current.files[0];
        } else if (name === "copyOfRejectionLetter") {
          selectedFile = copyOfRejectionLetterPartially.current.files[0];
        }
        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfHealthCard") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfHealthCard: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfCashReceipt") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfCashReceipt: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfInvestigationReport") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfInvestigationReport: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPharmacyBill") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfPharmacyBill: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfCaseSheet") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfCaseSheet: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfRejectionLetter") {
              data = {
                ...data,
                HealthIns: {
                  ...data.HealthIns,
                  ClaimPartiallySettled: {
                    ...data.HealthIns.ClaimPartiallySettled,
                    copyOfRejectionLetter: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }

            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Two Wheeler Insurance") {
      if (inputFields.TwIns.claimType === "Own Damage Claim") {
        if (name === "copyOfRC") {
          selectedFile = TWcopyOfRCOwn.current.files[0];
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = TWcopyOfDrivingLicenseOwn.current.files[0];
        } else if (name === "copyOfComplaint") {
          selectedFile = TWcopyOfComplaintOwn.current.files[0];
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = TWcopyOfPolicyDocOwn.current.files[0];
        }
        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfRC") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  OwnDamage: {
                    ...data.TwIns.OwnDamage,
                    copyOfRC: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfDrivingLicense") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  OwnDamage: {
                    ...data.TwIns.OwnDamage,
                    copyOfDrivingLicence: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfComplaint") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  OwnDamage: {
                    ...data.TwIns.OwnDamage,
                    copyOfComplaint: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  OwnDamage: {
                    ...data.TwIns.OwnDamage,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }

            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      } else if (inputFields.TwIns.claimType === "Theft Claim") {
        if (name === "copyOfRC") {
          selectedFile = TWcopyOfRCTheft.current.files[0];
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = TWcopyOfPolicyDocTheft.current.files[0];
        } else if (name === "copyOfComplaint") {
          selectedFile = TWcopyOfComplaintTheft.current.files[0];
        } else if (name === "copyOfFinalReport") {
          selectedFile = TWcopyOfFinalReportTheft.current.files[0];
        }
        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfRC") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  Theft: {
                    ...data.TwIns.Theft,
                    copyOfRC: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  Theft: {
                    ...data.TwIns.Theft,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfComplaint") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  Theft: {
                    ...data.TwIns.Theft,
                    copyOfComplaint: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfFinalReport") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  Theft: {
                    ...data.TwIns.Theft,
                    copyOfFinalReport: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }
            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      } else if (inputFields.TwIns.claimType === "Third Party Claim") {
        if (name === "copyOfRC") {
          selectedFile = TWcopyOfRCThird.current.files[0];
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = TWcopyOfDrivingLicenseThird.current.files[0];
        } else if (name === "copyOfComplaint") {
          selectedFile = TWcopyOfComplaintThird.current.files[0];
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = TWcopyOfPolicyDocThird.current.files[0];
        }

        if (selectedFile) {
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/svg" ||
            selectedFile.type === "image/webp"
          ) {
            let data = { ...inputFields };
            if (name === "copyOfRC") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  ThirdParty: {
                    ...data.TwIns.ThirdParty,
                    copyOfRC: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfDrivingLicense") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  ThirdParty: {
                    ...data.TwIns.ThirdParty,
                    copyOfDrivingLicence: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfComplaint") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  ThirdParty: {
                    ...data.TwIns.ThirdParty,
                    copyOfComplaint: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            } else if (name === "copyOfPolicyDoc") {
              data = {
                ...data,
                TwIns: {
                  ...data.TwIns,
                  ThirdParty: {
                    ...data.TwIns.ThirdParty,
                    copyOfPolicyDoc: {
                      value: value.name,
                      url: value.url,
                      warning: isEmpty(selectedFile.name),
                    },
                  },
                },
              };
            }

            setInputFields({ ...data });
          } else {
            toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Term Insurance") {
      if (name === "policyDoc") {
        selectedFile = policyDoc.current.files[0];
      }
      if (selectedFile) {
        if (selectedFile.size > 2 * 1000 * 1024) {
          return toast.error("File with maximum size of 2MB is allowed");
        }
        if (
          selectedFile.type === "image/jpeg" ||
          selectedFile.type === "image/png" ||
          selectedFile.type === "image/svg" ||
          selectedFile.type === "image/webp"
        ) {
          let data = { ...inputFields };
          if (name === "policyDoc") {
            console.log("valuevalueanmol", value);

            data = {
              ...data,
              termIns: {
                ...data.termIns,
                policyDoc: {
                  value: value.name,
                  url: value.url,
                  warning: isEmpty(selectedFile.name),
                },
              },
            };
          }
          setInputFields({ ...data });
        } else {
          toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!");
        }
      }
    }
  };
  // copyOfComplaint
  const handleFileChange = (name: string) => {
    let selectedFile;
    if (inputFields.Common.claimFor === "Car Insurance") {
      if (inputFields.CarIns.claimType === "Own Damage Claim") {
        if (name === "copyOfRC") {
          selectedFile = copyOfRCOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              OwnDamage: {
                ...data.CarIns.OwnDamage,
                copyOfRC: {
                  ...data.CarIns.OwnDamage.copyOfRC,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              OwnDamage: {
                ...data.CarIns.OwnDamage,
                copyOfComplaint: {
                  ...data.CarIns.OwnDamage.copyOfComplaint,
                  docFile: selectedFile, // Assign the single file directly
                  // warning: isEmpty(selectFile.name)
                  warning: false,
                  // warning: data.CarIns.OwnDamage.reportedAtPoliceStation ? isEmpty(selectedFile.name) : false,
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              OwnDamage: {
                ...data.CarIns.OwnDamage,
                copyOfPolicyDoc: {
                  ...data.CarIns.OwnDamage.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = copyOfDrivingLicenseOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              OwnDamage: {
                ...data.CarIns.OwnDamage,
                copyOfDrivingLicence: {
                  ...data.CarIns.OwnDamage.copyOfDrivingLicence,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      } else if (inputFields.CarIns.claimType === "Theft Claim") {
        if (name === "copyOfRC") {
          selectedFile = copyOfRCTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              Theft: {
                ...data.CarIns.Theft,
                copyOfRC: {
                  ...data.CarIns.Theft.copyOfRC,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              Theft: {
                ...data.CarIns.Theft,
                copyOfComplaint: {
                  ...data.CarIns.Theft.copyOfComplaint,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              Theft: {
                ...data.CarIns.Theft,
                copyOfPolicyDoc: {
                  ...data.CarIns.Theft.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              Theft: {
                ...data.CarIns.Theft,
                copyOfPolicyDoc: {
                  ...data.CarIns.Theft.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfFinalReport") {
          selectedFile = copyOfFinalReportTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              Theft: {
                ...data.CarIns.Theft,
                copyOfFinalReport: {
                  ...data.CarIns.Theft.copyOfFinalReport,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      } else if (inputFields.CarIns.claimType === "Third Party Claim") {
        if (name === "copyOfRC") {
          selectedFile = copyOfRCThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              ThirdParty: {
                ...data.CarIns.ThirdParty,
                copyOfRC: {
                  ...data.CarIns.ThirdParty.copyOfRC,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = copyOfDrivingLicenseThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              ThirdParty: {
                ...data.CarIns.ThirdParty,
                copyOfDrivingLicence: {
                  ...data.CarIns.ThirdParty.copyOfDrivingLicence,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              ThirdParty: {
                ...data.CarIns.ThirdParty,
                copyOfPolicyDoc: {
                  ...data.CarIns.ThirdParty.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              ThirdParty: {
                ...data.CarIns.ThirdParty,
                copyOfComplaint: {
                  ...data.CarIns.ThirdParty.copyOfComplaint,
                  docFile: selectedFile, // Assign the single file directly
                  // warning: isEmpty(selectFile.name)
                  warning: data.CarIns.ThirdParty.reportedAtPoliceStation
                    ? isEmpty(selectedFile.name)
                    : false,
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      }
    } else if (inputFields.Common.claimFor === "Health Insurance") {
      if (inputFields.HealthIns.claimType === "Cashless") {
        if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocCash.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfPolicyDoc: {
                  ...data.HealthIns.cashLess.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfHealthCard") {
          selectedFile = copyOfHealthCardCash.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfHealthCard: {
                  ...data.HealthIns.cashLess.copyOfHealthCard,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfCashReceipt") {
          selectedFile = copyOfCashReceiptCash.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfCashReceipt: {
                  ...data.HealthIns.cashLess.copyOfCashReceipt,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPharmacyBill") {
          selectedFile = copyOfPharmacyBillCash.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfPharmacyBill: {
                  ...data.HealthIns.cashLess.copyOfPharmacyBill,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfCaseSheet") {
          selectedFile = copyOfCaseSheetCash.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfCaseSheet: {
                  ...data.HealthIns.cashLess.copyOfCaseSheet,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfInvestigationReport") {
          selectedFile = copyOfInvestigationReportCash.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfInvestigationReport: {
                  ...data.HealthIns.cashLess.copyOfInvestigationReport,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfRejectionLetter") {
          selectedFile = copyOfRejectionLetterCash.current.files[0];
          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              cashLess: {
                ...data.HealthIns.cashLess,
                copyOfRejectionLetter: {
                  ...data.HealthIns.cashLess.copyOfRejectionLetter,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      } else if (inputFields.HealthIns.claimType === "Reimbursement") {
        if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfPolicyDoc: {
                  ...data.HealthIns.reimbursement.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfHealthCard") {
          selectedFile = copyOfHealthCardRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfHealthCard: {
                  ...data.HealthIns.reimbursement.copyOfHealthCard,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfRejectionLetter") {
          selectedFile = copyOfRejectionLetterRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfRejectionLetter: {
                  ...data.HealthIns.reimbursement.copyOfRejectionLetter,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfCashReceipt") {
          selectedFile = copyOfCashReceiptRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfCashReceipt: {
                  ...data.HealthIns.reimbursement.copyOfCashReceipt,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfCaseSheet") {
          selectedFile = copyOfCaseSheetRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfCaseSheet: {
                  ...data.HealthIns.reimbursement.copyOfCaseSheet,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfInvestigationReport") {
          selectedFile = copyOfInvestigationReportRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfInvestigationReport: {
                  ...data.HealthIns.reimbursement.copyOfInvestigationReport,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPharmacyBill") {
          selectedFile = copyOfPharmacyBillRe.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                copyOfPharmacyBill: {
                  ...data.HealthIns.reimbursement.copyOfPharmacyBill,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      } else if (
        inputFields.HealthIns.claimType === "Claim Partially Settled"
      ) {
        if (name === "copyOfPolicyDoc") {
          selectedFile = copyOfPolicyDocClaim.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfPolicyDoc: {
                  ...data.HealthIns.ClaimPartiallySettled.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfHealthCard") {
          selectedFile = copyOfHealthCardClaim.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfHealthCard: {
                  ...data.HealthIns.ClaimPartiallySettled.copyOfHealthCard,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfCashReceipt") {
          selectedFile = copyOfCashReceiptClaim.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfCashReceipt: {
                  ...data.HealthIns.ClaimPartiallySettled.copyOfCashReceipt,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfCaseSheet") {
          selectedFile = copyOfCaseSheetClaim.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfCaseSheet: {
                  ...data.HealthIns.ClaimPartiallySettled.copyOfCaseSheet,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfInvestigationReport") {
          selectedFile = copyOfInvestigationReportClaim.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfInvestigationReport: {
                  ...data.HealthIns.ClaimPartiallySettled
                    .copyOfInvestigationReport,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfRejectionLetter") {
          selectedFile = copyOfRejectionLetterPartially.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfRejectionLetter: {
                  ...data.HealthIns.ClaimPartiallySettled.copyOfRejectionLetter,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPharmacyBill") {
          selectedFile = copyOfPharmacyBillClaim.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              ClaimPartiallySettled: {
                ...data.HealthIns.ClaimPartiallySettled,
                copyOfPharmacyBill: {
                  ...data.HealthIns.ClaimPartiallySettled.copyOfPharmacyBill,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      }
    } else if (inputFields.Common.claimFor === "Term Insurance") {
      if (name === "policyDoc") {
        selectedFile = policyDoc.current.files[0];

        if (selectedFile.size > 2 * 1000 * 1024) {
          return toast.error("File with maximum size of 2MB is allowed");
        }
        let data = { ...inputFields };
        data = {
          ...data,
          termIns: {
            ...data.termIns,
            policyDoc: {
              ...data.termIns.policyDoc,
              docFile: selectedFile, // Assign the single file directly
              warning: isEmpty(selectFile.name),
            },
          },
        };
        setInputFields({ ...data });
      }
    }
    if (inputFields.Common.claimFor === "Two Wheeler Insurance") {
      if (inputFields.TwIns.claimType === "Own Damage Claim") {
        if (name === "copyOfRC") {
          selectedFile = TWcopyOfRCOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              OwnDamage: {
                ...data.TwIns.OwnDamage,
                copyOfRC: {
                  ...data.TwIns.OwnDamage.copyOfRC,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = TWcopyOfComplaintOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              OwnDamage: {
                ...data.TwIns.OwnDamage,
                copyOfComplaint: {
                  ...data.TwIns.OwnDamage.copyOfComplaint,
                  docFile: selectedFile, // Assign the single file directly
                  // warning: isEmpty(selectFile.name)
                  warning: data.TwIns.OwnDamage.reportedAtPoliceStation
                    ? isEmpty(selectedFile.name)
                    : false,
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = TWcopyOfPolicyDocOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              OwnDamage: {
                ...data.TwIns.OwnDamage,
                copyOfPolicyDoc: {
                  ...data.TwIns.OwnDamage.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = TWcopyOfDrivingLicenseOwn.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              OwnDamage: {
                ...data.TwIns.OwnDamage,
                copyOfDrivingLicence: {
                  ...data.TwIns.OwnDamage.copyOfDrivingLicence,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      } else if (inputFields.TwIns.claimType === "Theft Claim") {
        if (name === "copyOfRC") {
          selectedFile = TWcopyOfRCTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              Theft: {
                ...data.TwIns.Theft,
                copyOfRC: {
                  ...data.TwIns.Theft.copyOfRC,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = TWcopyOfComplaintTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              Theft: {
                ...data.TwIns.Theft,
                copyOfComplaint: {
                  ...data.TwIns.Theft.copyOfComplaint,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = TWcopyOfPolicyDocTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              Theft: {
                ...data.TwIns.Theft,
                copyOfPolicyDoc: {
                  ...data.TwIns.Theft.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = copyOfComplaintTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              Theft: {
                ...data.TwIns.Theft,
                copyOfPolicyDoc: {
                  ...data.TwIns.Theft.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfFinalReport") {
          selectedFile = TWcopyOfFinalReportTheft.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              Theft: {
                ...data.TwIns.Theft,
                copyOfFinalReport: {
                  ...data.TwIns.Theft.copyOfFinalReport,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      } else if (inputFields.TwIns.claimType === "Third Party Claim") {
        if (name === "copyOfRC") {
          selectedFile = TWcopyOfRCThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              ThirdParty: {
                ...data.TwIns.ThirdParty,
                copyOfRC: {
                  ...data.TwIns.ThirdParty.copyOfRC,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfDrivingLicense") {
          selectedFile = TWcopyOfDrivingLicenseThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              ThirdParty: {
                ...data.TwIns.ThirdParty,
                copyOfDrivingLicence: {
                  ...data.TwIns.ThirdParty.copyOfDrivingLicence,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfPolicyDoc") {
          selectedFile = TWcopyOfPolicyDocThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              ThirdParty: {
                ...data.TwIns.ThirdParty,
                copyOfPolicyDoc: {
                  ...data.TwIns.ThirdParty.copyOfPolicyDoc,
                  docFile: selectedFile, // Assign the single file directly
                  warning: isEmpty(selectFile.name),
                },
              },
            },
          };
          setInputFields({ ...data });
        } else if (name === "copyOfComplaint") {
          selectedFile = TWcopyOfComplaintThird.current.files[0];

          if (selectedFile.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              ThirdParty: {
                ...data.TwIns.ThirdParty,
                copyOfComplaint: {
                  ...data.TwIns.ThirdParty.copyOfComplaint,
                  docFile: selectedFile, // Assign the single file directly
                  // warning: isEmpty(selectFile.name)
                  warning: data.TwIns.ThirdParty.reportedAtPoliceStation
                    ? isEmpty(selectedFile.name)
                    : false,
                },
              },
            },
          };
          setInputFields({ ...data });
        }
      }
    }
  };

  const multiFileChange = (name: string) => {
    let selectedFile;
    if (inputFields.Common.claimFor === "Car Insurance") {
      if (inputFields.CarIns.claimType === "Own Damage Claim") {
        if (name === "accidentPhotos") {
          selectedFile = accidentPhotosOwn.current.files;
        }
        for (let i = 0; i < selectedFile.length; i++) {
          const item = selectedFile[i];
          if (item.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          // if (item.type != "image/jpeg" || item.type != "image/png" || item.type != "image/svg" || item.type != "image/webp") {
          //     return toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!")
          // }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              OwnDamage: {
                ...data.CarIns.OwnDamage,
                accidentPhotos: {
                  warning:
                    inputFields.CarIns.OwnDamage.accidentPhotos.accidentPhotos
                      .length > 0
                      ? false
                      : true,
                  accidentPhotos: [
                    ...data.CarIns.OwnDamage.accidentPhotos.accidentPhotos,
                    ...selectedFile,
                  ],
                },
              },
            },
          };
          setInputFields({ ...data });
          if (accidentPhotosOwn.current) {
            accidentPhotosOwn.current.value = null; // Reset the input value
          }
        }
      } else if (inputFields.CarIns.claimType === "Third Party Claim") {
        if (name === "accidentPhotos") {
          selectedFile = accidentPhotosThird.current.files;
        }
        for (let i = 0; i < selectedFile.length; i++) {
          const item = selectedFile[i];
          if (item.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          // if (item.type != "image/jpeg" || item.type != "image/png" || item.type != "image/svg" || item.type != "image/webp") {
          //     return toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!")
          // }
          let data = { ...inputFields };
          data = {
            ...data,
            CarIns: {
              ...data.CarIns,
              ThirdParty: {
                ...data.CarIns.ThirdParty,
                accidentPhotos: {
                  warning:
                    inputFields.CarIns.ThirdParty.accidentPhotos.accidentPhotos
                      .length > 0
                      ? false
                      : true,
                  accidentPhotos: [
                    ...data.CarIns.ThirdParty.accidentPhotos.accidentPhotos,
                    ...selectedFile,
                  ],
                },
              },
            },
          };
          setInputFields({ ...data });
          if (accidentPhotosThird.current) {
            accidentPhotosThird.current.value = null;
          }
        }
      }
    } else if (inputFields.Common.claimFor === "Two Wheeler Insurance") {
      if (inputFields.TwIns.claimType === "Own Damage Claim") {
        if (name === "accidentPhotos") {
          selectedFile = TWaccidentPhotosOwn.current.files;
        }
        for (let i = 0; i < selectedFile.length; i++) {
          const item = selectedFile[i];
          if (item.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          // if (item.type != "image/jpeg" || item.type != "image/png" || item.type != "image/svg" || item.type != "image/webp") {
          //     return toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!")
          // }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              OwnDamage: {
                ...data.TwIns.OwnDamage,
                accidentPhotos: {
                  warning:
                    inputFields.TwIns.OwnDamage.accidentPhotos.accidentPhotos
                      .length > 0
                      ? false
                      : true,
                  accidentPhotos: [
                    ...data.TwIns.OwnDamage.accidentPhotos.accidentPhotos,
                    ...selectedFile,
                  ],
                },
              },
            },
          };
          setInputFields({ ...data });
          if (TWaccidentPhotosOwn.current) {
            TWaccidentPhotosOwn.current.value = null;
          }
        }
      } else if (inputFields.TwIns.claimType === "Third Party Claim") {
        if (name === "accidentPhotos") {
          selectedFile = TWaccidentPhotosThird.current.files;
        }
        for (let i = 0; i < selectedFile.length; i++) {
          const item = selectedFile[i];
          if (item.size > 2 * 1000 * 1024) {
            return toast.error("File with maximum size of 2MB is allowed");
          }
          // if (item.type != "image/jpeg" || item.type != "image/png" || item.type != "image/svg" || item.type != "image/webp") {
          //     return toast.error("Only JPEG, PNG, SVG and WEBP files are allowed!")
          // }
          let data = { ...inputFields };
          data = {
            ...data,
            TwIns: {
              ...data.TwIns,
              ThirdParty: {
                ...data.TwIns.ThirdParty,
                accidentPhotos: {
                  warning:
                    inputFields.TwIns.ThirdParty.accidentPhotos.accidentPhotos
                      .length > 0
                      ? false
                      : true,
                  accidentPhotos: [
                    ...data.TwIns.ThirdParty.accidentPhotos.accidentPhotos,
                    ...selectedFile,
                  ],
                },
              },
            },
          };
          setInputFields({ ...data });
          if (TWaccidentPhotosThird.current) {
            TWaccidentPhotosThird.current.value = null;
          }
        }
      }
    }
  };
  const removePhoto = (i: number) => {
    let data = { ...inputFields };
    if (inputFields.Common.claimFor === "Car Insurance") {
      if (inputFields.CarIns.claimType === "Own Damage Claim") {
        let removedItem =
          data.CarIns.OwnDamage.accidentPhotos.accidentPhotos?.splice(i, 1);
        let restItems =
          data.CarIns.OwnDamage.accidentPhotos.accidentPhotos?.filter(
            (item) => item !== removedItem
          );
        data = {
          ...data,
          CarIns: {
            ...data.CarIns,
            OwnDamage: {
              ...data.CarIns.OwnDamage,
              accidentPhotos: {
                ...data.CarIns.OwnDamage.accidentPhotos,
                accidentPhotos: restItems,
              },
            },
          },
        };
        setInputFields({ ...data });
      } else if (inputFields.CarIns.claimType === "Third Party Claim") {
        let removedItem =
          data.CarIns.ThirdParty.accidentPhotos.accidentPhotos?.splice(i, 1);
        let restItems =
          data.CarIns.ThirdParty.accidentPhotos.accidentPhotos?.filter(
            (item) => item !== removedItem
          );
        data = {
          ...data,
          CarIns: {
            ...data.CarIns,
            ThirdParty: {
              ...data.CarIns.ThirdParty,
              accidentPhotos: {
                ...data.CarIns.ThirdParty.accidentPhotos,
                accidentPhotos: restItems,
              },
            },
          },
        };
        setInputFields({ ...data });
      }
    } else if (inputFields.Common.claimFor === "Two Wheeler Insurance") {
      if (inputFields.TwIns.claimType === "Own Damage Claim") {
        let removedItem =
          data.TwIns.OwnDamage.accidentPhotos.accidentPhotos?.splice(i, 1);
        let restItems =
          data.TwIns.OwnDamage.accidentPhotos.accidentPhotos?.filter(
            (item) => item !== removedItem
          );
        data = {
          ...data,
          TwIns: {
            ...data.TwIns,
            OwnDamage: {
              ...data.TwIns.OwnDamage,
              accidentPhotos: {
                ...data.TwIns.OwnDamage.accidentPhotos,
                accidentPhotos: restItems,
              },
            },
          },
        };
        setInputFields({ ...data });
      } else if (inputFields.TwIns.claimType === "Third Party Claim") {
        let removedItem =
          data.TwIns.ThirdParty.accidentPhotos.accidentPhotos?.splice(i, 1);
        let restItems =
          data.TwIns.ThirdParty.accidentPhotos.accidentPhotos?.filter(
            (item) => item !== removedItem
          );
        data = {
          ...data,
          TwIns: {
            ...data.TwIns,
            ThirdParty: {
              ...data.TwIns.ThirdParty,
              accidentPhotos: {
                ...data.TwIns.ThirdParty.accidentPhotos,
                accidentPhotos: restItems,
              },
            },
          },
        };
        setInputFields({ ...data });
      }
    }
  };

  const validateForm = () => {
    let data = { ...inputFields };
    // VALIDATE FOR COMMON FIELDS
    data = {
      ...data,
      Common: {
        ...data.Common,
        fullname: {
          ...data.Common.fullname,
          warning: !validateFullName(data.Common.fullname.value),
        },
        mobile: {
          ...data.Common.mobile,
          warning: !validateMobileNumber(data.Common.mobile.value),
        },
        email: {
          ...data.Common.email,
          warning: !validateEmail(data.Common.email.value),
        },
      },
    };
    // VALIDATE FOR CAR INSURANCE
    if (data.Common.claimFor === "Car Insurance") {
      if (data.CarIns.claimType === "Own Damage Claim") {
        data = {
          ...data,
          CarIns: {
            ...data.CarIns,
            OwnDamage: {
              ...data.CarIns.OwnDamage,
              vehicleNumber: {
                ...data.CarIns.OwnDamage.vehicleNumber,
                warning: !validateRegnoNewMotor(
                  data.CarIns.OwnDamage.vehicleNumber.value
                ),
              },
              dateTimeOfAccident: {
                ...data.CarIns.OwnDamage.dateTimeOfAccident,
                warning: isEmpty(
                  data.CarIns.OwnDamage.dateTimeOfAccident.value
                ),
              },
              placeOfAccident: {
                ...data.CarIns.OwnDamage.placeOfAccident,
                warning: isEmpty(data.CarIns.OwnDamage.placeOfAccident.value),
              },
              driverAtTimeOfAccident: {
                ...data.CarIns.OwnDamage.driverAtTimeOfAccident,
                warning: isEmpty(
                  data.CarIns.OwnDamage.driverAtTimeOfAccident.value
                ),
              },
              drivingLicenseNo: {
                ...data.CarIns.OwnDamage.drivingLicenseNo,
                warning: isEmpty(data.CarIns.OwnDamage.drivingLicenseNo.value),
              },
              drivingLicenseValidUpto: {
                ...data.CarIns.OwnDamage.drivingLicenseValidUpto,
                warning: isEmpty(
                  data.CarIns.OwnDamage.drivingLicenseValidUpto.value
                ),
              },
              typeOfDrivingLicense: {
                ...data.CarIns.OwnDamage.typeOfDrivingLicense,
                warning: isEmpty(
                  data.CarIns.OwnDamage.typeOfDrivingLicense.value
                ),
              },
              insurer: {
                ...data.CarIns.OwnDamage.insurer,
                warning: isEmpty(data.CarIns.OwnDamage.insurer.value),
              },
              policyNumber: {
                ...data.CarIns.OwnDamage.policyNumber,
                warning: isEmpty(data.CarIns.OwnDamage.policyNumber.value),
              },
              policyStartDate: {
                ...data.CarIns.OwnDamage.policyStartDate,
                warning: isEmpty(data.CarIns.OwnDamage.policyStartDate.value),
              },
              policyEndDate: {
                ...data.CarIns.OwnDamage.policyEndDate,
                warning: isEmpty(data.CarIns.OwnDamage.policyEndDate.value),
              },
              descriptionAboutAccident: {
                ...data.CarIns.OwnDamage.descriptionAboutAccident,
                warning: isEmpty(
                  data.CarIns.OwnDamage.descriptionAboutAccident.value
                ),
              },
              claimStatus: {
                ...data.CarIns.OwnDamage.claimStatus,
                warning: isEmpty(data.CarIns.OwnDamage.claimStatus.value),
              },
              whatDoYouExpect: {
                ...data.CarIns.OwnDamage.whatDoYouExpect,
                warning: isEmpty(data.CarIns.OwnDamage.whatDoYouExpect.value),
              },
              copyOfDrivingLicence: {
                ...data.CarIns.OwnDamage.copyOfDrivingLicence,
                warning: isEmpty(
                  data.CarIns.OwnDamage.copyOfDrivingLicence.docFile
                ),
              },

              copyOfComplaint: {
                ...data.CarIns.OwnDamage.copyOfComplaint,
                warning: data.CarIns.OwnDamage.reportedAtPoliceStation
                  ? isEmpty(data.CarIns.OwnDamage.copyOfComplaint.docFile)
                  : false,
              },
              copyOfRC: {
                ...data.CarIns.OwnDamage.copyOfRC,
                warning: isEmpty(data.CarIns.OwnDamage.copyOfRC.docFile),
              },
              copyOfPolicyDoc: {
                ...data.CarIns.OwnDamage.copyOfPolicyDoc,
                warning: isEmpty(data.CarIns.OwnDamage.copyOfPolicyDoc.docFile),
              },
              accidentPhotos: {
                ...data.CarIns.OwnDamage.accidentPhotos,
                warning:
                  data.CarIns.OwnDamage.accidentPhotos.accidentPhotos.length > 0
                    ? false
                    : true,
              },
            },
          },
        };
      } else if (data.CarIns.claimType === "Theft Claim") {
        data = {
          ...data,
          CarIns: {
            ...data.CarIns,
            Theft: {
              ...data.CarIns.Theft,
              vehicleNumber: {
                ...data.CarIns.Theft.vehicleNumber,
                warning: !validateRegnoNewMotor(
                  data.CarIns.Theft.vehicleNumber.value
                ),
              },
              dateOfLossNoticed: {
                ...data.CarIns.Theft.dateOfLossNoticed,
                warning: isEmpty(data.CarIns.Theft.dateOfLossNoticed.value),
              },
              placeOfAccident: {
                ...data.CarIns.Theft.placeOfAccident,
                warning: isEmpty(data.CarIns.Theft.placeOfAccident.value),
              },
              insurer: {
                ...data.CarIns.Theft.insurer,
                warning: isEmpty(data.CarIns.Theft.insurer.value),
              },
              policyNumber: {
                ...data.CarIns.Theft.policyNumber,
                warning: isEmpty(data.CarIns.Theft.policyNumber.value),
              },
              policyStartDate: {
                ...data.CarIns.Theft.policyStartDate,
                warning: isEmpty(data.CarIns.Theft.policyStartDate.value),
              },
              policyEndDate: {
                ...data.CarIns.Theft.policyEndDate,
                warning: isEmpty(data.CarIns.Theft.policyEndDate.value),
              },
              descriptionAboutAccident: {
                ...data.CarIns.Theft.descriptionAboutAccident,
                warning: isEmpty(
                  data.CarIns.Theft.descriptionAboutAccident.value
                ),
              },
              claimStatus: {
                ...data.CarIns.Theft.claimStatus,
                warning: isEmpty(data.CarIns.Theft.claimStatus.value),
              },
              whatDoYouExpect: {
                ...data.CarIns.Theft.whatDoYouExpect,
                warning: isEmpty(data.CarIns.Theft.whatDoYouExpect.value),
              },

              copyOfRC: {
                ...data.CarIns.Theft.copyOfRC,
                warning: isEmpty(data.CarIns.Theft.copyOfRC.docFile),
              },
              copyOfPolicyDoc: {
                ...data.CarIns.Theft.copyOfPolicyDoc,
                warning: isEmpty(data.CarIns.Theft.copyOfPolicyDoc.docFile),
              },
              copyOfComplaint: {
                ...data.CarIns.Theft.copyOfComplaint,
                // warning: data.CarIns.Theft.copyOfComplaint ? isEmpty(data.CarIns.Theft.copyOfComplaint.docFile) : false,
                warning: isEmpty(data.CarIns.Theft.copyOfComplaint.docFile),
              },
              copyOfFinalReport: {
                ...data.CarIns.Theft.copyOfFinalReport,
                warning: isEmpty(data.CarIns.Theft.copyOfFinalReport.docFile),
              },
            },
          },
        };
      } else if (data.CarIns.claimType === "Third Party Claim") {
        data = {
          ...data,
          CarIns: {
            ...data.CarIns,
            ThirdParty: {
              ...data.CarIns.ThirdParty,
              vehicleNumber: {
                ...data.CarIns.ThirdParty.vehicleNumber,
                warning: !validateRegnoNewMotor(
                  data.CarIns.ThirdParty.vehicleNumber.value
                ),
              },
              dateTimeOfAccident: {
                ...data.CarIns.ThirdParty.dateTimeOfAccident,
                warning: isEmpty(
                  data.CarIns.ThirdParty.dateTimeOfAccident.value
                ),
              },
              dateOfLossNoticed: {
                ...data.CarIns.ThirdParty.dateOfLossNoticed,
                warning: isEmpty(
                  data.CarIns.ThirdParty.dateOfLossNoticed.value
                ),
              },
              placeOfAccident: {
                ...data.CarIns.ThirdParty.placeOfAccident,
                warning: isEmpty(data.CarIns.ThirdParty.placeOfAccident.value),
              },
              driverAtTimeOfAccident: {
                ...data.CarIns.ThirdParty.driverAtTimeOfAccident,
                warning: isEmpty(
                  data.CarIns.ThirdParty.driverAtTimeOfAccident.value
                ),
              },
              drivingLicenseNo: {
                ...data.CarIns.ThirdParty.drivingLicenseNo,
                warning: isEmpty(data.CarIns.ThirdParty.drivingLicenseNo.value),
              },
              drivingLicenseValidUpto: {
                ...data.CarIns.ThirdParty.drivingLicenseValidUpto,
                warning: isEmpty(
                  data.CarIns.ThirdParty.drivingLicenseValidUpto.value
                ),
              },
              typeOfDrivingLicense: {
                ...data.CarIns.ThirdParty.typeOfDrivingLicense,
                warning: isEmpty(
                  data.CarIns.ThirdParty.typeOfDrivingLicense.value
                ),
              },
              insurer: {
                ...data.CarIns.ThirdParty.insurer,
                warning: isEmpty(data.CarIns.ThirdParty.insurer.value),
              },
              policyNumber: {
                ...data.CarIns.ThirdParty.policyNumber,
                warning: isEmpty(data.CarIns.ThirdParty.policyNumber.value),
              },
              policyStartDate: {
                ...data.CarIns.ThirdParty.policyStartDate,
                warning: isEmpty(data.CarIns.ThirdParty.policyStartDate.value),
              },
              policyEndDate: {
                ...data.CarIns.ThirdParty.policyEndDate,
                warning: isEmpty(data.CarIns.ThirdParty.policyEndDate.value),
              },
              descriptionAboutAccident: {
                ...data.CarIns.ThirdParty.descriptionAboutAccident,
                warning: isEmpty(
                  data.CarIns.ThirdParty.descriptionAboutAccident.value
                ),
              },
              detailsOfThirdParty: {
                ...data.CarIns.ThirdParty.detailsOfThirdParty,
                warning: isEmpty(
                  data.CarIns.ThirdParty.detailsOfThirdParty.value
                ),
              },
              deathOfThirdParty: {
                ...data.CarIns.ThirdParty.deathOfThirdParty,
                warning: isEmpty(
                  data.CarIns.ThirdParty.deathOfThirdParty.value
                ),
              },
              detailsOfThirdPropertyDamage: {
                ...data.CarIns.ThirdParty.detailsOfThirdPropertyDamage,
                warning: isEmpty(
                  data.CarIns.ThirdParty.detailsOfThirdPropertyDamage.value
                ),
              },
              claimStatus: {
                ...data.CarIns.ThirdParty.claimStatus,
                warning: isEmpty(data.CarIns.ThirdParty.claimStatus.value),
              },
              whatDoYouExpect: {
                ...data.CarIns.ThirdParty.whatDoYouExpect,
                warning: isEmpty(data.CarIns.ThirdParty.whatDoYouExpect.value),
              },
              copyOfRC: {
                ...data.CarIns.ThirdParty.copyOfRC,
                warning: isEmpty(data.CarIns.ThirdParty.copyOfRC.docFile),
              },
              copyOfPolicyDoc: {
                ...data.CarIns.ThirdParty.copyOfPolicyDoc,
                warning: isEmpty(
                  data.CarIns.ThirdParty.copyOfPolicyDoc.docFile
                ),
              },
              copyOfComplaint: {
                ...data.CarIns.ThirdParty.copyOfComplaint,
                warning: data.CarIns.ThirdParty.reportedAtPoliceStation
                  ? isEmpty(data.CarIns.ThirdParty.copyOfComplaint.docFile)
                  : false,
              },
              copyOfDrivingLicence: {
                ...data.CarIns.ThirdParty.copyOfDrivingLicence,
                warning: isEmpty(
                  data.CarIns.ThirdParty.copyOfDrivingLicence.docFile
                ),
              },
              accidentPhotos: {
                ...data.CarIns.ThirdParty.accidentPhotos,
                warning:
                  data.CarIns.ThirdParty.accidentPhotos.accidentPhotos.length >
                  0
                    ? false
                    : true,
              },
            },
          },
        };
      }
    } else if (data.Common.claimFor === "Health Insurance") {
      if (data.HealthIns.claimType === "Cashless") {
        data = {
          ...data,
          HealthIns: {
            ...data.HealthIns,
            cashLess: {
              ...data.HealthIns.cashLess,
              dateOfAdmission: {
                ...data.HealthIns.cashLess.dateOfAdmission,
                warning: isEmpty(data.HealthIns.cashLess.dateOfAdmission.value),
              },
              dateOfDiscahrge: {
                ...data.HealthIns.cashLess.dateOfDiscahrge,
                warning: isEmpty(data.HealthIns.cashLess.dateOfDiscahrge.value),
              },
              reasonForRejection: {
                ...data.HealthIns.cashLess.reasonForRejection,
                warning:
                  data.HealthIns.cashLess.claimStatus === "Rejected"
                    ? isEmpty(data.HealthIns.cashLess.reasonForRejection.value)
                    : false,
              },
              reasonForPending: {
                ...data.HealthIns.cashLess.reasonForPending,
                warning:
                  data.HealthIns.cashLess.claimStatus === "Pending"
                    ? isEmpty(data.HealthIns.cashLess.reasonForPending.value)
                    : false,
              },
              dischargeSummary: {
                ...data.HealthIns.cashLess.dischargeSummary,
                warning: isEmpty(
                  data.HealthIns.cashLess.dischargeSummary.value
                ),
              },
              finalBillBreakup: {
                ...data.HealthIns.cashLess.finalBillBreakup,
                warning: isEmpty(
                  data.HealthIns.cashLess.finalBillBreakup.value
                ),
              },
              whatDoYouExpect: {
                ...data.HealthIns.cashLess.whatDoYouExpect,
                warning: isEmpty(data.HealthIns.cashLess.whatDoYouExpect.value),
              },

              copyOfCaseSheet: {
                ...data.HealthIns.cashLess.copyOfCaseSheet,
                warning: isEmpty(
                  data.HealthIns.cashLess.copyOfCaseSheet.docFile
                ),
              },
              copyOfHealthCard: {
                ...data.HealthIns.cashLess.copyOfHealthCard,
                warning: isEmpty(
                  data.HealthIns.cashLess.copyOfHealthCard.docFile
                ),
              },
              copyOfCashReceipt: {
                ...data.HealthIns.cashLess.copyOfCashReceipt,
                warning: isEmpty(
                  data.HealthIns.cashLess.copyOfCashReceipt.docFile
                ),
              },
              copyOfPharmacyBill: {
                ...data.HealthIns.cashLess.copyOfPharmacyBill,
                warning: isEmpty(
                  data.HealthIns.cashLess.copyOfPharmacyBill.docFile
                ),
              },
              copyOfInvestigationReport: {
                ...data.HealthIns.cashLess.copyOfInvestigationReport,
                warning: isEmpty(
                  data.HealthIns.cashLess.copyOfInvestigationReport.docFile
                ),
              },
              copyOfPolicyDoc: {
                ...data.HealthIns.cashLess.copyOfPolicyDoc,
                warning: isEmpty(
                  data.HealthIns.cashLess.copyOfPolicyDoc.docFile
                ),
              },
              copyOfRejectionLetter: {
                ...data.HealthIns.cashLess.copyOfRejectionLetter,
                warning:
                  data.HealthIns.cashLess.claimStatus === "Rejected"
                    ? data.HealthIns.cashLess.copyOfRejectionLetter.docFile
                        .length === 0
                    : false,
              },
            },
          },
        };
      } else if (data.HealthIns.claimType === "Reimbursement") {
        data = {
          ...data,
          HealthIns: {
            ...data.HealthIns,
            reimbursement: {
              ...data.HealthIns.reimbursement,
              dateOfAdmission: {
                ...data.HealthIns.reimbursement.dateOfAdmission,
                warning: isEmpty(
                  data.HealthIns.reimbursement.dateOfAdmission.value
                ),
              },
              dateOfDiscahrge: {
                ...data.HealthIns.reimbursement.dateOfDiscahrge,
                warning: isEmpty(
                  data.HealthIns.reimbursement.dateOfDiscahrge.value
                ),
              },
              dischargeSummary: {
                ...data.HealthIns.reimbursement.dischargeSummary,
                warning: isEmpty(
                  data.HealthIns.reimbursement.dischargeSummary.value
                ),
              },
              finalBillBreakup: {
                ...data.HealthIns.reimbursement.finalBillBreakup,
                warning: isEmpty(
                  data.HealthIns.reimbursement.finalBillBreakup.value
                ),
              },
              whatDoYouExpect: {
                ...data.HealthIns.reimbursement.whatDoYouExpect,
                warning: isEmpty(
                  data.HealthIns.reimbursement.whatDoYouExpect.value
                ),
              },

              copyOfCaseSheet: {
                ...data.HealthIns.reimbursement.copyOfCaseSheet,
                warning: isEmpty(
                  data.HealthIns.reimbursement.copyOfCaseSheet.docFile
                ),
              },
              copyOfHealthCard: {
                ...data.HealthIns.reimbursement.copyOfHealthCard,
                warning: isEmpty(
                  data.HealthIns.reimbursement.copyOfHealthCard.docFile
                ),
              },
              copyOfCashReceipt: {
                ...data.HealthIns.reimbursement.copyOfCashReceipt,
                warning: isEmpty(
                  data.HealthIns.reimbursement.copyOfCashReceipt.docFile
                ),
              },
              copyOfPharmacyBill: {
                ...data.HealthIns.reimbursement.copyOfPharmacyBill,
                warning: isEmpty(
                  data.HealthIns.reimbursement.copyOfPharmacyBill.docFile
                ),
              },
              copyOfInvestigationReport: {
                ...data.HealthIns.reimbursement.copyOfInvestigationReport,
                warning: isEmpty(
                  data.HealthIns.reimbursement.copyOfInvestigationReport.docFile
                ),
              },
              copyOfPolicyDoc: {
                ...data.HealthIns.reimbursement.copyOfPolicyDoc,
                warning: isEmpty(
                  data.HealthIns.reimbursement.copyOfPolicyDoc.docFile
                ),
              },
              copyOfRejectionLetter: {
                ...data.HealthIns.reimbursement.copyOfRejectionLetter,
                warning:
                  data.HealthIns.reimbursement.claimStatus === "Rejected"
                    ? data.HealthIns.reimbursement.copyOfRejectionLetter.docFile
                        .length === 0
                    : false,
              },
              reasonForPending: {
                ...data.HealthIns.reimbursement.reasonForPending,
                warning:
                  data.HealthIns.reimbursement.claimStatus === "Pending"
                    ? isEmpty(
                        data.HealthIns.reimbursement.reasonForPending.value
                      )
                    : false,
              },
              reasonForRejection: {
                ...data.HealthIns.reimbursement.reasonForRejection,
                warning:
                  data.HealthIns.reimbursement.claimStatus === "Rejected"
                    ? isEmpty(
                        data.HealthIns.reimbursement.reasonForRejection.value
                      )
                    : false,
              },
            },
          },
        };
        if (data.HealthIns.reimbursement.claimStatus === "Pending") {
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                reasonForPending: {
                  ...data.HealthIns.reimbursement.reasonForPending,
                  warning: isEmpty(
                    data.HealthIns.reimbursement.reasonForPending.value
                  ),
                },
              },
            },
          };
        } else if (data.HealthIns.reimbursement.claimStatus === "Rejected") {
          data = {
            ...data,
            HealthIns: {
              ...data.HealthIns,
              reimbursement: {
                ...data.HealthIns.reimbursement,
                reasonForRejection: {
                  ...data.HealthIns.reimbursement.reasonForRejection,
                  warning: isEmpty(
                    data.HealthIns.reimbursement.reasonForRejection.value
                  ),
                },
              },
            },
          };
        }
      } else if (data.HealthIns.claimType === "Claim Partially Settled") {
        data = {
          ...data,
          HealthIns: {
            ...data.HealthIns,
            ClaimPartiallySettled: {
              ...data.HealthIns.ClaimPartiallySettled,
              dateOfAdmission: {
                ...data.HealthIns.ClaimPartiallySettled.dateOfAdmission,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.dateOfAdmission.value
                ),
              },
              dateOfDiscahrge: {
                ...data.HealthIns.ClaimPartiallySettled.dateOfDiscahrge,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.dateOfDiscahrge.value
                ),
              },
              reasonForPending: {
                ...data.HealthIns.ClaimPartiallySettled.reasonForPending,
                warning:
                  data.HealthIns.reimbursement.claimStatus === "Pending"
                    ? isEmpty(
                        data.HealthIns.ClaimPartiallySettled.reasonForPending
                          .value
                      )
                    : false,
              },
              reasonForRejection: {
                ...data.HealthIns.ClaimPartiallySettled.reasonForRejection,
                warning:
                  data.HealthIns.ClaimPartiallySettled.claimStatus ===
                  "Rejected"
                    ? isEmpty(
                        data.HealthIns.ClaimPartiallySettled.reasonForRejection
                          .value
                      )
                    : false,
              },
              dischargeSummary: {
                ...data.HealthIns.ClaimPartiallySettled.dischargeSummary,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.dischargeSummary.value
                ),
              },
              finalBillBreakup: {
                ...data.HealthIns.ClaimPartiallySettled.finalBillBreakup,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.finalBillBreakup.value
                ),
              },
              whatDoYouExpect: {
                ...data.HealthIns.ClaimPartiallySettled.whatDoYouExpect,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.whatDoYouExpect.value
                ),
              },

              copyOfCaseSheet: {
                ...data.HealthIns.ClaimPartiallySettled.copyOfCaseSheet,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.copyOfCaseSheet.docFile
                ),
              },
              copyOfHealthCard: {
                ...data.HealthIns.ClaimPartiallySettled.copyOfHealthCard,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.copyOfHealthCard.docFile
                ),
              },
              copyOfCashReceipt: {
                ...data.HealthIns.ClaimPartiallySettled.copyOfCashReceipt,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.copyOfCashReceipt.docFile
                ),
              },
              copyOfPharmacyBill: {
                ...data.HealthIns.ClaimPartiallySettled.copyOfPharmacyBill,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.copyOfPharmacyBill
                    .docFile
                ),
              },
              copyOfInvestigationReport: {
                ...data.HealthIns.ClaimPartiallySettled
                  .copyOfInvestigationReport,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.copyOfInvestigationReport
                    .docFile
                ),
              },
              copyOfPolicyDoc: {
                ...data.HealthIns.ClaimPartiallySettled.copyOfPolicyDoc,
                warning: isEmpty(
                  data.HealthIns.ClaimPartiallySettled.copyOfPolicyDoc.docFile
                ),
              },
              copyOfRejectionLetter: {
                ...data.HealthIns.ClaimPartiallySettled.copyOfRejectionLetter,
                warning:
                  data.HealthIns.ClaimPartiallySettled.claimStatus ===
                  "Rejected"
                    ? data.HealthIns.ClaimPartiallySettled.copyOfRejectionLetter
                        .docFile.length === 0
                    : false,
              },
            },
          },
        };
      }
    } else if (data.Common.claimFor === "Two Wheeler Insurance") {
      if (data.TwIns.claimType === "Own Damage Claim") {
        data = {
          ...data,
          TwIns: {
            ...data.TwIns,
            OwnDamage: {
              ...data.TwIns.OwnDamage,
              vehicleNumber: {
                ...data.TwIns.OwnDamage.vehicleNumber,
                warning: !validateRegnoNewMotor(
                  data.TwIns.OwnDamage.vehicleNumber.value
                ),
              },
              dateTimeOfAccident: {
                ...data.TwIns.OwnDamage.dateTimeOfAccident,
                warning: isEmpty(data.TwIns.OwnDamage.dateTimeOfAccident.value),
              },
              placeOfAccident: {
                ...data.TwIns.OwnDamage.placeOfAccident,
                warning: isEmpty(data.TwIns.OwnDamage.placeOfAccident.value),
              },
              driverAtTimeOfAccident: {
                ...data.TwIns.OwnDamage.driverAtTimeOfAccident,
                warning: isEmpty(
                  data.TwIns.OwnDamage.driverAtTimeOfAccident.value
                ),
              },
              drivingLicenseNo: {
                ...data.TwIns.OwnDamage.drivingLicenseNo,
                warning: isEmpty(data.TwIns.OwnDamage.drivingLicenseNo.value),
              },
              drivingLicenseValidUpto: {
                ...data.TwIns.OwnDamage.drivingLicenseValidUpto,
                warning: isEmpty(
                  data.TwIns.OwnDamage.drivingLicenseValidUpto.value
                ),
              },
              typeOfDrivingLicense: {
                ...data.TwIns.OwnDamage.typeOfDrivingLicense,
                warning: isEmpty(
                  data.TwIns.OwnDamage.typeOfDrivingLicense.value
                ),
              },
              insurer: {
                ...data.TwIns.OwnDamage.insurer,
                warning: isEmpty(data.TwIns.OwnDamage.insurer.value),
              },
              policyNumber: {
                ...data.TwIns.OwnDamage.policyNumber,
                warning: isEmpty(data.TwIns.OwnDamage.policyNumber.value),
              },
              policyStartDate: {
                ...data.TwIns.OwnDamage.policyStartDate,
                warning: isEmpty(data.TwIns.OwnDamage.policyStartDate.value),
              },
              policyEndDate: {
                ...data.TwIns.OwnDamage.policyEndDate,
                warning: isEmpty(data.TwIns.OwnDamage.policyEndDate.value),
              },
              descriptionAboutAccident: {
                ...data.TwIns.OwnDamage.descriptionAboutAccident,
                warning: isEmpty(
                  data.TwIns.OwnDamage.descriptionAboutAccident.value
                ),
              },
              claimStatus: {
                ...data.TwIns.OwnDamage.claimStatus,
                warning: isEmpty(data.TwIns.OwnDamage.claimStatus.value),
              },
              whatDoYouExpect: {
                ...data.TwIns.OwnDamage.whatDoYouExpect,
                warning: isEmpty(data.TwIns.OwnDamage.whatDoYouExpect.value),
              },

              copyOfDrivingLicence: {
                ...data.TwIns.OwnDamage.copyOfDrivingLicence,
                warning: isEmpty(
                  data.TwIns.OwnDamage.copyOfDrivingLicence.docFile
                ),
              },

              copyOfComplaint: {
                ...data.TwIns.OwnDamage.copyOfComplaint,
                warning: data.TwIns.OwnDamage.reportedAtPoliceStation
                  ? data.TwIns.OwnDamage.copyOfComplaint.docFile.length === 0
                  : false,
              },
              copyOfRC: {
                ...data.TwIns.OwnDamage.copyOfRC,
                warning: isEmpty(data.TwIns.OwnDamage.copyOfRC.docFile),
              },
              copyOfPolicyDoc: {
                ...data.TwIns.OwnDamage.copyOfPolicyDoc,
                warning: isEmpty(data.TwIns.OwnDamage.copyOfPolicyDoc.docFile),
              },
              accidentPhotos: {
                ...data.TwIns.OwnDamage.accidentPhotos,
                warning:
                  data.TwIns.OwnDamage.accidentPhotos.accidentPhotos.length > 0
                    ? false
                    : true,
              },
            },
          },
        };
      } else if (data.TwIns.claimType === "Theft Claim") {
        data = {
          ...data,
          TwIns: {
            ...data.TwIns,
            Theft: {
              ...data.TwIns.Theft,
              vehicleNumber: {
                ...data.TwIns.Theft.vehicleNumber,
                warning: !validateRegnoNewMotor(
                  data.TwIns.Theft.vehicleNumber.value
                ),
              },
              dateOfLossNoticed: {
                ...data.TwIns.Theft.dateOfLossNoticed,
                warning: isEmpty(data.TwIns.Theft.dateOfLossNoticed.value),
              },
              placeOfAccident: {
                ...data.TwIns.Theft.placeOfAccident,
                warning: isEmpty(data.TwIns.Theft.placeOfAccident.value),
              },
              insurer: {
                ...data.TwIns.Theft.insurer,
                warning: isEmpty(data.TwIns.Theft.insurer.value),
              },
              policyNumber: {
                ...data.TwIns.Theft.policyNumber,
                warning: isEmpty(data.TwIns.Theft.policyNumber.value),
              },
              policyStartDate: {
                ...data.TwIns.Theft.policyStartDate,
                warning: isEmpty(data.TwIns.Theft.policyStartDate.value),
              },
              policyEndDate: {
                ...data.TwIns.Theft.policyEndDate,
                warning: isEmpty(data.TwIns.Theft.policyEndDate.value),
              },
              descriptionAboutAccident: {
                ...data.TwIns.Theft.descriptionAboutAccident,
                warning: isEmpty(
                  data.TwIns.Theft.descriptionAboutAccident.value
                ),
              },
              claimStatus: {
                ...data.TwIns.Theft.claimStatus,
                warning: isEmpty(data.TwIns.Theft.claimStatus.value),
              },
              whatDoYouExpect: {
                ...data.TwIns.Theft.whatDoYouExpect,
                warning: isEmpty(data.TwIns.Theft.whatDoYouExpect.value),
              },

              // copyOfComplaint: {
              //   ...data.TwIns.Theft.copyOfComplaint,
              //   warning: isEmpty(data.TwIns.Theft.copyOfComplaint.docFile),
              // },
              copyOfRC: {
                ...data.TwIns.Theft.copyOfRC,
                warning: isEmpty(data.TwIns.Theft.copyOfRC.docFile),
              },
              copyOfPolicyDoc: {
                ...data.TwIns.Theft.copyOfPolicyDoc,
                warning: isEmpty(data.TwIns.Theft.copyOfPolicyDoc.docFile),
              },
              copyOfFinalReport: {
                ...data.TwIns.Theft.copyOfPolicyDoc,
                warning: isEmpty(data.TwIns.Theft.copyOfPolicyDoc.docFile),
              },
            },
          },
        };
      } else if (data.TwIns.claimType === "Third Party Claim") {
        data = {
          ...data,
          TwIns: {
            ...data.TwIns,
            ThirdParty: {
              ...data.TwIns.ThirdParty,
              vehicleNumber: {
                ...data.TwIns.ThirdParty.vehicleNumber,
                warning: !validateRegnoNewMotor(
                  data.TwIns.ThirdParty.vehicleNumber.value
                ),
              },
              dateTimeOfAccident: {
                ...data.TwIns.ThirdParty.dateTimeOfAccident,
                warning: isEmpty(
                  data.TwIns.ThirdParty.dateTimeOfAccident.value
                ),
              },
              placeOfAccident: {
                ...data.TwIns.ThirdParty.placeOfAccident,
                warning: isEmpty(data.TwIns.ThirdParty.placeOfAccident.value),
              },
              driverAtTimeOfAccident: {
                ...data.TwIns.ThirdParty.driverAtTimeOfAccident,
                warning: isEmpty(
                  data.TwIns.ThirdParty.driverAtTimeOfAccident.value
                ),
              },
              drivingLicenseNo: {
                ...data.TwIns.ThirdParty.drivingLicenseNo,
                warning: isEmpty(data.TwIns.ThirdParty.drivingLicenseNo.value),
              },
              drivingLicenseValidUpto: {
                ...data.TwIns.ThirdParty.drivingLicenseValidUpto,
                warning: isEmpty(
                  data.TwIns.ThirdParty.drivingLicenseValidUpto.value
                ),
              },
              typeOfDrivingLicense: {
                ...data.TwIns.ThirdParty.typeOfDrivingLicense,
                warning: isEmpty(
                  data.TwIns.ThirdParty.typeOfDrivingLicense.value
                ),
              },
              insurer: {
                ...data.TwIns.ThirdParty.insurer,
                warning: isEmpty(data.TwIns.ThirdParty.insurer.value),
              },
              policyNumber: {
                ...data.TwIns.ThirdParty.policyNumber,
                warning: isEmpty(data.TwIns.ThirdParty.policyNumber.value),
              },
              policyStartDate: {
                ...data.TwIns.ThirdParty.policyStartDate,
                warning: isEmpty(data.TwIns.ThirdParty.policyStartDate.value),
              },
              policyEndDate: {
                ...data.TwIns.ThirdParty.policyEndDate,
                warning: isEmpty(data.TwIns.ThirdParty.policyEndDate.value),
              },
              descriptionAboutAccident: {
                ...data.TwIns.ThirdParty.descriptionAboutAccident,
                warning: isEmpty(
                  data.TwIns.ThirdParty.descriptionAboutAccident.value
                ),
              },
              detailsOfThirdParty: {
                ...data.TwIns.ThirdParty.detailsOfThirdParty,
                warning: isEmpty(
                  data.TwIns.ThirdParty.detailsOfThirdParty.value
                ),
              },
              deathOfThirdParty: {
                ...data.TwIns.ThirdParty.deathOfThirdParty,
                warning: isEmpty(data.TwIns.ThirdParty.deathOfThirdParty.value),
              },
              detailsOfThirdPropertyDamage: {
                ...data.TwIns.ThirdParty.detailsOfThirdPropertyDamage,
                warning: isEmpty(
                  data.TwIns.ThirdParty.detailsOfThirdPropertyDamage.value
                ),
              },
              claimStatus: {
                ...data.TwIns.ThirdParty.claimStatus,
                warning: isEmpty(data.TwIns.ThirdParty.claimStatus.value),
              },
              whatDoYouExpect: {
                ...data.TwIns.ThirdParty.whatDoYouExpect,
                warning: isEmpty(data.TwIns.ThirdParty.whatDoYouExpect.value),
              },

              copyOfComplaint: {
                ...data.TwIns.ThirdParty.copyOfComplaint,

                warning: data.TwIns.ThirdParty.reportedAtPoliceStation
                  ? data.TwIns.ThirdParty.copyOfComplaint.docFile.length === 0
                  : false,
              },

              copyOfRC: {
                ...data.TwIns.ThirdParty.copyOfRC,
                warning: isEmpty(data.TwIns.ThirdParty.copyOfRC.docFile),
              },
              copyOfPolicyDoc: {
                ...data.TwIns.ThirdParty.copyOfPolicyDoc,
                warning: isEmpty(data.TwIns.ThirdParty.copyOfPolicyDoc.docFile),
              },
              copyOfDrivingLicence: {
                ...data.TwIns.ThirdParty.copyOfDrivingLicence,
                warning: isEmpty(
                  data.TwIns.ThirdParty.copyOfDrivingLicence.docFile
                ),
              },
              dateOfLossNoticed: {
                ...data.TwIns.ThirdParty.dateOfLossNoticed,
                warning: isEmpty(data.TwIns.ThirdParty.dateOfLossNoticed.value),
              },
              accidentPhotos: {
                ...data.TwIns.ThirdParty.accidentPhotos,
                warning:
                  data.TwIns.ThirdParty.accidentPhotos.accidentPhotos.length > 0
                    ? false
                    : true,
              },
            },
          },
        };
      }
    } else if (data.Common.claimFor === "Term Insurance") {
      if (data.termIns.claimType === "Rejected") {
        data = {
          ...data,
          termIns: {
            ...data.termIns,
            reasonForRejection: {
              ...data.termIns.reasonForRejection,
              warning: isEmpty(data.termIns.reasonForRejection.value),
            },
          },
        };
      } else if (data.termIns.claimType === "Pending") {
        data = {
          ...data,
          termIns: {
            ...data.termIns,
            reasonForPending: {
              ...data.termIns.reasonForPending,
              warning: isEmpty(data.termIns.reasonForPending.value),
            },
          },
        };
      }
      data = {
        ...data,
        termIns: {
          ...data.termIns,
          dateOfDeath: {
            ...data.termIns.dateOfDeath,
            warning: isEmpty(data.termIns.dateOfDeath.value),
          },
          dateOfSubmisionOfDoc: {
            ...data.termIns.dateOfSubmisionOfDoc,
            warning: isEmpty(data.termIns.dateOfSubmisionOfDoc.value),
          },
          whatDoYouExpect: {
            ...data.termIns.whatDoYouExpect,
            warning: isEmpty(data.termIns.whatDoYouExpect.value),
          },
          policyDoc: {
            ...data.termIns.policyDoc,
            warning: isEmpty(data.termIns.policyDoc.docFile),
          },
        },
      };
    }
    // SET DATA WARNINGS AFTER VALIDATE ALL FIELDS
    setInputFields({ ...data });
    let hasError = false;
    Object.values(data.Common)?.map((item) => {
      Object.values(item).map((self) => {
        if (typeof self === "boolean") {
          if (self === true) {
            hasError = true;
          }
        }
      });
    });
    if (data.Common.claimFor === "Car Insurance") {
      if (data.CarIns.claimType === "Own Damage Claim") {
        Object.values(data.CarIns.OwnDamage)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      } else if (data.CarIns.claimType === "Theft Claim") {
        Object.values(data.CarIns.Theft)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      } else if (data.CarIns.claimType === "Third Party Claim") {
        Object.values(data.CarIns.ThirdParty)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      }
    } else if (data.Common.claimFor === "Health Insurance") {
      if (data.HealthIns.claimType === "Cashless") {
        console.log("cash less", Object.values(data.HealthIns.cashLess));
        Object.values(data.HealthIns.cashLess)?.map((item) => {
          Object.values(item).map((self) => {
            console.log("self", item);
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      } else if (data.HealthIns.claimType === "Reimbursement") {
        if (
          data.HealthIns.reimbursement.dateOfAdmission.warning ||
          data.HealthIns.reimbursement.dateOfDiscahrge.warning ||
          data.HealthIns.reimbursement.dischargeSummary.warning ||
          data.HealthIns.reimbursement.finalBillBreakup.warning ||
          data.HealthIns.reimbursement.whatDoYouExpect.warning
        ) {
          hasError = true;
        }
        Object.values(data.HealthIns.reimbursement)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
        if (data.HealthIns.reimbursement.claimStatus === "Pending") {
          if (data.HealthIns.reimbursement.reasonForPending.warning) {
            hasError = true;
          }
        } else if (data.HealthIns.reimbursement.claimStatus === "Rejected") {
          if (data.HealthIns.reimbursement.reasonForRejection.warning) {
            hasError = true;
          }
        }
      } else if (data.HealthIns.claimType === "Claim Partially Settled") {
        Object.values(data.HealthIns.ClaimPartiallySettled)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      }
    } else if (data.Common.claimFor === "Two Wheeler Insurance") {
      if (data.TwIns.claimType === "Own Damage Claim") {
        Object.values(data.TwIns.OwnDamage)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      } else if (data.TwIns.claimType === "Theft Claim") {
        Object.values(data.TwIns.Theft)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      } else if (data.TwIns.claimType === "Third Party Claim") {
        Object.values(data.TwIns.ThirdParty)?.map((item) => {
          Object.values(item).map((self) => {
            if (typeof self === "boolean") {
              if (self === true) {
                hasError = true;
              }
            }
          });
        });
      }
    } else if (data.Common.claimFor === "Term Insurance") {
      if (
        data.termIns.dateOfDeath.warning ||
        data.termIns.dateOfSubmisionOfDoc.warning ||
        data.termIns.whatDoYouExpect.warning
      ) {
        hasError = true;
      }
      if (data.termIns.claimType === "Rejected") {
        if (data.termIns.reasonForRejection.warning) {
          hasError = true;
        }
      } else if (data.termIns.claimType === "Pending") {
        if (data.termIns.reasonForPending.warning) {
          hasError = true;
        }
      }
    }
    if (!hasError) {
      if (isTermsAccepted) {
        submitForm();
      } else {
        toast.error("Please Accept The Declaration");
      }
    }
    // else {
    //   toast.error("Please enter all the mandetory fields");
    // }
  };

  const submitForm = () => {
    let data = { ...inputFields };
    let commonData = data.Common;
    let formData = new FormData();
    if (data.Common.claimFor === "Car Insurance") {
      if (data.CarIns.claimType === "Own Damage Claim") {
        let OwnDamage = data.CarIns.OwnDamage;
        formData.append("subject", "zoho");
        formData.append("rcFile", OwnDamage.copyOfRC.docFile);
        formData.append(
          "description",
          OwnDamage.descriptionAboutAccident.value
        );
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", OwnDamage.claimStatus.value);
        formData.append("priority", "Very High");
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.CarIns.claimType);
        formData.append(
          "typeOfLicenceFile",
          OwnDamage.copyOfDrivingLicence.docFile
        );
        formData.append(
          "policyDocumentsFile",
          OwnDamage.copyOfPolicyDoc.docFile
        );
        formData.append("vehicleNo", OwnDamage.vehicleNumber.value); //InAll car two thrid theft
        formData.append(
          "dateAndTimeOfAccident",
          OwnDamage.dateTimeOfAccident.value
        );
        formData.append("placeOfAccident", OwnDamage.placeOfAccident.value);
        formData.append(
          "driverOfTheAccident",
          OwnDamage.driverAtTimeOfAccident.value
        );
        formData.append("drivingLicenceNo", OwnDamage.drivingLicenseNo.value);
        formData.append(
          "drivingLicenceUpto",
          OwnDamage.drivingLicenseValidUpto.value
        );
        formData.append("typeOfLicence", OwnDamage.typeOfDrivingLicense.value);

        formData.append("claimStatus", OwnDamage.claimStatus.value);
        formData.append("expectUs", OwnDamage.whatDoYouExpect.value);
        formData.append("PolicyNo", OwnDamage.policyNumber.value);
        formData.append("policyStartDate", OwnDamage.policyStartDate.value);
        formData.append("policyEndDate", OwnDamage.policyEndDate.value);
        formData.append("insurerName", OwnDamage.insurer.value);

        if (OwnDamage.reportedAtPoliceStation) {
          formData.append("firCopyFile", OwnDamage.copyOfComplaint.docFile);
        }

        // OwnDamage.accidentPhotos.accidentPhotos.forEach((photo, index) => {
        formData.append(
          `accidentPhotos`,
          OwnDamage.accidentPhotos.accidentPhotos?.[0]
        );
        // });
      } else if (data.CarIns.claimType === "Theft Claim") {
        let Theft = data.CarIns.Theft;
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", Theft.claimStatus.value);
        formData.append("priority", "Very High");
        formData.append("rcFile", Theft.copyOfRC.docFile);
        formData.append("dateOfLossNoticed", Theft.dateOfLossNoticed.value);
        formData.append("placeOfAccident", Theft.placeOfAccident.value);
        formData.append("insurerName", Theft.insurer.value);
        formData.append("policyDocumentsFile", Theft.copyOfPolicyDoc.docFile);
        formData.append("PolicyNo", Theft.policyNumber.value);
        formData.append("policyStartDate", Theft.policyStartDate.value);
        formData.append("policyEndDate", Theft.policyEndDate.value);
        formData.append("claimStatus", Theft.claimStatus.value);
        formData.append("firCopyFile", Theft.copyOfComplaint.docFile);
        formData.append(
          "fileReportfromPoliceStation",
          Theft.copyOfFinalReport.docFile
        );
        formData.append("description", Theft.descriptionAboutAccident.value);
        formData.append("expectUs", Theft.whatDoYouExpect.value);
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.CarIns.claimType);
        formData.append("vehicleNo", Theft.vehicleNumber.value);
      } else if (data.CarIns.claimType === "Third Party Claim") {
        let ThirdParty = data.CarIns.ThirdParty;
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.CarIns.claimType);
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", ThirdParty.claimStatus.value);
        formData.append("priority", "Very High");
        formData.append("rcFile", ThirdParty.copyOfRC.docFile);
        formData.append(
          "dateAndTimeOfAccident",
          ThirdParty.dateTimeOfAccident.value
        );
        formData.append("placeOfAccident", ThirdParty.placeOfAccident.value);
        formData.append(
          "driverOfTheAccident",
          ThirdParty.driverAtTimeOfAccident.value
        );
        formData.append("drivingLicenceNo", ThirdParty.drivingLicenseNo.value);
        formData.append(
          "drivingLicenceUpto",
          ThirdParty.drivingLicenseValidUpto.value
        );
        formData.append(
          "typeOfLicenceFile",
          ThirdParty.copyOfDrivingLicence.docFile
        );
        formData.append("typeOfLicence", ThirdParty.typeOfDrivingLicense.value);
        formData.append("claimStatus", ThirdParty.claimStatus.value);
        formData.append("insurerName", ThirdParty.insurer.value);
        formData.append("PolicyNo", ThirdParty.policyNumber.value);
        formData.append("policyStartDate", ThirdParty.policyStartDate.value);
        formData.append("policyEndDate", ThirdParty.policyEndDate.value);
        formData.append(
          "policyDocumentsFile",
          ThirdParty.copyOfPolicyDoc.docFile
        );
        formData.append(
          "description",
          ThirdParty.descriptionAboutAccident.value
        );
        formData.append("expectUs", ThirdParty.whatDoYouExpect.value);
        formData.append(
          "dateOfLossNoticed",
          ThirdParty.dateOfLossNoticed.value
        ); //New
        formData.append(
          "detailsOfTpDeaths",
          ThirdParty.deathOfThirdParty.value
        );
        formData.append(
          "detailsOfTpBodyInjury",
          ThirdParty.detailsOfThirdParty.value
        );
        formData.append(
          "detailsOfPropertyDamage",
          ThirdParty.detailsOfThirdPropertyDamage.value
        );
        // ThirdParty.accidentPhotos.accidentPhotos.forEach((photo, index) => {
        formData.append(
          `accidentPhotos`,
          ThirdParty.accidentPhotos.accidentPhotos?.[0]
        );
        // });
        formData.append("vehicleNo", ThirdParty.vehicleNumber.value);
        if (ThirdParty.reportedAtPoliceStation) {
          formData.append("firCopyFile", ThirdParty.copyOfComplaint.docFile);
        }
        // formData.append("drivingLicenceNo", ThirdParty.copyOfDrivingLicence.value);
      }
    } else if (data.Common.claimFor === "Health Insurance") {
      if (data.HealthIns.claimType === "Cashless") {
        let Cashless = data.HealthIns.cashLess;
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", Cashless.claimStatus);
        formData.append("claimStatus", Cashless.claimStatus);
        formData.append("priority", "very high");
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.HealthIns.claimType);
        formData.append(
          "policyDocumentsFile",
          Cashless.copyOfPolicyDoc.docFile
        );
        formData.append("healthCardfile", Cashless.copyOfHealthCard.docFile);
        formData.append("cashPaidReciept", Cashless.copyOfCashReceipt.docFile);
        formData.append(
          "investigationReportsAndBreakUpbill",
          Cashless.copyOfInvestigationReport.docFile
        );
        formData.append("pharmacyBill", Cashless.copyOfPharmacyBill.docFile);
        formData.append("caseSheet", Cashless.copyOfCaseSheet.docFile);
        formData.append("dateOfAdmission", Cashless.dateOfAdmission.value); //DD:MM:YY
        formData.append("dateOfDischarge", Cashless.dateOfDiscahrge.value); //DD:MM:YY
        if (Cashless.claimStatus === "Rejected") {
          formData.append(
            "reasonForRejection",
            Cashless.reasonForRejection.value
          );
          formData.append(
            "rejectionLetter",
            Cashless.copyOfRejectionLetter.docFile
          );
        } else {
          formData.append("reasonForPending", Cashless.reasonForPending.value);
        }
        formData.append("dischargeSummary", Cashless.dischargeSummary.value);
        formData.append(
          "finalBillBreakupSummary",
          Cashless.finalBillBreakup.value
        );
        formData.append("customerType", Cashless.customerType);
      } else if (data.HealthIns.claimType === "Reimbursement") {
        let Reimbursement = data.HealthIns.reimbursement;
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", Reimbursement.claimStatus);
        formData.append("claimStatus", Reimbursement.claimStatus);
        formData.append("priority", "very high");
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.HealthIns.claimType);
        formData.append(
          "policyDocumentsFile",
          Reimbursement.copyOfPolicyDoc.docFile
        );
        formData.append(
          "healthCardfile",
          Reimbursement.copyOfHealthCard.docFile
        );
        formData.append(
          "cashPaidReciept",
          Reimbursement.copyOfCashReceipt.docFile
        );
        formData.append(
          "investigationReportsAndBreakUpbill",
          Reimbursement.copyOfInvestigationReport.docFile
        );
        formData.append(
          "pharmacyBill",
          Reimbursement.copyOfPharmacyBill.docFile
        );
        formData.append("caseSheet", Reimbursement.copyOfCaseSheet.docFile);
        formData.append("dateOfAdmission", Reimbursement.dateOfAdmission.value); //DD:MM:YY
        formData.append("dateOfDischarge", Reimbursement.dateOfDiscahrge.value); //DD:MM:YY
        if (Reimbursement.claimStatus === "Rejected") {
          formData.append(
            "reasonForRejection",
            Reimbursement.reasonForRejection.value
          );
          formData.append(
            "rejectionLetter",
            Reimbursement.copyOfRejectionLetter.docFile
          );
        } else {
          formData.append(
            "reasonForPending",
            Reimbursement.reasonForPending.value
          );
        }
        formData.append(
          "dischargeSummary",
          Reimbursement.dischargeSummary.value
        );
        formData.append(
          "finalBillBreakupSummary",
          Reimbursement.finalBillBreakup.value
        );
        formData.append("customerType", Reimbursement.customerType);
      } else if (data.HealthIns.claimType === "Claim Partially Settled") {
        let ClaimPartiallySettled = data.HealthIns.ClaimPartiallySettled;
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", ClaimPartiallySettled.claimStatus);
        formData.append("claimStatus", ClaimPartiallySettled.claimStatus);
        formData.append("priority", "very high");
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.HealthIns.claimType);
        formData.append(
          "policyDocumentsFile",
          ClaimPartiallySettled.copyOfPolicyDoc.docFile
        );
        formData.append(
          "healthCardfile",
          ClaimPartiallySettled.copyOfHealthCard.docFile
        );
        // formData.append('rejectionLetter', ClaimPartiallySettled.re.docFile);
        formData.append(
          "cashPaidReciept",
          ClaimPartiallySettled.copyOfCashReceipt.docFile
        );
        formData.append(
          "investigationReportsAndBreakUpbill",
          ClaimPartiallySettled.copyOfInvestigationReport.docFile
        );
        formData.append(
          "pharmacyBill",
          ClaimPartiallySettled.copyOfPharmacyBill.docFile
        );
        formData.append(
          "caseSheet",
          ClaimPartiallySettled.copyOfCaseSheet.docFile
        );
        formData.append(
          "dateOfAdmission",
          ClaimPartiallySettled.dateOfAdmission.value //DD:MM:YY
        );
        formData.append(
          "dateOfDischarge",
          ClaimPartiallySettled.dateOfDiscahrge.value //DD:MM:YY
        );
        if (ClaimPartiallySettled.claimStatus === "Rejected") {
          formData.append(
            "reasonForRejection",
            ClaimPartiallySettled.reasonForRejection.value
          );
          formData.append(
            "rejectionLetter",
            ClaimPartiallySettled.copyOfRejectionLetter.docFile
          );
        } else {
          formData.append(
            "reasonForPending",
            ClaimPartiallySettled.reasonForPending.value
          );
        }
        formData.append(
          "dischargeSummary",
          ClaimPartiallySettled.dischargeSummary.value
        );
        formData.append(
          "finalBillBreakupSummary",
          ClaimPartiallySettled.finalBillBreakup.value
        );
        formData.append("customerType", ClaimPartiallySettled.customerType);
      }
    } else if (data.Common.claimFor === "Two Wheeler Insurance") {
      if (data.TwIns.claimType === "Own Damage Claim") {
        let OwnDamage = data.TwIns.OwnDamage;

        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", OwnDamage.claimStatus.value);
        formData.append("priority", "very high");
        formData.append("rcFile", data.TwIns.OwnDamage.copyOfRC.docFile);
        formData.append(
          "policyDocumentsFile",
          OwnDamage.copyOfPolicyDoc.docFile
        );
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.CarIns.claimType);
        formData.append("vehicleNo", OwnDamage.vehicleNumber.value);
        formData.append(
          "dateAndTimeOfAccident",
          OwnDamage.dateTimeOfAccident.value
        );
        formData.append("placeOfAccident", OwnDamage.placeOfAccident.value);
        formData.append(
          "driverOfTheAccident",
          OwnDamage.driverAtTimeOfAccident.value
        );
        formData.append("drivingLicenceNo", OwnDamage.drivingLicenseNo.value);
        formData.append(
          "drivingLicenceUpto",
          OwnDamage.drivingLicenseValidUpto.value
        );
        formData.append("typeOfLicence", OwnDamage.typeOfDrivingLicense.value);
        formData.append("claimStatus", OwnDamage.claimStatus.value);
        formData.append(
          "description",
          OwnDamage.descriptionAboutAccident.value
        );
        formData.append("expectUs", OwnDamage.whatDoYouExpect.value);

        formData.append("insurerName", OwnDamage.insurer.value);
        formData.append("PolicyNo", OwnDamage.policyNumber.value);
        formData.append("policyStartDate", OwnDamage.policyStartDate.value);
        formData.append("policyEndDate", OwnDamage.policyEndDate.value);

        if (OwnDamage.reportedAtPoliceStation) {
          formData.append("firCopyFile", OwnDamage.copyOfComplaint.docFile);
        }
        formData.append(
          "typeOfLicenceFile",
          OwnDamage.copyOfDrivingLicence.docFile
        );

        // OwnDamage.accidentPhotos.accidentPhotos.forEach((photo, index) => {
        formData.append(
          `accidentPhotos`,
          OwnDamage.accidentPhotos.accidentPhotos?.[0]
        );
        // });
      } else if (data.TwIns.claimType === "Theft Claim") {
        let Theft = data.TwIns.Theft;
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", Theft.claimStatus.value);
        formData.append("priority", "very high");
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.TwIns.claimType);
        formData.append("rcFile", Theft.copyOfRC.docFile);
        formData.append("policyDocumentsFile", Theft.copyOfPolicyDoc.value);
        formData.append("dateOfLossNoticed", Theft.dateOfLossNoticed.value);
        formData.append("placeOfAccident", Theft.placeOfAccident.value);
        formData.append("insurerName", Theft.insurer.value);
        formData.append("PolicyNo", Theft.policyNumber.value);
        formData.append("policyStartDate", Theft.policyStartDate.value);
        formData.append("policyEndDate", Theft.policyEndDate.value);
        formData.append("claimStatus", Theft.claimStatus.value);
        formData.append("description", Theft.descriptionAboutAccident.value);
        formData.append("expectUs", Theft.whatDoYouExpect.value);
        formData.append("vehicleNo", Theft.vehicleNumber.value);
        formData.append("firCopyFile", Theft.copyOfComplaint.docFile);
        formData.append("policyDocumentsFile", Theft.copyOfPolicyDoc.docFile);
        formData.append(
          "fileReportfromPoliceStation",
          Theft.copyOfFinalReport.docFile
        );
      } else if (data.TwIns.claimType === "Third Party Claim") {
        let ThirdParty = data.TwIns.ThirdParty;
        formData.append("subject", "zoho");
        formData.append("email", commonData.email.value);
        formData.append("phone", commonData.mobile.value);
        formData.append("status", "true");
        formData.append("priority", "very high");
        formData.append("claimFor", commonData.claimFor);
        formData.append("claimType", data.TwIns.claimType);
        formData.append("rcFile", ThirdParty.copyOfRC.docFile); // Assuming you have an input with id 'rcFile'
        formData.append(
          "policyDocumentsFile",
          ThirdParty.copyOfPolicyDoc.docFile
        ); // Assuming you have an input with id 'policyDocumentsFile'
        formData.append(
          "dateAndTimeOfAccident",
          ThirdParty.dateTimeOfAccident.value
        );
        formData.append("placeOfAccident", ThirdParty.placeOfAccident.value);
        formData.append(
          "driverOfTheAccident",
          ThirdParty.driverAtTimeOfAccident.value
        );
        formData.append("drivingLicenceNo", ThirdParty.drivingLicenseNo.value);
        formData.append(
          "drivingLicenceUpto",
          ThirdParty.drivingLicenseValidUpto.value
        );
        formData.append("typeOfLicence", ThirdParty.typeOfDrivingLicense.value);
        formData.append("claimStatus", ThirdParty.claimStatus.value);
        formData.append(
          "dateOfLossNoticed",
          ThirdParty.dateOfLossNoticed.value
        ); // New
        formData.append("insurerName", ThirdParty.insurer.value);
        formData.append("PolicyNo", ThirdParty.policyNumber.value);
        formData.append("policyStartDate", ThirdParty.policyStartDate.value);
        formData.append("policyEndDate", ThirdParty.policyEndDate.value);
        formData.append(
          "description",
          ThirdParty.descriptionAboutAccident.value
        );
        formData.append("expectUs", ThirdParty.whatDoYouExpect.value);
        formData.append(
          "detailsOfTpDeaths",
          ThirdParty.deathOfThirdParty.value
        );
        formData.append(
          "detailsOfTpBodyInjury",
          ThirdParty.detailsOfThirdParty.value
        );
        formData.append(
          "detailsOfPropertyDamage",
          ThirdParty.detailsOfThirdPropertyDamage.value
        );
        formData.append("vehicleNo", ThirdParty.vehicleNumber.value);

        formData.append(
          "typeOfLicenceFile",
          ThirdParty.copyOfDrivingLicence.docFile
        );
        if (ThirdParty.reportedAtPoliceStation) {
          formData.append("firCopyFile", ThirdParty.copyOfComplaint.docFile);
        }
        // ThirdParty.accidentPhotos.accidentPhotos.forEach((photo, index) => {
        formData.append(
          `accidentPhotos`,
          ThirdParty.accidentPhotos.accidentPhotos?.[0]
        );
        // });
      }
    } else if (data.Common.claimFor === "Term Insurance") {
      let termIns = data.termIns;
      formData.append("subject", "zoho");
      formData.append("email", commonData.email.value);
      formData.append("phone", commonData.mobile.value);
      formData.append("status", termIns.claimType);
      formData.append("claimStatus", termIns.claimType); //New
      formData.append("priority", "Very High");
      formData.append("claimFor", commonData.claimFor);
      formData.append("claimType", data.termIns.claimType);
      formData.append("policyDocumentsFile", termIns.policyDoc.docFile);

      formData.append("dateOfDeath", termIns.dateOfDeath.value);
      formData.append(
        "dateOfDocSubmission",
        termIns.dateOfSubmisionOfDoc.value
      );
      formData.append("expectUs", termIns.whatDoYouExpect.value);
      if (termIns.claimType === "Rejected") {
        formData.append("reasonForRejection", termIns.reasonForRejection.value);
      } else {
        formData.append("reasonForPending", termIns.reasonForPending.value);
      }
    }
    /// submit claim API
    submit_claim(formData);
  };

  const submit_claim = (dto: any) => {
    const onSuccess = (res: any) => {
      toast.success("Claim submitted successfully");
      setIsTermsAccepted(false);
      navigate("/");
      console.log("claim submit", res);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    HOME_SERVICES.Submit_claim(onSuccess, onError, dto);
  };

  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box className="claimsWrapper">
        <div className="complaint-parent">
          <div className="frame-group">
            <div className="frame-wrapper">
              <div className="contact-us-parent">
                <div className="contact-us">
                  <div className="contact-us1">
                    <h1>Free Claims Assistance</h1>
                  </div>
                </div>
                <Grid className="headerContent" md={2}>
                  <div className="top_heading">
                    We're here to swiftly assist with your claim, ensuring a
                    seamless process & speedy settlement. Count on our dedicated
                    team for top-notch support.
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <Box className="bottomSection">
          <Grid container>
            <Grid xs={12}>
              <div className="claim-parent">
                <Box>
                  <Grid className="gridContainer" container spacing={3}>
                    <Grid xs={12} className="mainHeading">
                      Personal Details
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Full Name"}
                        value={inputFields.Common.fullname.value}
                        attrName={["Common", "fullname"]}
                        value_update={fieldsUpdateState}
                        warn_status={inputFields.Common.fullname.warning}
                        error_message={"Enter Full Name"}
                        max_length={50}
                        validation_type="NAME"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Mobile"}
                        value={inputFields.Common.mobile.value}
                        attrName={["Common", "mobile"]}
                        value_update={fieldsUpdateState}
                        warn_status={inputFields.Common.mobile.warning}
                        error_message={
                          isEmpty(inputFields.Common.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number"
                        }
                        max_length={10}
                        validation_type="NUMBER"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Email"}
                        value={inputFields.Common.email.value}
                        attrName={["Common", "email"]}
                        value_update={fieldsUpdateState}
                        warn_status={inputFields.Common.email.warning}
                        error_message={" Enter Email"}
                      />
                    </Grid>
                  </Grid>

                  <Grid className="gridContainer" container spacing={3}>
                    <Grid xs={12} className="gridHeading">
                      What is this Claim for?
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} className="claimTab">
                      <TabContext value={inputFields.Common.claimFor}>
                        <Box>
                          <TabList
                            className="tabListButtons"
                            onChange={(
                              e,
                              a:
                                | "Car Insurance"
                                | "Health Insurance"
                                | "Two Wheeler Insurance"
                                | "Term Insurance"
                            ) => {
                              setInputFields({
                                ...inputFields,
                                Common: {
                                  ...inputFields.Common,
                                  claimFor: a,
                                },
                              });
                            }}
                          >
                            <Tab
                              className={
                                inputFields.Common.claimFor === "Car Insurance"
                                  ? "active"
                                  : "inActive"
                              }
                              label="Car Insurance"
                              value="Car Insurance"
                            />
                            <Tab
                              className={
                                inputFields.Common.claimFor ===
                                "Health Insurance"
                                  ? "active"
                                  : "inActive"
                              }
                              label="Health Insurance"
                              value="Health Insurance"
                            />
                            <Tab
                              className={
                                inputFields.Common.claimFor ===
                                "Two Wheeler Insurance"
                                  ? "active"
                                  : "inActive"
                              }
                              label="Two Wheeler Insurance"
                              value="Two Wheeler Insurance"
                            />
                            <Tab
                              className={
                                inputFields.Common.claimFor === "Term Insurance"
                                  ? "active"
                                  : "inActive"
                              }
                              label="Term Insurance"
                              value="Term Insurance"
                            />
                          </TabList>
                        </Box>
                        {/* Car Insurance */}
                        <TabPanel className="tabPanel" value="Car Insurance">
                          <CarClaim
                            inputFields={inputFields}
                            setInputFields={setInputFields}
                            fieldsUpdateState={fieldsUpdateState}
                            selectFile={selectFile}
                            fileChange={fileChange}
                            multiFileChange={multiFileChange}
                            removePhoto={removePhoto}
                            copyOfRCOwn={copyOfRCOwn}
                            copyOfDrivingLicenseOwn={copyOfDrivingLicenseOwn}
                            copyOfComplaintOwn={copyOfComplaintOwn}
                            copyOfPolicyDocOwn={copyOfPolicyDocOwn}
                            accidentPhotosOwn={accidentPhotosOwn}
                            copyOfRCTheft={copyOfRCTheft}
                            copyOfPolicyDocTheft={copyOfPolicyDocTheft}
                            copyOfComplaintTheft={copyOfComplaintTheft}
                            copyOfFinalReportTheft={copyOfFinalReportTheft}
                            copyOfRCThird={copyOfRCThird}
                            copyOfDrivingLicenseThird={
                              copyOfDrivingLicenseThird
                            }
                            copyOfComplaintThird={copyOfComplaintThird}
                            copyOfPolicyDocThird={copyOfPolicyDocThird}
                            accidentPhotosThird={accidentPhotosThird}
                            handleFileChange={handleFileChange}
                          />
                        </TabPanel>
                        {/* Health Insurance */}
                        <TabPanel
                          className="tabPanel healthTab"
                          value="Health Insurance"
                        >
                          <HealthClaim
                            inputFields={inputFields}
                            setInputFields={setInputFields}
                            fieldsUpdateState={fieldsUpdateState}
                            selectFile={selectFile}
                            fileChange={fileChange}
                            copyOfPolicyDocCash={copyOfPolicyDocCash}
                            copyOfHealthCardCash={copyOfHealthCardCash}
                            copyOfRejectionLetterCash={
                              copyOfRejectionLetterCash
                            }
                            copyOfCashReceiptCash={copyOfCashReceiptCash}
                            copyOfInvestigationReportCash={
                              copyOfInvestigationReportCash
                            }
                            copyOfPharmacyBillCash={copyOfPharmacyBillCash}
                            copyOfCaseSheetCash={copyOfCaseSheetCash}
                            copyOfPolicyDocRe={copyOfPolicyDocRe}
                            copyOfHealthCardRe={copyOfHealthCardRe}
                            copyOfRejectionLetterRe={copyOfRejectionLetterRe}
                            copyOfRejectionLetterPartially={
                              copyOfRejectionLetterPartially
                            }
                            copyOfCashReceiptRe={copyOfCashReceiptRe}
                            copyOfInvestigationReportRe={
                              copyOfInvestigationReportRe
                            }
                            copyOfPharmacyBillRe={copyOfPharmacyBillRe}
                            copyOfCaseSheetRe={copyOfCaseSheetRe}
                            copyOfPolicyDocClaim={copyOfPolicyDocClaim}
                            copyOfHealthCardClaim={copyOfHealthCardClaim}
                            copyOfCashReceiptClaim={copyOfCashReceiptClaim}
                            copyOfInvestigationReportClaim={
                              copyOfInvestigationReportClaim
                            }
                            copyOfPharmacyBillClaim={copyOfPharmacyBillClaim}
                            copyOfCaseSheetClaim={copyOfCaseSheetClaim}
                            handleFileChange={handleFileChange}
                          />
                        </TabPanel>
                        {/* Two Wheeler Insurance */}
                        <TabPanel
                          className="tabPanel"
                          value="Two Wheeler Insurance"
                        >
                          <TwoWheelerClaim
                            inputFields={inputFields}
                            setInputFields={setInputFields}
                            fieldsUpdateState={fieldsUpdateState}
                            selectFile={selectFile}
                            fileChange={fileChange}
                            multiFileChange={multiFileChange}
                            removePhoto={removePhoto}
                            TWcopyOfRCOwn={TWcopyOfRCOwn}
                            TWcopyOfDrivingLicenseOwn={
                              TWcopyOfDrivingLicenseOwn
                            }
                            TWcopyOfComplaintOwn={TWcopyOfComplaintOwn}
                            TWcopyOfPolicyDocOwn={TWcopyOfPolicyDocOwn}
                            TWaccidentPhotosOwn={TWaccidentPhotosOwn}
                            TWcopyOfRCTheft={TWcopyOfRCTheft}
                            TWcopyOfPolicyDocTheft={TWcopyOfPolicyDocTheft}
                            TWcopyOfComplaintTheft={TWcopyOfComplaintTheft}
                            TWcopyOfFinalReportTheft={TWcopyOfFinalReportTheft}
                            TWcopyOfRCThird={TWcopyOfRCThird}
                            TWcopyOfDrivingLicenseThird={
                              TWcopyOfDrivingLicenseThird
                            }
                            TWcopyOfComplaintThird={TWcopyOfComplaintThird}
                            TWcopyOfPolicyDocThird={TWcopyOfPolicyDocThird}
                            TWaccidentPhotosThird={TWaccidentPhotosThird}
                            handleFileChange={handleFileChange}
                          />
                        </TabPanel>
                        {/* Term Insurance */}
                        <TabPanel className="tabPanel" value="Term Insurance">
                          <TermClaim
                            inputFields={inputFields}
                            setInputFields={setInputFields}
                            fieldsUpdateState={fieldsUpdateState}
                            selectFile={selectFile}
                            fileChange={fileChange}
                            policyDoc={policyDoc}
                            handleFileChange={handleFileChange}
                          />
                        </TabPanel>
                        <Grid container columnSpacing={3}>
                          <Grid
                            xs={12}
                            marginBottom="20px"
                            marginTop="20px"
                            className="checkDeclaration"
                          >
                            <CustomCheckbox
                              label={`I declare that all the above details are true to the best of my knowledge. I hereby appoint Bimastreet (Amaze Insurance Brokers) to act as Claims Consultant under my cited claim and authorize them to represent me with the insurance company for the said Claim. I understand that Bimastreet is extending this Claims Consultancy Service FREE OF COST.`}
                              // value={true}
                              isChecked={isTermsAccepted}
                              value_update={() =>
                                setIsTermsAccepted((prev) => !prev)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} className="submit">
                          <Grid xs={12}>
                            <CustomButton
                              text_name="Submit Details"
                              class_name={`greenMdBtn submitButton`}
                              onClickFunction={validateForm}
                            />
                          </Grid>
                        </Grid>
                      </TabContext>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div className="support">
                <div>
                  <h4>
                    Customer Support on
                    <span className="green">(+91) 9391009482/84/89</span>
                  </h4>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </>
  );
};

export default Claims;
