import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../CommonSCSS/ModalPopup.scss";
import "./../MNavbar.scss";
import RKTextField from "../../../Common/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";

const MCustomerLogin = ({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  resendOtp: Function;
}) => {
  return (
    <Modal open={open} className="modalWrapperLogin">
      <Box className="modalContent">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12}>
            <Link
              href="javascript:void(0);"
              className="close-button"
              onClick={() => {
                setOpen(false);
                setShowEnterMobileSection(true);
                setLoginFields({
                  enterMobile: { value: "", warning: false },
                  enterOtp: { value: "", warning: false },
                });
              }}></Link>
          </Grid>
        </Grid>
        {showEnterMobileSection ? (
          <>
            <Grid container spacing={3}>
              <Grid xs={12} textAlign="center">
                <img src="./images/customer_login_icon1.svg" alt="" />
              </Grid>
              <Grid
                xs={12}
                textAlign="center"
                paddingLeft={"30px"}
                paddingRight={"40px"}>
                <h3 className="mb-2">
                  Customer <span>Login</span>
                </h3>
                <p>Hey, Enter your details to get sign in to your account</p>
              </Grid>
              <Grid xs={12} textAlign="center">
                <RKTextField
                  class_name="inputField"
                  title={"Enter Mobile Number"}
                  value={loginFields.enterMobile.value}
                  attrName={[
                    "enterMobile",
                    "value",
                    loginFields,
                    setLoginFields,
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={loginFields.enterMobile.warning}
                  error_message={
                    isEmpty(loginFields.enterMobile.value)
                      ? "Enter Mobile Number"
                      : "Enter Valid Mobile Number"
                  }
                  max_length={10}
                />
              </Grid>
              <Grid xs={12} textAlign="center">
                <Button
                  className="greenLgBtn"
                  onClick={() => {
                    handleShowMessage();
                  }}>
                  Send OTP
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid xs={12} textAlign="center">
                <img src="./images/customer_login_icon2.svg" alt="" />
              </Grid>
              <Grid
                xs={12}
                textAlign="center"
                paddingLeft={"30px"}
                paddingRight={"30px"}>
                <h3 className="mb-2">
                  One Time <span>Password</span>
                </h3>
                <p>
                  OTP sent successfully on
                  <br /> <span>+91-{loginFields.enterMobile.value}</span>
                  <Link
                    className="editBtn"
                    onClick={() => {
                      setShowEnterMobileSection(true);
                    }}>
                    Edit
                  </Link>
                </p>
              </Grid>
              <Grid xs={12} textAlign="center">
                <RKTextField
                  class_name="inputField"
                  title={"Enter OTP"}
                  value={loginFields.enterOtp.value}
                  attrName={["enterOtp", "value", loginFields, setLoginFields]}
                  value_update={fieldsUpdateState}
                  warn_status={loginFields.enterOtp.warning}
                  error_message={
                    isEmpty(loginFields.enterOtp.value)
                      ? "Enter OTP"
                      : "Enter Valid OTP"
                  }
                  max_length={4}
                />
              </Grid>
              <Grid xs={12} textAlign="center">
                <Button
                  className="greenLgBtn"
                  onClick={() => {
                    showMessage();
                  }}>
                  Verify OTP
                </Button>
              </Grid>
              <Grid xs={12} textAlign="center">
                <Button
                  className="greenLgBtn"
                  onClick={() => {
                    setLoginFields({
                      ...loginFields,
                      enterOtp: { value: "", warning: false },
                    });
                    resendOtp();
                  }}>
                  Resend OTP
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MCustomerLogin;
