let BIMASTREET, BIMASTREET_QUOTE;

if (process.env.REACT_APP_ENV === "DEV") {
  BIMASTREET = "dev.bimastreet.com";
  BIMASTREET_QUOTE = "dev.bimastreet.com";
} else if (process.env.REACT_APP_ENV === "STG") {
  BIMASTREET = "stg.bimastreet.com";
  BIMASTREET_QUOTE = "stg.bimastreet.com";
} else if (process.env.REACT_APP_ENV === "PROD") {
  BIMASTREET = "bimastreet.com";
  BIMASTREET_QUOTE = "bimastreet.com";
} else {
  // Default to dev environment
  BIMASTREET = "dev.bimastreet.com";
  BIMASTREET_QUOTE = "dev.bimastreet.com";
}

export const CLIENTS = {
  BIMASTREET: BIMASTREET,
  BIMASTREET_QUOTE: BIMASTREET_QUOTE,
};
