import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ.scss";

const FAQ = ({
  data,
}: {
  data: { expanded: string; title: string; desc: any }[];
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box className="faq">
      {data.map((data, index) => (
        <Accordion
          expanded={expanded === `${index}`}
          onChange={handleChange(`${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${index}` + "bh-content"}
            id={`${index}` + "bh-header"}
          >
            <Typography>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.desc}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
