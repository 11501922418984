import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import TextArea from "../../../../Component/Common/FieldTypes/TextArea/TextArea";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import "./MComplaint.scss";
import CustomButton from "../../../../Component/Common/CustomButton/CustomButton";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

const MNeedHelp = ({
  fieldsUpdateState,
  complaintFields,
  setcomplaintFields,
  validate_Form,
}: {
  fieldsUpdateState: Function;
  complaintFields: any;
  setcomplaintFields: any;
  validate_Form: Function;
}) => {
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      <MNavbar />
      <div className="complaint_wrapper">
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          <Grid xs={12} className="main_heading mb-6">
            <h1>
              Need Help?
              <span>
                We eagerly await your input! Please share your thoughts with us,
                as we value and cherish every perspective we receive.
              </span>
            </h1>
          </Grid>
          <Grid xs={12}>
            <div className="form_details">
              <Grid container spacing={3}>
                <Grid xs={12} className="px-0">
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    validation_type="NAME"
                    value={complaintFields.fullname.value}
                    attrName={[
                      "fullname",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.fullname.warning}
                    error_message={" Enter Full Name"}
                  />
                </Grid>
                <Grid xs={6} className="pl-0">
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={complaintFields.mobile.value}
                    attrName={[
                      "mobile",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.mobile.warning}
                    error_message={
                      isEmpty(complaintFields.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter valid mobile number"
                    }
                    max_length={10}
                  />
                </Grid>
                <Grid xs={6} className="pr-0">
                  <RKTextField
                    class_name="inputField"
                    title={"Email"}
                    value={complaintFields.email.value}
                    attrName={[
                      "email",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.email.warning}
                    error_message={" Enter email"}
                  />
                </Grid>
                <Grid xs={12} className="px-0">
                  <TextArea
                    class_name="inputField"
                    title={"What are you facing problems?"}
                    value={complaintFields.comments.value}
                    attrName={[
                      "comments",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.comments.warning}
                    error_message={" Enter email"}
                    placeholder="Please wirte here"
                  />
                </Grid>
                <Grid xs={12} className="px-0 text-center">
                  <div className="ctaBtn">
                    <CustomButton
                      class_name="greenLgBtn"
                      text_name="Submit Details"
                      onClickFunction={validate_Form}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid xs={10}>
            <div className="customer_support mb-6">
              <h5>
                Customer Support on <Link>(+91) 9676671888</Link>
              </h5>
              <p>10:00AM to 06:00PM</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <MFooter />
    </>
  );
};

export default MNeedHelp;
