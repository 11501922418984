import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../../Store/hooks";
import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";

function MKeyManagTool() {
  const { key_management_personal } = useAppSelector((state) => state.CMS);
  return (
    <Box>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"30px"}
      >
        {key_management_personal.data.data.map((data, index) => (
          <Grid xs={12} className="personnelbox bluebox">
            <Box textAlign={"center"} marginBottom={"24px"}>
              <img
                alt=""
                src={`${STRAPI_DOMAIN}${data.attributes.image.data.attributes.url}`}
              />
            </Box>
            <h4>{data.attributes.name}</h4>
            <h5>{data.attributes.designation}</h5>
            <ul className="points_list">
              {data.attributes.data.map((sub_data, sub_index) => (
                <li>
                  <img alt="" src="./images/list-tick-icon.svg" />
                  <p>{sub_data.desc}</p>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default MKeyManagTool;
