import React from "react";
import "./PageNotFound.scss";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../Navbar/Navbar";
import CustomButton from "../../../CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Box className="pageNotFoundWrapper">
      <Navbar />
      <Grid container spacing={3}>
        <Grid xs={12} textAlign="center">
          <Box className="imgSection">
            <div className="oppsImg">
              <img src="/images/oops.svg" />
            </div>
            <div className="eyes">
              <div className="eye sad"></div>
              <div className="eye sad"></div>
            </div>
            <img src="/images/404.svg" alt="" />
          </Box>
          <h3>PAGE NOT FOUND</h3>
          <p>
            The page you are looking for does not exist or an other occurred.
          </p>
        </Grid>
        <Grid xs={12} className="ctaBtn mb-4" textAlign="center">
          {/* <Button className="regularSecondaryBtn"
            onClick={() => {
              // navigate("/");
            }}
          >
            Back to Home
          </Button> */}
          <CustomButton
            onClickFunction={() => navigate("/")}
            text_name={"Back to Home"}
            class_name={"greenXlBtn"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageNotFound;
