import FreeClaimAssistance from "../../Pages/Desktop/CMS/FreeClaimAssistance/FreeClaimAssistance";
import MFreeClaimAssistance from "../../Pages/Mobile/CMS/MFreeClaimAssistance/MFreeClaimAssistance";
import useIsMobile from "../../SupportingFiles/MobileProvider";

function FreeClaimAssistanceConatiner() {
  const isMobile = useIsMobile();
  return isMobile ? <MFreeClaimAssistance /> : <FreeClaimAssistance />;
}

export default FreeClaimAssistanceConatiner;
