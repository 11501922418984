import { createSlice } from "@reduxjs/toolkit";
import { THomePage } from "../../../Type/Home/THomePage";
import { HOME_PAGE_REDUCERS, HomePageReducers } from "./HomeReducer";

const initialState: THomePage = {
  header: {
    top_heading: "",
    heading: "",
    desc: "",
  },
  how_it_works: {
    section_name: "",
    section_index: 0,
    id: 0,
    title: "",
    desc: "",
    visible: true,
    data: { data: [] },
  },
  top_rated: {
    section_name: "",
    section_index: 0,
    id: 0,
    title: "",
    desc: "",
    visible: true,
    data: { data: [] },
  },
  our_clients: {
    section_name: "",
    section_index: 0,
    id: 0,
    title: "",
    desc: "",
    visible: true,
    data: { data: [] },
  },
  awards: {
    section_name: "",
    section_index: 0,
    id: 0,
    title: "",
    desc: "",
    visible: true,
    data: { data: [] },
  },
  our_products: {
    section_name: "",
    section_index: 0,
    id: 0,
    title: "",
    desc: "",
    sub_desc: "",
    visible: true,
    data: {
      data: [
        {
          id: 0,
          attributes: {
            createdAt: "",
            image: { data: { id: 0, attributes: { url: "" } } },
            title: "Health",
            type: "health",
            updatedAt: "",
          },
        },
        {
          id: 0,
          attributes: {
            createdAt: "",
            image: { data: { id: 0, attributes: { url: "" } } },
            title: "Car",
            type: "car",
            updatedAt: "",
          },
        },
        {
          id: 0,
          attributes: {
            createdAt: "",
            image: { data: { id: 0, attributes: { url: "" } } },
            title: "Two Wheeler",
            type: "tw",
            updatedAt: "",
          },
        },
        {
          id: 0,
          attributes: {
            createdAt: "",
            image: { data: { id: 0, attributes: { url: "" } } },
            title: "Term",
            type: "term",
            updatedAt: "",
          },
        },
        {
          id: 0,
          attributes: {
            createdAt: "",
            image: { data: { id: 0, attributes: { url: "" } } },
            title: "Travel",
            type: "travel",
            updatedAt: "",
          },
        },
      ],
    },
  },
  testimonials: {
    section_name: "",
    section_index: 0,
    id: 0,
    visible: true,
    title: "",
    desc: "",
    visibility: true,
    data: { data: [] },
  },
  our_partners: {
    section_name: "",
    section_index: 0,
    id: 0,
    visible: true,
    title: "",
    data: { data: [] },
  },
  footer: {
    toll_free_number: "",
    id: 0,
    section_index: 0,
    support_email: "",
    claim_email: "",
    for_other_query: "",
    phone_number: "",
    address: "",
    irda_license_number: "",
    isnp_ref: "",
    license_on: "",
    valid_upto: "",
    disclaimer: "",
    company_name: "",
    product_name: "",
  },
  PAGE_STATUS: "",
};

export const HomeSlice = createSlice({
  name: "Home",
  initialState: initialState,
  reducers: { ...HOME_PAGE_REDUCERS },
});
