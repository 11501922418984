import ArchitectureIcon from "@mui/icons-material/Architecture";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import { useAppSelector } from "../../../../Store/hooks";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

export default function MFreeClaimAssistance() {
  const isMobile = useIsMobile();
  const { privacy_policy } = useAppSelector((state) => state.CMS);
  const { footer } = useAppSelector((state) => state.Home);
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box>
        <Grid container spacing={3}>
          <Grid
            xs={12}
            textAlign="center"
            marginTop="200px"
            marginBottom="100px"
          >
            <h1>Free Claim Assistance</h1>
            <ArchitectureIcon
              sx={{
                height: "100px",
                width: "100px",
                marginTop: "50px",
                color: "#00785A",
              }}
            />
            <h2>Work in progress....</h2>
            <p>Come back and check it out.</p>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </>
  );
}
