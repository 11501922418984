import About from "../../Pages/Desktop/CMS/About/About";
import MAbout from "../../Pages/Mobile/CMS/MAbout/MAbout";
import useIsMobile from "../../SupportingFiles/MobileProvider";

function AboutConatiner() {
  const isMobile = useIsMobile();
  return isMobile ? <MAbout /> : <About />;
}

export default AboutConatiner;
