import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../CommonSCSS/ProductPages.scss";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../Component/Common/FieldTypes/SearchDropdown/SearchDropdown";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import FAQ from "../../../../Component/Desktop/ProductPages/FAQ/FAQ";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import { CAR_ADD_FORM_SERVICES } from "../../../../Services/CAR/CarAddFormServices";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { CarSlice } from "../../../../Store/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validateRegNoBharat,
  validateRegno,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { ADD_CAR_DTO } from "../../../../Type/Car/form/AddCarDTO";
import { TDropdown } from "../../../../Type/Common/TDropdown";
import { TCarAddForm, TCarAddFormResponse } from "../../../../Type/TCarSlice";
import { CLIENTS } from "../../../../URLCollection/Clients";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";

function CarInsurance() {
  const { ADD_FORM, DROPDOWN_DATA } = useAppSelector((state) => state.Car);
  const [formData, setFormData] = useState<TCarAddForm>(ADD_FORM);
  const isMobile = useIsMobile();
  const location = useLocation();
  const [pageStatus, setPageStatus] = useState<number>(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const CAR_INSURANCE = useAppSelector(
    (state) => state.PRODUCT_PAGES.CAR_INSURANCE.attributes.data
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (pageStatus === 3) {
      GET_MAKE_MODEL_LIST();
      GET_PREV_INSURER_LIST();
      GET_MAKE_LIST();
    } else if (pageStatus === 2 || pageStatus === 1) {
      GET_RTO_LIST();
    }
  }, [pageStatus]);

  //fuel type api call
  // useEffect(() => {
  //   if (formData.make_model.value !== "") {
  //     GET_FUEL_TYPE_LIST();
  //   }
  // }, [formData.make_model.value]);

  useEffect(() => {
    if (!isEmpty(formData.make.value) && !isEmpty(formData.model.value)) {
      GET_FUEL_TYPE_LIST();
    } else {
      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "FUEL_TYPE_LIST",
          value: [],
        })
      );
    }
  }, [formData.make.value, formData.model.value]);

  // variant api call
  useEffect(() => {
    if (formData.fuel_type.value !== "") {
      GET_VARIANT_LIST();
    }
  }, [formData.fuel_type.value]);

  // form field update function
  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "make") {
      setFormData((prev) => ({
        ...prev,
        make: { value: value, warning: false },
        fuel_type: { ...prev.fuel_type, value: "" },
        variant_cc: { ...prev.variant_cc, value: "" },
      }));
    } else if (attrName === "model") {
      setFormData((prev) => ({
        ...prev,
        model: { value: value, warning: false },
        fuel_type: { ...prev.fuel_type, value: "" },
        variant_cc: { ...prev.variant_cc, value: "" },
      }));
    } else if (attrName === "fuel_type") {
      setFormData((prev) => ({
        ...prev,
        fuel_type: { value: value, warning: false },
        variant_cc: { ...prev.variant_cc, value: "" },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "reg_no"
              ? !validateRegno(value)
              : attrName === "mobile"
              ? !validateMobileNumber(value)
              : false,
        },
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(formData?.make?.value)) {
      GET_MODEL_LIST();
    } else {
      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "MODEL_LIST",
          value: [],
        })
      );
    }
  }, [formData?.make?.value]);

  const GET_MAKE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make === "string") {
            data.push({ label: `${value.make}`, value: `${value.make}` });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MAKE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);

      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make_model?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make_model === "string") {
            data.push({
              label: `${value.make_model}`,
              value: `${value.make_model}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MODEL_LIST(
      onError,
      onSuccess,
      `${formData.make.value}`
    );
  };
  // get vehicle info after the reg_no and reg_date
  const GET_VEHICLE_INFO = () => {
    setPageStatus(3);
    const onSuccess = (res: any) => {
      const results = res?.data;
      const response = results?.response;
      const error = results?.error;

      const make = response?.make;
      const model = response?.model;
      const make_model = `${make} ${model}`;
      const variant_cc = `${response?.variant}, ${response?.cubic_capacity} CC`;
      const fuel_type = response?.fuel_type;
      const registration_date = response?.registration_date;

      if (
        !isEmpty(make) &&
        !isEmpty(make_model) &&
        !isEmpty(variant_cc) &&
        !isEmpty(fuel_type)
      ) {
        const data: TCarAddForm = {
          ...formData,
          make: { value: make, warning: false },
          make_model: { value: make_model, warning: false },
          variant_cc: { value: variant_cc, warning: false },
          fuel_type: { value: fuel_type, warning: false },
          reg_date: {
            value: FORMAT_DATE_DD_MM_YYYY(registration_date),
            warning: false,
          },
        };

        setFormData(data);
      }
    };
    const onError = () => {};

    CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onError,
      onSuccess,
      formData.reg_no.value
    );
  };
  /// get previuos isnurer list
  // const GET_PREV_INSURER_LIST = () => {
  //   const onSuccess = (res: any) => {
  //     const response = res.data.results;
  //     const error = response.error;

  //     if (error === false) {
  //       let data: TDropdown[] = [];
  //       const datas = response.response;

  //       Object.entries(datas).map(([brand, value]) =>
  //         data.push({ label: `${value}`, value: `${value}` })
  //       );

  //       dispatch(
  //         CarSlice.actions.UPDATE_DROPDOWN_MASTER({
  //           key: "PREVIOUS_INSURER_LIST",
  //           value: data,
  //         })
  //       );
  //     }
  //   };

  //   const onError = (res: any) => {
  //     console.log("Error");
  //   };
  //   CAR_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  // };

  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          short_name?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.short_name === "string") {
            data.push({
              label: `${value.short_name}`,
              value: `${value.short_name}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  /// get rto list
  // const GET_RTO_LIST = () => {
  //   const onSuccess = (res: any) => {
  //     const response = res.data.results;
  //     const error = response.error;

  //     if (error === false) {
  //       let data: TDropdown[] = [];
  //       const datas = response.response;

  //       Object.entries(datas).map(([brand, value]) =>
  //         data.push({ label: `${brand} - ${value}`, value: brand })
  //       );

  //       dispatch(
  //         CarSlice.actions.UPDATE_DROPDOWN_MASTER({
  //           key: "RTO_LIST",
  //           value: data,
  //         })
  //       );
  //     }
  //   };

  //   const onError = (res: any) => {
  //     console.log("Error");
  //   };
  //   CAR_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  // };

  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        const data: Array<{ label: string; value: string }> = [];

        Object.entries(response).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };

  /// get make and model list
  const GET_MAKE_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: brand, value: brand })
        );

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MAKE_MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  };

  console.log("formData", formData);

  // get fuel list
  // const GET_FUEL_TYPE_LIST = () => {
  //   const onSuccess = (res: any) => {
  //     console.log("hsdb", res);

  //     const response = res.results;
  //     const error = response.error;

  //     if (error === false) {
  //       let data: TDropdown[] = [];
  //       const datas = response.response;

  //       Object.entries(datas).map(([brand, value]) =>
  //         data.push({ label: brand, value: brand })
  //       );

  //       dispatch(
  //         CarSlice.actions.UPDATE_DROPDOWN_MASTER({
  //           key: "FUEL_TYPE_LIST",
  //           value: data,
  //         })
  //       );
  //     }
  //   };

  //   const onError = (res: any) => {
  //     console.log("Error", res);
  //   };
  //   CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
  //     onSuccess,
  //     onError,
  //     `${formData.make_model.value}`
  //   );
  // };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          fuel_type?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.fuel_type === "string") {
            data.push({
              label: `${value.fuel_type}`,
              value: `${value.fuel_type}`,
            });
          }
        });

        // dispatch(
        //   CarSlice.actions.SET_DROPDOWN_DATA({
        //     key: "FUEL_TYPE_LIST",
        //     value: data,
        //   })
        // );
        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onError,
      onSuccess,
      `${formData.make.value} ${formData.model.value}`
    );
  };

  const POST_CAR_FORM = () => {
    const onSuccess = (res: any) => {
      console.log("resD", res);

      const results = res;
      const error = res.error;
      // setLoader(false);
      if (error === false) {
        const response: TCarAddFormResponse = results.response;
        dispatch(
          CarSlice.actions.UPDATE_FORM_DATA([
            {
              key: "business_type",
              value: formData.business_type,
            },
            {
              key: "rto",
              value: { value: formData.rto.value, warning: false },
            },
            {
              key: "make_model",
              value: { value: formData.make.value, warning: false },
            },
            {
              key: "fuel_type",
              value: { value: formData.fuel_type.value, warning: false },
            },
            {
              key: "variant_cc",
              value: { value: formData.variant_cc.value, warning: false },
            },
            {
              key: "reg_date",
              value: { value: formData.reg_date.value, warning: false },
            },
            {
              key: "policy_expiry_date",
              value: {
                value: formData.policy_expiry_date.value,
                warning: false,
              },
            },
            {
              key: "previous_insurer",
              value: { value: formData.previous_insurer.value, warning: false },
            },
            {
              key: "name",
              value: { value: formData.name.value, warning: false },
            },
            {
              key: "mobile",
              value: { value: formData.mobile.value, warning: false },
            },
            {
              key: "reg_no",
              value: { value: formData.reg_no.value, warning: false },
            },
          ])
        );
        dispatch(CarSlice.actions.ADD_FORM_RESPONSE(response));

        window.location.href = `https://${CLIENTS.BIMASTREET_QUOTE}${COMMON_ROUTES.CAR_QUOTE}?type=CAR&quote_no=${response}`;
      }
    };

    const onError = (err: any) => {
      // setLoader(false);
      console.log(err);
    };
    dispatch(
      UserProfileSlice.actions.setAccountUserDataMultipleKey([
        { key: "name", value: formData.name.value },
        { key: "mobile", value: formData.mobile.value },
      ])
    );

    const data: ADD_CAR_DTO = {
      carBusinessType: formData.business_type,
      carClaimMade: formData.claimed,
      carFuelType: formData.fuel_type.value,
      carMakeModel: `${formData.make.value} ${formData.model.value}`,
      carMobile: formData.mobile.value,
      carPExpiryDate:
        formData.business_type === "Rollover"
          ? FORMAT_YYYY_MM_DD(formData.policy_expiry_date.value)
          : "",
      carPreviousInsurer: formData.previous_insurer.value,
      carPreviousNCB: formData.prev_ncb,
      carRegDate:
        formData.business_type === "Rollover"
          ? FORMAT_YYYY_MM_DD(`${formData.reg_date.value}`)
          : "",
      carRegNo: formData.reg_no.value,
      carRtoCode: formData.rto.value,
      carVariantCC: formData.variant_cc.value,
    };
    // setLoader(true);
    CAR_ADD_FORM_SERVICES.GENERATE_QUOTE_NO(onSuccess, onError, data);
  };

  // get variant list
  // const GET_VARIANT_LIST = () => {
  //   const onSuccess = (res: any) => {
  //     const response = res.results;
  //     const error = response.error;

  //     if (error === false) {
  //       let data: TDropdown[] = [];
  //       const datas = response.response;

  //       Object.entries(datas).map(([brand, value]) =>
  //         data.push({ label: brand, value: brand })
  //       );

  //       dispatch(
  //         CarSlice.actions.UPDATE_DROPDOWN_MASTER({
  //           key: "VARIANT_LIST",
  //           value: data,
  //         })
  //       );
  //     }
  //   };

  //   const onError = (res: any) => {
  //     console.log("Error");
  //   };
  //   CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
  //     onSuccess,
  //     onError,
  //     `${formData.make_model.value}`,
  //     `${formData.fuel_type.value}`
  //   );
  // };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          variant_cc?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.variant_cc === "string") {
            data.push({
              label: `${value.variant_cc}`,
              value: `${value.variant_cc}`,
            });
          }
        });

        // dispatch(
        //   CarSlice.actions.SET_DROPDOWN_DATA({
        //     key: "VARIANT_LIST",
        //     value: data,
        //   })
        // );

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onError,
      onSuccess,
      `${formData.make.value} ${formData.model.value}`,
      `${formData.fuel_type.value}`
    );
  };

  // validate the form
  const validateForm = () => {
    // debugger;
    let data = { ...formData };
    switch (pageStatus) {
      case 1:
        data = {
          ...data,
          reg_no: {
            ...data.reg_no,
            warning: !validateRegno(`${data.reg_no.value}`),
          },
          business_type: "Rollover",
        };

        if (validateRegNoBharat(data.reg_no.value)) {
          data = {
            ...data,
            rto: {
              ...data.rto,
              warning: isEmpty(`${data.rto.value}`),
            },
          };
        }

        setFormData({ ...data });
        if (!data.reg_no.warning) {
          GET_VEHICLE_INFO();
        }
        break;
      case 2:
        data.rto = {
          ...data.rto,
          warning: isEmpty(`${data.rto.value}`),
        };
        setFormData({ ...data });
        if (!data.rto.warning) {
          setPageStatus(3);
        }

        break;
      case 3:
        data = {
          ...data,
          // make_model: {
          //   ...data.make_model,
          //   warning: isEmpty(data.make_model.value),
          // },
          make: {
            ...data.make,
            warning: isEmpty(data.make.value),
          },
          model: {
            ...data.model,
            warning: isEmpty(data.model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
        };
        if (data.business_type === "Rollover") {
          data = {
            ...data,
            reg_date: {
              ...data.reg_date,
              warning: isEmpty(data.reg_date.value),
            },
            policy_expiry_date: {
              ...data.policy_expiry_date,
              warning: isEmpty(data.policy_expiry_date.value),
            },
            previous_insurer: {
              ...data.previous_insurer,
              warning: isEmpty(data.previous_insurer.value),
            },
          };
        }
        setFormData({ ...data });
        if (data.business_type === "Rollover") {
          if (
            !data.make.warning &&
            !data.model.warning &&
            !data.fuel_type.warning &&
            !data.variant_cc.warning &&
            !data.reg_date.warning &&
            !data.policy_expiry_date.warning &&
            !data.previous_insurer.warning
          ) {
            setPageStatus(4);
          }
        } else {
          if (
            !data.make.warning &&
            !data.model.warning &&
            !data.fuel_type.warning &&
            !data.variant_cc.warning
          ) {
            setPageStatus(4);
          }
        }

        break;
      case 4:
        data = {
          ...data,
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
          name: {
            ...data.name,
            warning: isEmpty(data.name.value),
          },
        };

        setFormData({ ...data });
        if (!data.name.warning && !data.mobile.warning) {
          POST_CAR_FORM();
        }
        break;
      default:
        break;
    }
  };

  // back button naviagte button
  const backnavigate = () => {
    let dataBack = pageStatus;
    if (dataBack === 3 && formData.rto.value !== "") {
      setPageStatus(2);
    } else {
      setPageStatus((prev) => (prev - 1 === 2 ? 1 : prev - 1));
    }
  };

  useEffect(() => {
    if (pageStatus === 1) {
      setFormData(CarSlice.getInitialState().ADD_FORM);
    }
  }, [pageStatus]);

  return (
    <>
      <Helmet>
        <title>
          Best Car Insurance Online | Car Insurance Policy Online | Affordable
          Car Insurance Companies
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Explore the most affordable car insurance quotes and find the best rates today. Compare policies on the best car insurance comparison sites for the lowest car insurance policy prices."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Car Insurance Online, Best Car Insurance Companies, Car Insurance Policy Online, 4 Wheeler Insurance, Best Car Insurance, Best Car Insurance Companies	"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bimastreet" />
        <meta
          property="og:title"
          content="Best Car Insurance Online | Car Insurance Policy Online | Affordable
          Car Insurance Companies"
        />
        <meta
          property="og:description"
          content="Explore the most affordable car insurance quotes and find the best rates today. Compare policies on the best car insurance comparison sites for the lowest car insurance policy prices."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FRONTEND_DOMAIN}${location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://www.bimastreet.com/images/car_product_icon.svg"
        />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
        <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} />
      </Helmet>

      {isMobile ? <MNavbar /> : <Navbar />}
      <Box className="productPagesWrapper">
        {/* Title */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img alt="" src="./images/car_product_icon.svg" height="67px" />
              {CAR_INSURANCE.title}
            </h1>
            <p style={{ opacity: "0.5" }}>{CAR_INSURANCE.desc}</p>
          </Grid>
        </Grid>

        {/* Header / From */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing columnReverse">
          <Grid lg={6} xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: CAR_INSURANCE?.primary_info?.title,
              }}></h2>
            <p style={{ marginBottom: "24px", marginTop: "24px" }}>
              {CAR_INSURANCE?.primary_info?.desc}
            </p>
            <ul>
              <li>
                <h5 className="tickTitle">Third-Party Car Insurance</h5>
              </li>
              <li>
                <h5 className="tickTitle">Comprehensive Car Insurance</h5>
              </li>
              <li>
                <h5 className="tickTitle">Standalone Own Damage</h5>
              </li>
            </ul>
          </Grid>
          <Grid lg={6} xs={12}>
            <Box className="formSection carbg">
              {/* Step 1 */}
              {pageStatus === 1 ? (
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={0}
                  justifyContent="center">
                  <Grid xs={12} textAlign="center" marginBottom="38px">
                    <h1>
                      Find the <span className="greenText">Best</span>{" "}
                      <span>Car Insurance Plan</span>
                    </h1>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ maxWidth: "244px", marginBottom: "24px" }}>
                    <RKTextField
                      class_name="inputField vehicleNumberField"
                      title={""}
                      value={formData.reg_no.value}
                      attrName={"reg_no"}
                      value_update={updateMasterState}
                      warn_status={formData.reg_no.warning}
                      error_message={
                        isEmpty(formData.reg_no.value)
                          ? "Enter vehicle no."
                          : "Enter valid vehicle no."
                      }
                      placeholder={"DL01ABC1234"}
                      validation_type="ALPHANUMERIC"
                      max_length={11}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {/* To go back */}
              {pageStatus !== 1 ? (
                <Grid
                  container
                  columnSpacing={1}
                  rowSpacing={3}
                  marginBottom="12px">
                  <Grid
                    xs={12}
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    paddingTop="0">
                    <Link className="backStep" onClick={backnavigate}>
                      <ArrowBackIosNewIcon />
                    </Link>
                    <h3
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginLeft: "-32px",
                      }}>
                      {pageStatus === 1 || pageStatus === 3 ? (
                        <>
                          Vehicle <span>Details</span>
                        </>
                      ) : pageStatus === 2 ? (
                        <>
                          Select Your <span>RTO</span>
                        </>
                      ) : pageStatus === 4 ? (
                        <>
                          Policy <span>Details</span>
                        </>
                      ) : null}
                    </h3>
                  </Grid>
                </Grid>
              ) : null}
              {/* Step 2 */}
              {pageStatus === 2 ? (
                <Grid
                  xs={12}
                  sm={8}
                  md={10}
                  marginLeft="auto"
                  marginRight="auto">
                  <SearchDropdown
                    class_name="inputField"
                    title="RTO/City Name"
                    value={formData.rto.value}
                    attrName={"rto"}
                    value_update={updateMasterState}
                    data={DROPDOWN_DATA.RTO_LIST}
                    warn_status={formData.rto.warning}
                    error_message={"Select RTO/City Name"}
                  />
                </Grid>
              ) : null}
              {/* Step 3 */}
              {pageStatus === 3 ? (
                <Grid
                  container
                  columnSpacing={{ xs: 2, md: 3 }}
                  rowSpacing={3}
                  justifyContent="center">
                  {/* <Grid xs={12}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Make & Model"
                      value={formData.make_model.value}
                      attrName={"make_model"}
                      value_update={updateMasterState}
                      data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                      warn_status={formData.make_model.warning}
                      error_message={"Select Make & Model"}
                    />
                  </Grid> */}
                  <Grid xs={6}>
                    <SearchDropdown
                      class_name="inputField"
                      title={"Manufacturer"}
                      attrName={"make"}
                      value={formData.make.value}
                      value_update={updateMasterState}
                      warn_status={formData.make.warning}
                      error_message={"Select Make"}
                      data={DROPDOWN_DATA.MAKE_LIST}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SearchDropdown
                      class_name="inputField"
                      title={"Model"}
                      attrName={"model"}
                      value={formData.model.value}
                      value_update={updateMasterState}
                      warn_status={formData.model.warning}
                      error_message={"Select Model"}
                      data={DROPDOWN_DATA.MODEL_LIST}
                    />
                  </Grid>
                  <Grid xs={12} sm={5}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Fuel Type"
                      value={formData.fuel_type.value}
                      attrName={"fuel_type"}
                      value_update={updateMasterState}
                      data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                      warn_status={formData.fuel_type.warning}
                      error_message={"Select Fuel Type"}
                    />
                  </Grid>
                  <Grid xs={12} sm={7}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Variant"
                      value={formData.variant_cc.value}
                      attrName={"variant_cc"}
                      value_update={updateMasterState}
                      data={DROPDOWN_DATA.VARIANT_LIST}
                      warn_status={formData.variant_cc.warning}
                      error_message={"Select variant"}
                    />
                  </Grid>
                  {formData.business_type === "Rollover" ? (
                    <>
                      <Grid xs={12} sm={6} lg={4}>
                        <DatePicker
                          class_name="inputField datepicker"
                          title={"Reg. Date"}
                          value={formData.reg_date.value}
                          attrName={"reg_date"}
                          value_update={updateMasterState}
                          warn_status={formData.reg_date.warning}
                          error_message={"Select Reg. Date"}
                          min_date={15 * 12}
                          max_date={3}
                          date_validation_type="MONTHS"
                        />
                      </Grid>
                      <Grid xs={12} sm={6} lg={4}>
                        <DatePicker
                          class_name="inputField datepicker"
                          title={"Policy Expiry"}
                          value={formData.policy_expiry_date.value}
                          attrName={"policy_expiry_date"}
                          value_update={updateMasterState}
                          warn_status={formData.policy_expiry_date.warning}
                          error_message={"Select Policy Expiry Date"}
                          min_date={
                            calculateAgeInDays(`${ADD_FORM.reg_date.value}`) -
                            89
                          }
                          max_date={-89}
                          date_validation_type="DAYS"
                        />
                      </Grid>
                      <Grid xs={12} sm={6} lg={4}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Previous Insurer"
                          value={formData.previous_insurer.value}
                          attrName={"previous_insurer"}
                          value_update={updateMasterState}
                          data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                          warn_status={formData.previous_insurer.warning}
                          error_message={"Select Previous Insurer"}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              ) : null}

              {/* Step 4 */}
              {pageStatus === 4 ? (
                <>
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={0}
                    justifyContent="center"
                    marginBottom="12px">
                    {formData.business_type === "Rollover" ? (
                      <Grid
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap="12px"
                        sx={{
                          "@media (max-width:420px)": {
                            flexDirection: "column",
                          },
                        }}>
                        <h3 className="claimed">Claimed in the last year?</h3>
                        <ul
                          className="radioBoxList"
                          style={{
                            justifyContent: "left",
                          }}>
                          <li
                            className={
                              formData.claimed === "Yes" ? "active" : ""
                            }
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                claimed: "Yes",
                              }))
                            }>
                            Yes
                          </li>
                          <li
                            className={
                              formData.claimed === "No" ? "active" : ""
                            }
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                claimed: "No",
                              }))
                            }>
                            No
                          </li>
                        </ul>
                      </Grid>
                    ) : null}
                    {formData.claimed == "No" &&
                    formData.business_type === "Rollover" ? (
                      <Grid xs={12} textAlign="center">
                        <h6 className="claimed my-4">
                          Previous No Claim Bonus (NCB)
                          <span>(Check it in your last policy.)</span>
                        </h6>
                        <ul
                          className="radioBoxList"
                          style={{ marginBottom: "16px" }}>
                          {DROPDOWN_DATA.NCB.map((data: any, index) => (
                            <li
                              onClick={() => {
                                setFormData((prev) => ({
                                  ...prev,
                                  prev_ncb: data,
                                }));
                              }}
                              className={
                                data === formData.prev_ncb ? "active" : ""
                              }>
                              {data}%
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid
                    container
                    columnSpacing={{ xs: 2, md: 3 }}
                    rowSpacing={{ xs: 3, md: 0 }}>
                    <Grid xs={12} sm={6}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="NAME"
                        title={"Full Name"}
                        value={formData.name.value}
                        attrName={"name"}
                        value_update={updateMasterState}
                        warn_status={formData.name.warning}
                        error_message={"Enter Full Name"}
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="NUMBER"
                        title={"Mobile"}
                        value={formData.mobile.value}
                        attrName={"mobile"}
                        value_update={updateMasterState}
                        warn_status={formData.mobile.warning}
                        max_length={10}
                        error_message={
                          isEmpty(formData.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter valid mobile number"
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}

              {/* Step 4 OTP Section */}
              {/* <Grid
                container
                columnSpacing={3}
                rowSpacing={0}
                justifyContent="center"
                marginBottom="12px"
              >
                <Grid xs={12} textAlign="center">
                  <h3 className="claimed">OTP has sent successfully on</h3>
                  <h5
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "4px",
                      marginBottom: "16px",
                    }}
                  >
                    +91-987654321
                  </h5>
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    validation_type="NUMBER"
                    title={"Enter OTP"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={"Enter Mobile"}
                    max_length={10}
                  />
                </Grid>
                <Grid xs={12} className="ctaBtn mt-6" textAlign="center">
                  <CustomButton
                    class_name="greenLgBtn"
                    text_name="Verify OTP"
                  />
                </Grid>
              </Grid> */}

              {/* Common Continue Btn */}
              <Grid
                container
                columnSpacing={3}
                rowSpacing={3}
                justifyContent="center">
                <Grid xs={12} className="ctaBtn mt-4" textAlign="center">
                  <CustomButton
                    class_name="greenXlBtn"
                    text_name={pageStatus < 4 ? "continue" : "View Quotes"}
                    onClickFunction={validateForm}
                  />
                </Grid>
                {pageStatus === 1 ? (
                  <Grid xs={12} textAlign="center">
                    <p className="newAndRenew">
                      Click here for{" "}
                      <Link
                        onClick={() => {
                          setFormData({ ...formData, business_type: "New" });
                          setPageStatus(2);
                        }}>
                        BRAND NEW CAR INSURANCE
                      </Link>
                    </p>
                  </Grid>
                ) : null}
                {pageStatus === 4 ? (
                  <Grid
                    xs={12}
                    className="disabledCheckbox"
                    textAlign={"center"}>
                    <CustomCheckbox
                      label={
                        <>
                          By clicking on view quotes you agree to all{" "}
                          <a>Terms & Conditions</a>.
                        </>
                      }
                      value={true}
                      defaultChecked
                      disabled={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px">
              <Grid container columnSpacing={3} rowSpacing={0}>
                {/* <Grid lg={6} md={12}>
                  <h4 style={{ marginBottom: "18px" }}>
                    Features of <span className="underline">Thrid-Party</span>{" "}
                    Car Insurance
                  </h4>
                  <ol>
                    <li>
                      Covers third-party liability for bodily injury or death.
                    </li>
                    <li>Covers third-party liability for property damage.</li>
                    <li>Affordable premium as it offers limited coverage.</li>
                  </ol>
                  <h4 style={{ marginBottom: "18px", marginTop: "18px" }}>
                    Features of <span className="underline">Comprehensive</span>{" "}
                    Car Insurance
                  </h4>
                  <ol>
                    <li>
                      Covers third-party liability for bodily injury or death.
                    </li>
                    <li>Covers third-party liability for property damage.</li>
                    <li>
                      Offers coverage for damage to your own vehicle due to
                      accidents, theft, fire, natural disasters, vandalism etc.
                    </li>
                    <li>
                      May include personal accident cover for the owner/driver.
                    </li>
                    <li>
                      Provides the option to add various add-ons or riders for
                      enhanced coverage.
                    </li>
                  </ol>
                </Grid> */}
                <Grid lg={12} md={12}>
                  <h2
                    style={{ marginBottom: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: CAR_INSURANCE.gradient_box_title,
                    }}></h2>
                  {CAR_INSURANCE.gradient_data.map((data, index) => (
                    <Box className="boderWhitegradientBox">
                      <h5 className="tickTitle">{data.title}</h5>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px">
              <Box className="inner">
                <img src="./images/shield-icon.svg" width="58px" />
                <div style={{ flex: "1 0 0", minWidth: "300px" }}>
                  <h4>{CAR_INSURANCE?.buy_quote?.title}</h4>
                  <p>{CAR_INSURANCE?.buy_quote?.desc}</p>
                </div>
                <div className="ctaBtn">
                  <Buttons
                    onClickFunction={scrollToTop}
                    class_name="greenMdBtn"
                    text_name="Buy Now"
                  />
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Addon Covers */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h2
              dangerouslySetInnerHTML={{
                __html: CAR_INSURANCE.number_box_heading?.title,
              }}
            />
            <p style={{ marginBottom: "40px", marginTop: "24px" }}>
              {CAR_INSURANCE.number_box_heading?.desc}
            </p>
            <ul className="numberBox">
              {CAR_INSURANCE.number_box_data.map((data, index) => (
                <li>
                  <div>
                    <p>{index + 1}</p>
                  </div>
                  <h4>{data.title}</h4>
                  <p>{data.desc}</p>
                </li>
              ))}
            </ul>
            <p style={{ marginTop: "16px" }}>{CAR_INSURANCE.number_box_note}</p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12}>
            <h2>{CAR_INSURANCE.list_cont_heading?.title}</h2>
            <p style={{ marginBottom: "40px", marginTop: "24px" }}>
              {CAR_INSURANCE.list_cont_heading?.desc}
            </p>
            <ul className="numberList">
              {CAR_INSURANCE?.list_cont_data?.map((data, index) => (
                <li>
                  <span>{index + 1}</span>
                  <div>
                    <h4>{data.title}</h4>
                    <p>{data.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              display="flex"
              padding="20px 30px"
              justifyContent="center"
              alignItems="center"
              gap="24px"
              alignSelf="stretch">
              <img src="./images/policy-ponts.svg" width="141px" />
              <p>{CAR_INSURANCE.product_info}</p>
            </Box>
          </Grid>
        </Grid>

        {/* FAQ */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h2 style={{ marginBottom: "18px" }}>
              {CAR_INSURANCE?.faq_heading?.title}
            </h2>
            <p style={{ marginBottom: "18px" }}>
              {CAR_INSURANCE?.faq_heading?.desc}
            </p>
          </Grid>
          <Grid xs={12}>
            <FAQ data={CAR_INSURANCE.faq_data} />
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h4 style={{ marginBottom: "18px" }}>{CAR_INSURANCE.disclaimer}</h4>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px">
              <Box className="inner">
                <img src="./images/idea.svg" width="94px" />
                <div style={{ flex: "1 0 0" }}>
                  <p>{CAR_INSURANCE.faq_note}</p>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </>
  );
}

export default CarInsurance;
