import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays, subYears } from "date-fns";
import { useEffect, useState } from "react";
import CustomButton from "../../../../Component/Common/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { useAppSelector } from "../../../../Store/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colours";
import { THealthAddForm } from "../../../../Type/Health/THealthSlice";
import "./../../../../CommonSCSS/Forms.scss";
import MultipleSearchDropdown from "../../../../Component/Common/FieldTypes/MultipleSearchDropdown/MultiSearchDropdown";

const Travelform = ({
  formData,
  backFunction,
  loading,
  relationship_data,
}: {
  formData: THealthAddForm;
  backFunction: Function;
  loading: boolean;
  relationship_data: any;
}) => {
  const DATA = useAppSelector((state) => state.CommonDropdownData);
  const [pageStatus, setPageStatus] = useState<"1" | "2" | "3">("1");

  // Screen height is more than add space on top
  const [addSpacing, setAddSpacing] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      setAddSpacing(screenHeight > 700);
    };
    // Initial check on mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [planType, setPlanType] = useState<"individualFamily" | "student">(
    "individualFamily"
  );
  const [frequencyTrip, setFrequencyTrip] = useState<
    "singleTrip" | "multiTrip"
  >("singleTrip");
  const [travellerMedicalCondition, settravellerMedicalCondition] = useState<
    "yes" | "no"
  >("no");

  return (
    <Box className="productForm">
      {pageStatus !== "1" ? (
        <Box className="backnavigate">
          <Grid container columnSpacing={3} alignItems={"center"}>
            <Grid xs={12} textAlign="left">
              <Link className="backStep" onClick={() => backFunction()}>
                <ChevronLeftIcon /> Back
              </Link>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <div
        className={`formWrapper healthbg ${addSpacing ? "changeStyle" : ""}`}
      >
        <div>
          {pageStatus === "1" ? (
            <Grid
              container
              spacing={3}
              className="row "
              sx={{ justifyContent: "center" }}
            >
              <Grid xs={12} sx={{ textAlign: "center" }}>
                <img src="/images/logo.svg" alt="bima-street" height="60px" />
                <h1 className="pageHeading mt-10">
                  Your <span>Travel</span> Plans
                </h1>
                <p className="heading_desc">
                  Travel insurance policy is a prudent step to protect you
                </p>
              </Grid>
              <h6 className="pageHeading">
                <p className="toinsure">Select the travel details</p>
              </h6>
              <Grid xs={12}>
                <>
                  <Grid
                    container
                    columnSpacing={3}
                    className="row mt-3 mb-8"
                    sx={{ justifyContent: "center" }}
                  >
                    <Grid xs={6}>
                      <MultipleSearchDropdown
                        class_name="inputField"
                        attrName={undefined}
                        title={"Select Country where you want to travel"}
                        value={undefined}
                        value_update={() => {}}
                        multiple_data={[
                          { title: "india" },
                          { title: "china" },
                          { title: "usa" },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columnSpacing={3}
                    className="row mb-6"
                    sx={{ justifyContent: "center" }}
                  >
                    <Grid xs={4}>
                      <Box>
                        <p className="toinsure" style={{ textAlign: "center" }}>
                          {" "}
                          Select Plan Type
                        </p>
                        <ul
                          className="varient_list"
                          style={{
                            marginTop: "8px",
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          <li
                            onClick={() => setPlanType("individualFamily")}
                            className={
                              planType === "individualFamily" ? "active" : ""
                            }
                          >
                            Individual/Family
                          </li>
                          <li
                            onClick={() => setPlanType("student")}
                            className={planType === "student" ? "active" : ""}
                          >
                            Students
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                    <Grid xs={4}>
                      <Box>
                        <p className="toinsure" style={{ textAlign: "center" }}>
                          {" "}
                          Select Frequency of trip
                        </p>
                        <ul
                          className="varient_list"
                          style={{
                            marginTop: "8px",
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          <li
                            onClick={() => setFrequencyTrip("singleTrip")}
                            className={
                              frequencyTrip === "singleTrip" ? "active" : ""
                            }
                          >
                            Single Trip
                          </li>
                          <li
                            onClick={() => setFrequencyTrip("multiTrip")}
                            className={
                              frequencyTrip === "multiTrip" ? "active" : ""
                            }
                          >
                            Multi Trip
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      maxWidth: "624px",
                      margin: "0 auto",
                    }}
                  >
                    <Grid xs={12} textAlign={"center"}>
                      <CustomButton
                        onClickFunction={() => {
                          setPageStatus("2");
                        }}
                        text_name="Continue"
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          ) : pageStatus === "2" ? (
            <Grid
              container
              spacing={3}
              className="row "
              sx={{ justifyContent: "center" }}
            >
              <Grid xs={12} sx={{ textAlign: "center" }}>
                <h6 className="pageHeading">Enter Travel Details</h6>
              </Grid>
              <h6 className="pageHeading">
                <p className="toinsure">Select trip start and end details</p>
              </h6>
              <Grid xs={12}>
                <>
                  <Grid
                    container
                    columnSpacing={3}
                    className="row mt-3 mb-8"
                    sx={{
                      justifyContent: "center",
                      margin: "24px auto",
                      maxWidth: "624px",
                    }}
                  >
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField datepicker"
                        title={"Start Date"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Select Start Date"}
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                        default_date={subDays(new Date(), 90)}
                      />
                    </Grid>
                    {frequencyTrip === "singleTrip" ? (
                      <Grid xs={6}>
                        <DatePicker
                          class_name="inputField datepicker"
                          title={"End Date"}
                          value={""}
                          attrName={""}
                          value_update={() => {}}
                          warn_status={false}
                          error_message={"Select End Date"}
                          min_date={15 * 12}
                          max_date={3}
                          date_validation_type="MONTHS"
                          default_date={subDays(new Date(), 90)}
                        />
                      </Grid>
                    ) : (
                      <Grid xs={6}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Duration"
                          value={""}
                          attrName={""}
                          value_update={() => {}}
                          data={[]}
                          warn_status={false}
                          error_message={"Select Duration"}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    className="row mb-4"
                    sx={{
                      justifyContent: "center",
                      margin: "24px auto",
                      maxWidth: "624px",
                    }}
                  >
                    <Grid xs={12} textAlign={"center"}>
                      <p className="toinsure mb-2">Enter age of travellers</p>
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Traveller 1"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter Traveller1 age"}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Traveller 2"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter Traveller 2 age"}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Traveller 3"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter Traveller 3 age"}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Traveller 4"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter Traveller 4 age"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    className="row mb-4"
                    sx={{
                      justifyContent: "center",
                      margin: "24px auto",
                      maxWidth: "624px",
                    }}
                  >
                    <Grid xs={12}>
                      <Box>
                        <p className="toinsure" style={{ textAlign: "center" }}>
                          {" "}
                          Does any of the traveller(s) have any pre-existing
                          medical condition like BP, diabetes etc?
                        </p>
                        <ul
                          className="varient_list"
                          style={{
                            marginTop: "8px",
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          <li
                            onClick={() => settravellerMedicalCondition("yes")}
                            className={
                              travellerMedicalCondition === "yes"
                                ? "active"
                                : ""
                            }
                          >
                            Yes
                          </li>
                          <li
                            onClick={() => settravellerMedicalCondition("no")}
                            className={
                              travellerMedicalCondition === "no" ? "active" : ""
                            }
                          >
                            No
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      maxWidth: "624px",
                      margin: "0 auto",
                    }}
                  >
                    <Grid xs={12} textAlign={"center"}>
                      <CustomButton
                        onClickFunction={() => {
                          setPageStatus("3");
                        }}
                        text_name="Continue"
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              className="row"
              sx={{ justifyContent: "center" }}
            >
              <Grid xs={12}>
                <>
                  <Grid
                    container
                    spacing={3}
                    className="row"
                    sx={{ justifyContent: "center" }}
                  >
                    <Grid xs={12} sx={{ textAlign: "center" }}>
                      <h6 className="pageHeading">Personal Details</h6>
                      <p className="heading_desc">
                        Please provide your relevant information so we can
                        assist you effectively, if needed.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      margin: "24px auto",
                      maxWidth: "624px",
                    }}
                  >
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Full Name"}
                        value={""}
                        attrName={"name"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message="Enter Full Name"
                        validation_type="NAME"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Mobile"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        validation_type="NUMBER"
                        max_length={10}
                        error_message={
                          isEmpty(formData.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number"
                        }
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Email"}
                        value={""}
                        attrName={"name"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message="Enter Valid Email"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid xs={12} textAlign={"center"}>
                      <CustomButton
                        text_name={"View Quotes"}
                        onClickFunction={() => {}}
                        loading={loading}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      className="disabledCheckbox"
                      textAlign={"center"}
                    >
                      <CustomCheckbox
                        label={
                          <>
                            By clicking on view quotes you agree to all{" "}
                            <a
                              onClick={() =>
                                window.open(
                                  COMMON_ROUTES.TERM_AND_CONDTION,
                                  "_blank"
                                )
                              }
                            >
                              Terms & Conditions
                            </a>
                            .
                          </>
                        }
                        defaultChecked
                        value={true}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </Box>
  );
};

export default Travelform;
