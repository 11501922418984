import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import TextArea from "../../../../Component/Common/FieldTypes/TextArea/TextArea";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import "./Complaint.scss";

function NeedHelp({
  fieldsUpdateState,
  complaintFields,
  setcomplaintFields,
  validate_Form,
  loader,
}: {
  fieldsUpdateState: Function;
  complaintFields: any;
  setcomplaintFields: any;
  validate_Form: Function;
  loader: any;
}) {
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      <Navbar />
      <div className="complaint-parent">
        <div className="frame-group">
          <div className="frame-wrapper">
            <div className="contact-us-parent">
              <div className="contact-us">
                <div className="contact-us1">
                  <h1>How Can We Assist You?</h1>
                </div>
              </div>
              <div className="we-eagerly-await">
                We eagerly await your input! share your thoughts with us, as we
                value and cherish every perspective we receive.
              </div>
            </div>
          </div>
          <div className="frame-container">
            <div className="objects-parent">
              <img
                className="objects-icon"
                alt=""
                src="./images/complaint_mail_img.svg"
              />
              <div className="want-to-connect-with-us-parent">
                <div className="want-to-connect-container">
                  <span className="ant-to-connect">
                    Want to connect with us?
                  </span>
                </div>
                <div className="just-share-your">
                  Just share your information.
                </div>
              </div>
            </div>
            <div className="frame-div">
              <div className="input-field-parent">
                <div className="input-field1">
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    // validation_type="NAME"s
                    value={complaintFields.fullname.value}
                    attrName={[
                      "fullname",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.fullname.warning}
                    error_message={
                      isEmpty(complaintFields.fullname.value)
                        ? "Enter Full Name"
                        : "Enter Valid Full Name"
                    }
                  />
                </div>
                <div className="input-field-group">
                  <div className="input-field2">
                    <RKTextField
                      class_name="inputField"
                      title={"Mobile"}
                      value={complaintFields.mobile.value}
                      attrName={[
                        "mobile",
                        "value",
                        complaintFields,
                        setcomplaintFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={complaintFields.mobile.warning}
                      error_message={
                        isEmpty(complaintFields.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter valid mobile number"
                      }
                      max_length={10}
                    />
                  </div>
                  <div className="input-field2">
                    <RKTextField
                      class_name="inputField"
                      title={"Email"}
                      value={complaintFields.email.value}
                      attrName={[
                        "email",
                        "value",
                        complaintFields,
                        setcomplaintFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={complaintFields.email.warning}
                      error_message={
                        isEmpty(complaintFields.email.value)
                          ? "Enter Email"
                          : "Enter Valid Email"
                      }
                    />
                  </div>
                </div>
                <div className="input-field-wrapper">
                  <div className="input-field4">
                    <TextArea
                      class_name="inputField"
                      title={"How can we assist you?"}
                      value={complaintFields.comments.value}
                      attrName={[
                        "comments",
                        "value",
                        complaintFields,
                        setcomplaintFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={complaintFields.comments.warning}
                      error_message={" Enter Comments"}
                      placeholder="wirte here"
                    />
                  </div>
                </div>
                <div className="ctaBtn">
                  <Buttons
                    loading={loader}
                    onClickFunction={() => {
                      validate_Form();
                    }}
                    class_name="greenLgBtn"
                    text_name="Submit Details"
                  />
                </div>
              </div>
              <div className="frame-wrapper1">
                <div className="customer-support-on-91-9676-parent">
                  <div className="customer-support-on-container">
                    <span>
                      <span>Customer Support on</span>
                      <span className="span">{` `}</span>
                    </span>
                    <a
                      className="a"
                      href="tel:(+91) 9676671888"
                      target="_blank"
                    >
                      <span className="span1">(+91) 9676671888</span>
                    </a>
                  </div>
                  <div className="am-to-0600pm">10:00AM to 06:00PM</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NeedHelp;
