import Carousel from "react-multi-carousel";
import { useAppSelector } from "../../../../../Store/hooks";
import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";

function AboutUS() {
  const about_us = useAppSelector(
    (state) => state?.CMS?.about_us?.data?.attributes
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 960 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 959, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="about-us">
      <div className="frame-parent">
        <div className="frame-div">
          <div className="professionalism-technical-com-wrapper">
            <div className="professionalism-technical-com">
              {about_us?.desc}
            </div>
          </div>
          <div className="left-area-parent">
            <div className="left-area">
              <div className="we-are-insurance-brokers-licen-parent">
                <div className="we-are-insurance">
                  {about_us?.primary_info.title}
                </div>
                <div className="we-have-professionals">
                  {about_us?.primary_info.desc}
                </div>
              </div>
              <div className="instance-parent">
                {about_us?.primary_info_data.map((data, index) => (
                  <div className="frame-wrapper">
                    <div className="frame-parent1">
                      <div className="frame-wrapper1">
                        <img
                          alt=""
                          src={STRAPI_DOMAIN + data.image.data.attributes.url}
                        />
                      </div>
                      <div className="committed-to-the-objective-of-parent">
                        <div className="committed-to-the">{data.title}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="right-area">
              <img
                className="illustration-icon"
                alt=""
                src={
                  STRAPI_DOMAIN +
                  about_us?.primary_info.image.data.attributes.url
                }
              />
            </div>
          </div>
        </div>
        <div className="frame-parent22">
          <div className="expertise-parent">
            <div className="expertise">
              <h2>{about_us?.expertise.title}</h2>
            </div>
            <div className="some-of-the">{about_us?.expertise.desc}</div>
          </div>
          {about_us?.expertise_data && (
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
            >
              {about_us?.expertise_data &&
                about_us?.expertise_data?.map((data, index) => (
                  <div key={index}>
                    <div className="productCard">
                      <div className="inner">
                        <img
                          src={
                            STRAPI_DOMAIN + data?.image?.data?.attributes?.url
                          }
                          alt={data?.title}
                        />
                        <h4>{data?.title}</h4>
                        <p>{data?.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          )}
        </div>
        <div className="our-mission-vision-parent">
          <div className="our-mission">
            <h2>{about_us?.mission_title}</h2>
          </div>
          <div className="frame-parent32">
            <img
              className="frame-icon3"
              alt=""
              src={STRAPI_DOMAIN + about_us?.mission_image.data.attributes.url}
            />
            <div className="frame-parent33">
              <div className="we-are-insurance-brokers-licen-parent">
                <div className="health-insurance">
                  {about_us?.vision_statement.title}
                </div>
                <div className="to-establish-and">
                  {about_us?.vision_statement.desc}
                </div>
              </div>
              <div className="mission-statement-parent">
                <div className="mission-statement">
                  {about_us?.mission_statement_title}
                </div>
                {about_us?.mission_statement_data.map((data, index) => (
                  <div className="charmcircle-tick-parent">
                    <img
                      className="charmcircle-tick-icon"
                      alt=""
                      src="./images/list-tick-icon.svg"
                    />
                    <div className="professionalism-technical-com">
                      {data.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="frame-parent34">
          <div className="ethics-values-principles-wrapper">
            <div className="our-mission">{about_us?.ethics_title}</div>
          </div>
          <div className="frame-parent35">
            {about_us?.ethics_data.map((data, index) => (
              <div className="parent">
                <div className="div">{index + 1}.</div>
                <div className="compliance-with-irdai">{data.title}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="frame-parent36">
          <div className="quality-policy-statement-parent">
            <div className="we-are-insurance">
              <h2>{about_us?.quality.title}</h2>
            </div>
            <div className="to-establish-and">{about_us?.quality.desc}</div>
          </div>
          <div className="towards-this-goal-we-are-com-parent">
            <div className="we-are-insurance">
              {about_us?.quality_data_title}
            </div>
            <div className="frame-parent37">
              {about_us?.quality_data.map((data, index) => (
                <>
                  <div className="frame-parent38">
                    <div className="frame-frame">
                      <img
                        className="frame-icon4"
                        alt=""
                        src={STRAPI_DOMAIN + data.image.data.attributes.url}
                      />
                    </div>
                    <div className="providing-quality-advice-throu-wrapper">
                      <div className="professionalism-technical-com">
                        {data.title}
                      </div>
                    </div>
                  </div>
                  <div className="line-div" />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUS;
