import { createSlice } from "@reduxjs/toolkit";
import { TPaymentGateway } from "../../../Type/PaymentGateway/TPaymentGateway";
import { PAYMENT_GATEWAY_REDUCERS } from "./PaymentGatewayReducer";

const intitialState: TPaymentGateway = {
  METHOD: "GET",
  PAYMENT_URL: "",
};

export const PaymentGatewaySlice = createSlice({
  name: "PaymentGateway",
  initialState: intitialState,
  reducers: { ...PAYMENT_GATEWAY_REDUCERS },
});
