import { PayloadAction } from "@reduxjs/toolkit";
import { TPirSlice } from "../../../Type/PIR/TPir";
import { TPirObject } from "../../../Type/PIR/TPirObject";
import moment from "moment";

const initialState: TPirSlice = {
  PERSONAL: {
    firstname: { value: "", warning: false },
    lastname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    pincode: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    incomeAmount: { value: 0, warning: false },
  },
  INSUREDFOR: {
    insuredMember: [
      { value: "Self", warning: false },
      { value: "Spouse", warning: false },
      { value: "Parents", warning: false },
      { value: "Childrens", warning: false },
    ],
  },
  FINANCIAL: {
    incomeAmount: { value: 0, warning: false },
    monthlyExpenses: { value: 0, warning: false },
    // loans: {
    outstandingLoans: { value: "No", warning: false },
    outstandingAmount: { value: 0, warning: false },
    // },
    // invesments: {
    investments: { value: "No", warning: false },
    investmentsAmount: { value: 0, warning: false },
    // },
  },
  INSURANCEPOLICY: {
    healthInsurance: { value: "No", warning: false },
    healthCoverageAmount: { value: 0, warning: false },

    termInsurance: { value: "No", warning: false },
    termCoverageAmount: { value: 0, warning: false },
    smoke: { value: "No", warning: false },
  },
  OWNHOUSE: {
    ownHouse: { value: "", warning: false },
    houseValueAmount: { value: 0, warning: false },
    householdItemsAmount: { value: 0, warning: false },
  },
  HAVEANYVEHICLE: {
    haveAnyVehicle: {
      value: "No",
      warning: false,
    },
  },
  HAVEVEHICLE: [
    {
      haveAnyVehicle: "",
      vehicleType: { value: "", warning: false },
      makeModel: { value: "", warning: false },
      fuelType: { value: "", warning: false },
      variantCC: { value: "", warning: false },
      registrationDate: { value: "", warning: false },
      policyExpiryDate: { value: "", warning: false },
      regNo: { value: "", warning: false },
      previousInsurer: { value: "", warning: false },
      make_model_data: [],
      fuel_type_data: [],
      variant_data: [],
    },
  ],
  RELATION: {
    adult: {
      value: "1A",
      warning: false,
    },
    haveChildren: {
      value: "No",
      warning: false,
    },
    childrenCount: {
      value: 0,
      warning: false,
    },
    second_adult_dob: {
      value: "",
      warning: false,
    },
    child_1_dob: {
      value: "",
      warning: false,
    },
    child_2_dob: {
      value: "",
      warning: false,
    },
    child_3_dob: {
      value: "",
      warning: false,
    },
    child_4_dob: {
      value: "",
      warning: false,
    },
    relation: {
      value: "",
      warning: false,
    },
    child_1_relation: {
      value: "",
      warning: false,
    },
    child_2_relation: {
      value: "",
      warning: false,
    },
    child_3_relation: {
      value: "",
      warning: false,
    },
    child_4_relation: {
      value: "",
      warning: false,
    },
  },
  sumInsured: 0,
  termSumInsured: 0,
  step: 0,
};

const PIR_DATA_PERSONAL = (state: TPirSlice, action: PayloadAction<any>) => {
  state.PERSONAL = action.payload;
};
const PIR_DATA_INSURANCE_FOR = (
  state: TPirSlice,
  action: PayloadAction<any>
) => {
  state.RELATION = action.payload;
};
const PIR_DATA_FINANCIAL = (state: TPirSlice, action: PayloadAction<any>) => {
  state.FINANCIAL = action.payload;
};
const PIR_DATA_INSURANCE_POLICY = (
  state: TPirSlice,
  action: PayloadAction<any>
) => {
  state.INSURANCEPOLICY = action.payload;
};
const PIR_DATA_OWN_HOUSE = (state: TPirSlice, action: PayloadAction<any>) => {
  state.OWNHOUSE = action.payload;
};
const PIR_DATA_VEHICLE = (state: TPirSlice, action: PayloadAction<any>) => {
  state.HAVEVEHICLE = action.payload.HAVEVEHICLE;
  state.HAVEANYVEHICLE = action.payload.HAVEANYVEHICLE;
};
const PIR_STEP = (state: TPirSlice, action: PayloadAction<any>) => {
  state.step = action.payload;
};
const PIR_DATA = (
  state: TPirSlice,
  action: PayloadAction<any>
  // action: PayloadAction<{ key: ValueTypes; value: TPirObject }>
) => {
  state.PERSONAL = action.payload.PERSONAL;
  state.INSUREDFOR = action.payload.INSUREDFOR;
  state.INSURANCEPOLICY = action.payload.INSURANCEPOLICY;
  state.FINANCIAL = action.payload.FINANCIAL;
  state.OWNHOUSE = action.payload.OWNHOUSE;
  state.HAVEVEHICLE = action.payload.HAVEVEHICLE;
  state.HAVEANYVEHICLE = action.payload.HAVEANYVEHICLE;
  state.RELATION = action.payload.RELATION;
};

const PIR_DATA_SUMINSURED = (
  state: TPirSlice,
  action: PayloadAction<any>
  // action: PayloadAction<{ key: ValueTypes; value: TPirObject }>
) => {
  state.sumInsured = action.payload;
};
const PIR_DATA_SUMINSURED_TERM = (
  state: TPirSlice,
  action: PayloadAction<any>
  // action: PayloadAction<{ key: ValueTypes; value: TPirObject }>
) => {
  state.termSumInsured = action.payload;
};

const PIR_RESET_DATA = (state: TPirSlice) => {
  return initialState;
};

const PIR_UPDATE_REPORT = (state: TPirSlice, action: PayloadAction<any>) => {
  console.log("rrrrr====", action.payload);
  state.PERSONAL.firstname.value = action.payload.firstname;
  state.PERSONAL.dob.value = action.payload.dob;
  state.PERSONAL.pincode.value = action.payload.pincode;
  state.PERSONAL.gender.value = action.payload.gender;
  state.PERSONAL.mobile.value = action.payload.mobile;
  state.PERSONAL.incomeAmount.value = action.payload.incomeAmount;
  state.PERSONAL.firstname.warning = false;
  state.sumInsured = action.payload.SI;
  state.INSURANCEPOLICY.healthCoverageAmount.value =
    action.payload.healthCoverageAmount;
  state.termSumInsured = action.payload.TermSI;
  state.INSURANCEPOLICY.termCoverageAmount.value =
    action.payload.termCoverageAmount;
  state.HAVEANYVEHICLE.haveAnyVehicle.value = action.payload.haveAnyVehicle;
  state.HAVEVEHICLE = action.payload.vehicles.map((veh: any) => {
    console.log("veh", veh);
    return {
      haveAnyVehicle: "",
      vehicleType: {
        value: veh.vehicleType,
        warning: false,
      },
      makeModel: {
        value: veh.makeModel,
        warning: false,
      },
      fuelType: {
        value: veh.fuelType,
        warning: false,
      },
      variantCC: {
        value: veh.variantCC,
        warning: false,
      },
      registrationDate:
        veh.vehicleType == "Car"
          ? {
              value: moment(veh.registrationDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              ),
              warning: false,
            }
          : {
              value: veh.registrationDate,
              warning: false,
            },
      policyExpiryDate: {
        value: veh.policyExpiryDate,
        warning: false,
      },
      regNo: {
        value: veh.regNo,
        warning: false,
      },
      previousInsurer: {
        value: veh.previousInsurer,
        warning: false,
      },
    };
  });
};
const PIR_UPDATE_EMAIL_IN_REPORT = (
  state: TPirSlice,
  action: PayloadAction<any>
  // action: PayloadAction<{ key: ValueTypes; value: TPirObject }>
) => {
  state.PERSONAL.email.value = action.payload;
};

export const PIR_REDUCERS = {
  PIR_DATA,
  PIR_DATA_SUMINSURED,
  PIR_RESET_DATA,
  PIR_DATA_PERSONAL,
  PIR_DATA_INSURANCE_FOR,
  PIR_DATA_FINANCIAL,
  PIR_DATA_INSURANCE_POLICY,
  PIR_DATA_OWN_HOUSE,
  PIR_DATA_VEHICLE,
  PIR_DATA_SUMINSURED_TERM,
  PIR_STEP,
  PIR_UPDATE_REPORT,
  PIR_UPDATE_EMAIL_IN_REPORT,
};
