import { useState } from "react";
import NeedHelp from "../../Pages/Desktop/CMS/NeedHelp/NeedHelp";
import MNeedHelp from "../../Pages/Mobile/CMS/MNeedHelp/MNeedHelp";
import { HOME_SERVICES } from "../../Services/Home/HomeServices";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { toast } from "react-toastify";

function NeedHelpConatiner() {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);

  const [complaintFields, setcomplaintFields] = useState<{
    fullname: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    comments: { value: string; warning: boolean };
  }>({
    fullname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    comments: { value: "", warning: false },
  });
  console.log("complaintFields", complaintFields);
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: {
        ...[attrName[0]],
        [attrName[1]]: value,
        warning:
          attrName[0] === "mobile"
            ? !validateMobileNumber(value)
            : attrName[0] === "fullname"
            ? !validateFullName(value)
            : attrName[0] === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    });
  };

  const NEED_HELP = () => {
    const toastID = toast.loading("Please wait...");
    let params = {
      FullName: complaintFields.fullname.value,
      Mobile: complaintFields.mobile.value,
      Email: complaintFields.email.value,
      description: complaintFields.comments.value,
    };
    const onsuccess = (data: any) => {
      toast.dismiss(toastID);
      const results = data.results;
      const error = results.error;
      const message = results.message;

      if (error) {
        toast.error(`${message}`);
      } else {
        setcomplaintFields({
          fullname: { value: "", warning: false },
          mobile: { value: "", warning: false },
          email: { value: "", warning: false },
          comments: { value: "", warning: false },
        });
        toast.success(
          "Thank you for your query! Our team will reach out to you shortly."
        );
      }
    };
    const onError = (data: any) => {};
    HOME_SERVICES.SUBMIT_NEED_HELP(onsuccess, onError, params);
  };
  const validate_Form = () => {
    let data = { ...complaintFields };
    data = {
      ...data,
      fullname: {
        ...data.fullname,
        warning: !validateFullName(data.fullname.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      comments: {
        ...data.comments,
        warning: isEmpty(data.comments.value),
      },
    };
    setcomplaintFields(data);
    if (
      complaintFields.fullname.warning === false ||
      complaintFields.email.warning === false ||
      complaintFields.mobile.warning === false ||
      complaintFields.comments.warning === false
    ) {
      NEED_HELP();
    }
  };
  return isMobile ? (
    <MNeedHelp
      fieldsUpdateState={fieldsUpdateState}
      complaintFields={complaintFields}
      setcomplaintFields={setcomplaintFields}
      validate_Form={validate_Form}
    />
  ) : (
    <NeedHelp
      fieldsUpdateState={fieldsUpdateState}
      complaintFields={complaintFields}
      setcomplaintFields={setcomplaintFields}
      validate_Form={validate_Form}
      loader={loader}
    />
  );
}

export default NeedHelpConatiner;
