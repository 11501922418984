import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { PIR_FORM_DTO } from "../../DTO/PIRDTO/PirDTO";
import { NODE_DOMAIN } from "../../URLCollection/Domain";
import { PirUrls } from "../../URLCollection/PIR/PirUrsls";

const PIR_FORM = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: PIR_FORM_DTO
) => {
  PostAPI.call(
    PirUrls.FORM,
    // "https://nodeapi.bimastreet.com/api/v1/pera/user",
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const PIR_FETCH_FORM = (
  Id: string,
  onSuccess: (data: any) => void,
  onError: (data: any) => void
) => {
  GetAPI.call(
    `${PirUrls.FETCHFORM}?quote_no=${Id}`,
    {},
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const PIR_EMAIL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  bData: any
) => {
  PostAPI.call(
    PirUrls.EMAIL,
    bData,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

export const PIR_FORM_SERVICES = {
  PIR_FORM,
  PIR_FETCH_FORM,
  PIR_EMAIL,
};
