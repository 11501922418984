import { URL_CONSTANTS } from "../../URLCollection/URL";
import { HealthURLs } from "../../URLCollection/Health/HealthURLS";
import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { ADD_HEALTH_FORM_DTO } from "../../DTO/HealthDTO/AddHealthFormDTO";
import { GetQuotationDTO } from "../../DTO/HealthDTO/GetQuotationDTO";

const ADD_HEALTH_FORM = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: ADD_HEALTH_FORM_DTO
) => {
  PostAPI.call(
    HealthURLs.ADD_HEALTH_FORM,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

export const HEALTH_FORM_SERVICES = {
  ADD_HEALTH_FORM,
};
