import { PayloadAction } from "@reduxjs/toolkit";
import { TDropdown } from "../../../Type/Common/TDropdown";
import {
  DROPDOWN_MASTER,
  TSelectedQuote,
  TTermQuoteFieldData,
  TTermSlice,
} from "../../../Type/TTermSlice";
import {
  TTermAddForm,
  TTermAddFormResponse,
} from "../../../Type/Term/TTermAddForm";
import { TRidersBenefitsResponse } from "../../../Type/Term/TRidersBenifits";
import { TDropdownData } from "../../../Type/DropdownData/DropdownData";

function BULK_UPDATE(state: TTermSlice, action: PayloadAction<TTermSlice>) {
  const data: TTermSlice = { ...state, ...action.payload };

  return data;
}

function UPDATE_DROPDOWN_DATA(
  state: TTermSlice,
  action: PayloadAction<{ key: keyof DROPDOWN_MASTER; value: TDropdown[] }>
) {
  const { key, value } = action.payload;
  let data: TTermSlice = { ...state };

  data = { ...data, DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value } };

  return data;
}

function setTermAddFormData(
  state: TTermSlice,
  action: PayloadAction<TTermAddForm>
) {
  let data: TTermSlice = { ...state };
  data = {
    ...data,
    ADD_FORM: {
      ...state.ADD_FORM,
      ...action.payload,
    },
  };
  return data;
}
function UPDATE_QUOTE_FIELD_DATA(
  state: TTermSlice,
  action: PayloadAction<{ key: keyof TTermQuoteFieldData; value: string }[]>
) {
  let data: TTermSlice = { ...state };

  for (const { key, value } of action.payload) {
    data = {
      ...data,
      QUOTE_FIELDS_DATA: {
        ...data.QUOTE_FIELDS_DATA,
        [key]: value,
      },
    };
  }

  return data;
}

// function setTermAddFormDataResponse(
//   state: TTermSlice,
//   action: PayloadAction<TTermAddFormResponse>
// ) {
//   let data: TTermSlice = { ...state };
//   data = { ...data, ADD_FORM_RESPONSE: {...ADD_FORM_RESPONSE,action.payload} };
//   return data;
// }

function setTermAddFormDataResponse(
  state: TTermSlice,
  action: PayloadAction<TTermAddFormResponse>
) {
  const updatedState: TTermSlice = {
    ...state,
    ADD_FORM_RESPONSE: {
      ...state.ADD_FORM_RESPONSE,
      ...action.payload,
    },
  };
  return updatedState;
}

function UPDATE_ADD_FORM_RESPONSE(
  state: TTermSlice,
  action: PayloadAction<
    { key: keyof TTermAddFormResponse; value: string | number }[]
  >
) {
  let updatedState: TTermSlice = {
    ...state,
    ADD_FORM_RESPONSE: {
      ...state.ADD_FORM_RESPONSE,
    },
  };

  for (const { key, value } of action.payload) {
    // Ensure key is a valid property of TTermAddFormResponse
    if (key in updatedState.ADD_FORM_RESPONSE) {
      updatedState = {
        ...updatedState,
        ADD_FORM_RESPONSE: { ...updatedState.ADD_FORM_RESPONSE, [key]: value },
      };
    }
  }

  return updatedState;
}

function UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
  state: TTermSlice,
  action: PayloadAction<{
    updates: Array<{ key: string; value: boolean | string }>;
  }>
) {
  const { updates } = action.payload;
  let data = { ...state };

  console.log(data, "data");
  data = {
    ...data,
    RIDERS_BENEFITS: {
      ...data.RIDERS_BENEFITS,
      local_value: {
        ...data.RIDERS_BENEFITS.local_value,
        ...updates.reduce((updatedProperties, { key, value }) => {
          return { ...updatedProperties, [key]: value };
        }, {}),
      },
    },
  };

  return data;
}

function SET_RIDERS_BENEFITS_DATA(
  state: TTermSlice,
  action: PayloadAction<TRidersBenefitsResponse>
) {
  let data = { ...state };
  data = {
    ...data,
    RIDERS_BENEFITS: {
      ...state.RIDERS_BENEFITS,
      riders_benefits_response: action.payload,
    },
  };

  return data;
}

const SET_SELECTED_QUOTE_DATA = (
  state: TTermSlice,
  action: PayloadAction<TSelectedQuote>
) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const ADD_PREMIUM = (state: TTermSlice, action: PayloadAction<number>) => {
  const { SELECTED_QUOTE_DATA } = state;

  if (!SELECTED_QUOTE_DATA || !SELECTED_QUOTE_DATA.premiumDetails) {
    return state;
  }

  const { premiumDetails } = SELECTED_QUOTE_DATA;

  return {
    ...state,
    SELECTED_QUOTE_DATA: {
      ...SELECTED_QUOTE_DATA,
      premiumDetails: {
        ...premiumDetails,
        finalPremium: premiumDetails.finalPremium + action.payload,
      },
    },
  };
};

const SUB_PREMIUM = (state: TTermSlice, action: PayloadAction<number>) => {
  const { SELECTED_QUOTE_DATA } = state;

  if (!SELECTED_QUOTE_DATA || !SELECTED_QUOTE_DATA.premiumDetails) {
    return state;
  }

  const { premiumDetails } = SELECTED_QUOTE_DATA;

  return {
    ...state,
    SELECTED_QUOTE_DATA: {
      ...SELECTED_QUOTE_DATA,
      premiumDetails: {
        ...premiumDetails,
        finalPremium: premiumDetails.finalPremium - action.payload,
      },
    },
  };
};

const UPDATE_RIDERS = (state: TTermSlice, action: PayloadAction<any>) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: {
      ...state.SELECTED_QUOTE_DATA,
      premiumDetails: {
        ...state.SELECTED_QUOTE_DATA.premiumDetails,
        riders: action.payload,
      },
    },
  };

  return data;
};
function SET_DROPDOWN_DATA(
  state: TTermSlice,
  action: PayloadAction<{ key: keyof DROPDOWN_MASTER; value: TDropdown[] }>
) {
  const { key, value } = action.payload;
  let data: TTermSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}
export const TERM_REDUCERS = {
  BULK_UPDATE,
  UPDATE_DROPDOWN_DATA,
  setTermAddFormData,
  setTermAddFormDataResponse,
  UPDATE_ADD_FORM_RESPONSE,
  UPDATE_QUOTE_FIELD_DATA,
  SET_SELECTED_QUOTE_DATA,
  ADD_PREMIUM,
  SUB_PREMIUM,
  UPDATE_RIDERS,
  SET_RIDERS_BENEFITS_DATA,
  UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT,
  SET_DROPDOWN_DATA,
};

export type TwoTermReducers = typeof TERM_REDUCERS;
