import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const TW_URLs = {
  GET_DETAILS_BY_QUOTE_NO: `${NODE_DOMAIN}/api/v1/premium/quotedetail`,
  GET_SINGLE_QUOTE_COMPREHENSIVE: `${NODE_DOMAIN}/api/v1/premium/tw`,
  GET_SINGLE_QUOTE_SAOD: `${NODE_DOMAIN}/api/v1/premium/tw/saod`,
  GET_SINGLE_QUOTE_TP: `${NODE_DOMAIN}/api/v1/premium/tw/tp`,
  // ADD_FORM_DATA: `${PHP_DOMAIN}/api/tw-insurances/add.json`,
  GET_PRODUCT_LIST: `${NODE_DOMAIN}/api/v1/premium/get-product-lists.json?type=TW`,
  GET_CASHLESS_GARAGE_LIST: `${PHP_DOMAIN}/api/cashless-hospital-details/get-cashless-garage-details.json`,
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/car-details?type=TW&cRegNo=`,
  // GET_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}/api/previous-insurer-product-maps/get-prev-ins-lists.json`,
  // GET_VARIANT_LIST: `${PHP_DOMAIN}/api/tw-master-datas/get-variant-lists.json`,
  // GET_FUEL_TYPE_LIST: `${PHP_DOMAIN}/api/tw-master-datas/get-fuels.json`,
  // GET_MODEL_LIST: `${PHP_DOMAIN}/api/tw-master-datas/get-models-by-make.json`,
  // GET_MAKE_LIST: `${PHP_DOMAIN}/api/tw-master-datas/get-lists.json`,
  // GET_RTO_LIST: `${PHP_DOMAIN}/api/rto-product-maps/get-lists.json`,
  GET_MAKE_MODEL: `${NODE_DOMAIN}/api/v1/premium/make-model?type=TW`,
  UPDATE_DETAILS: `${PHP_DOMAIN}/api/tw-insurances/update-policy-details.json`,
  GET_SINGLE_QUOTE: `${PHP_DOMAIN}/api/tw-insurances/get-single-quote.json`,
  UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-addon`,
  UPDATE_IDV: `${NODE_DOMAIN}/api/v1/premium/cusIDV`,
  SEND_QUOTE_VIA_EMAIL: `${PHP_DOMAIN}/api/tw-insurances/send-email-quote-page.json`,
  PREMIUM_RECALCULATION: `${PHP_DOMAIN}/api/tw-insurances/recalculate-premium.json`,
  UPDATE_PERPOSAL_RECORDS: `${PHP_DOMAIN}/api/tw-proposals/capture-record.json`,
  UPDATE_FULL_PERPOSAL_RECORDS: `${PHP_DOMAIN}/api/tw-proposals/add.json`,
  GET_CITY_STATE_BY_PINCODE_ONLY: `${PHP_DOMAIN}/api/state-city-maps/getDetailsByPincode.json`,
  saveQuotesForPickUpWhereYouLeft: `${PHP_DOMAIN}/api/lowest-premium-details/add.json`,
  TWO_WHEELER_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}/api/previous-insurer-product-maps/get-prev-ins-lists.json`,
  VEHICLE_ON_LOAN: `${PHP_DOMAIN}/api/financier-masters/get-list-auto.json`,
  SEND_POLICY_EMAIL: `${PHP_DOMAIN}/api/tw-insurances/send-email-product-details.json`,
  PAYMENT_URL: `${PHP_DOMAIN}/api/tw-proposals/create-proposal.json`,
  BUY_ACTION: `${NODE_DOMAIN}/api/v1/premium/buy-action`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,

  GET_MAKE_LIST: `${NODE_DOMAIN}/api/v1/premium/make-list?type=TW`,
  GET_MODEL_LIST: `${NODE_DOMAIN}/api/v1/premium/model-make-list?type=TW&make=`,
  ADD_FORM_DATA: `${NODE_DOMAIN}/api/v1/premium/add-form/tw`,
  GET_PREVIOUS_INSURER_LIST: `${NODE_DOMAIN}/api/v1/premium/previous-insurers?type=TW`,
  GET_VARIANT_LIST: `${NODE_DOMAIN}/api/v1/premium/make-variant?type=TW&make_model=`,
  GET_FUEL_TYPE_LIST: `${NODE_DOMAIN}/api/v1/premium/make-fuel?type=TW&make_model=`,
  GET_RTO_LIST: `${NODE_DOMAIN}/api/v1/premium/rto-list?type=TW`,
};
