import { PayloadAction } from "@reduxjs/toolkit";
import { TCMSSlice } from "../../../Type/TCMSSlice";

function setCMSData(state: TCMSSlice, action: PayloadAction<TCMSSlice>) {
  return {
    ...state,
    ...action.payload,
  };
}

export const CMS_REDUCERS = {
  setCMSData,
};

export type CMSReducers = typeof CMS_REDUCERS;
