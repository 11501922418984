import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../CommonSCSS/ModalPopup.scss";
import RKTextField from "../../../Common/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";

const CustomerLogin = ({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  resendOtp: Function;
}) => {
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent xsWidth">
          <Box className="loginPopup">
            <Button
              className="closePopup"
              onClick={() => {
                setOpen(false);
                setShowEnterMobileSection(true);
                setLoginFields({
                  enterMobile: { value: "", warning: false },
                  enterOtp: { value: "", warning: false },
                });
              }}
            />

            {showEnterMobileSection ? (
              <>
                <Grid container spacing={3}>
                  <Grid xs={12} textAlign="center">
                    <img src="./images/customer_login_icon1.svg" alt="" />
                  </Grid>
                  <Grid xs={12} textAlign="center">
                    <h3 className="mb-2">Customer Login</h3>
                    <p>
                      Hey, Enter your details to get sign in to your account
                    </p>
                  </Grid>
                  <Grid xs={12} textAlign="center">
                    <RKTextField
                      class_name="inputField"
                      title={"Enter Mobile Number"}
                      value={loginFields.enterMobile.value}
                      attrName={[
                        "enterMobile",
                        "value",
                        loginFields,
                        setLoginFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={loginFields.enterMobile.warning}
                      error_message={
                        isEmpty(loginFields.enterMobile.value)
                          ? "Enter Mobile Number"
                          : "Enter Valid Mobile Number"
                      }
                      max_length={10}
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <Button
                      className="greenXlBtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        handleShowMessage();
                      }}>
                      Send OTP
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid xs={12} textAlign="center">
                    <img src="./images/customer_login_icon2.svg" alt="" />
                  </Grid>
                  <Grid xs={12} textAlign="center">
                    <h3 className="mb-2">One Time Password</h3>
                    <p>
                      OTP sent successfully on{" "}
                      <span>+91-{loginFields.enterMobile.value}</span>
                      <Link
                        className="editBtn"
                        onClick={() => {
                          setShowEnterMobileSection(true);
                        }}>
                        Edit
                      </Link>
                    </p>
                  </Grid>
                  <Grid xs={12} textAlign="center">
                    <RKTextField
                      class_name="inputField"
                      title={"Enter OTP"}
                      value={loginFields.enterOtp.value}
                      attrName={[
                        "enterOtp",
                        "value",
                        loginFields,
                        setLoginFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={loginFields.enterOtp.warning}
                      error_message={
                        isEmpty(loginFields.enterOtp.value)
                          ? "Enter OTP"
                          : "Enter Valid OTP"
                      }
                      max_length={4}
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <Button
                      onClick={() => {
                        showMessage();
                      }}
                      className="greenXlBtn"
                      style={{ width: "100%" }}>
                      Verify OTP
                    </Button>
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <Button
                      onClick={() => {
                        setLoginFields({
                          ...loginFields,
                          enterOtp: { value: "", warning: false },
                        });
                        resendOtp();
                      }}
                      className="greenXlBtn"
                      style={{ width: "100%" }}>
                      Resend OTP
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerLogin;
