import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../Navbar/Navbar.scss";

const Navbar: React.FC = () => {
  return (
    <Box className="proposalNavbar">
      <Grid container columnSpacing={3} paddingTop={0} paddingBottom={0}>
        <Grid xs={12}>
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.href = "https://dev.bimastreet.com";
            }}
          >
            <img
              src="/images/logo.svg"
              height="40px"
              alt="Brand Logo"
            />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
