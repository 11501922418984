import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";
import { useAppSelector } from "../../../../../Store/hooks";

function KeyManagTool() {
  const { key_management_personal } = useAppSelector((state) => state.CMS);
  return (
    <div className="about-us-management">
      {key_management_personal.data.data.map((data, index) => (
        <div className="mainWrapper">
          <div className="content-wrapper">
            <div className="content">
              <div className="fiii-chief-operating">{data.attributes.name}</div>
              <div className="kv-bhaskar-rao-ma-parent">
                <div className="kv-bhaskar-rao">
                  {data.attributes.designation}
                </div>
                <div className="instance-parent">
                  {data.attributes.data.map((sub_data, sub_index) => (
                    <div className="charmcircle-tick-parent">
                      <img
                        className="charmcircle-tick-icon"
                        alt=""
                        src="./images/list-tick-icon.svg"
                      />
                      <div
                        className="no-limit-on"
                        dangerouslySetInnerHTML={{
                          __html: sub_data.desc,
                        }}></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="photo">
              <img
                alt=""
                src={`${STRAPI_DOMAIN}${data.attributes.image.data.attributes.url}`}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default KeyManagTool;
