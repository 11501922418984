import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../CommonSCSS/ModalPopup.scss";
import {
  calculateAge,
  calculateRoundOffSumInsured,
  calculateRoundOffSumInsuredTerm,
  calculateShortOff,
  formatToCurrency,
  formatToCurrencyPir,
  isEmpty,
  validateEmail,
} from "../../../../SupportingFiles/HelpingFunction";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../Store/hooks";
import { PIR_FORM_SERVICES } from "../../../../Services/PIR/PirService";
import { toast } from "react-toastify";
import { TDropdown } from "../../../../Type/Common/TDropdown";
import { PIR_UPDATE_EMAIL_IN_REPORT } from "../../../../Store/Slice_Reducer/PIR/PirSlice";
import { useDispatch } from "react-redux";

const DetailSendPopup = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) => {
  const dispatch = useDispatch();

  const [emailInputBox, setEmailInputBox] = useState<{
    email: { value: string | any; warning: boolean };
  }>({
    email: { value: "", warning: false },
  });

  const [sumAssuredDataa, setSumAssuredDataa] = useState([
    { value: "", label: "" },
  ]);
  const [coverageUptoData, setCoverageUptoData] = useState([
    { value: "", label: "" },
  ]);
  const { PIR } = useAppSelector((state) => state);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);

  useEffect(() => {
    if (open) {
      const emailValue = PIR.PERSONAL.email.value || "";
      setEmailInputBox({ email: { value: emailValue, warning: false } });
    }
  }, [open, PIR.PERSONAL.email.value]);

  useEffect(() => {
    if (PIR.PERSONAL.dob.value) {
      const start_point: number =
        Number(calculateAge(PIR.PERSONAL.dob.value)) + 5;

      let data: TDropdown[] = [];
      for (let i = start_point; i <= 100; i++) {
        data.push({ value: `${i}`, label: `${i} Years` });
      }

      setCoverageUptoData(data);

      const calculateEndPoint = () => {
        const [year, month, date] = PIR.PERSONAL.dob.value.split("-");

        const age: number = calculateAge(`${date}-${month}-${year}`);
        console.log(age, "age");
        // let age = 25;

        if (age >= 18 && age <= 40)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 30;
        if (age >= 41 && age <= 45)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 22;
        if (age >= 46 && age <= 50)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 20;
        if (age >= 51 && age <= 55)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 12;
        if (age >= 56 && age <= 65)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 10;
        return 0;
      };

      const sumAssuredData: TDropdown[] = [];
      const endPoint: number = calculateEndPoint();
      console.log("ppp", endPoint);
      for (let i = 2; i <= endPoint; i += i < 50 ? 1 : i < 100 ? 10 : 100) {
        sumAssuredData.push({
          value: `${i * 100000}`,
          label: `₹ ${i >= 100 ? i / 100 : i} ${i < 100 ? "Lakh" : "Crores"}`,
        });
        if (i === 100) {
          sumAssuredData.push({
            value: "12500000",
            label: "₹ 1.25 Crores",
          });
        }
      }
      console.log("ppppp", sumAssuredData);
      setSumAssuredDataa(sumAssuredData);
    }
  }, [PIR]);

  const updateMasterState = (attrName: string[], value: string) => {
    let newValue: string | number = value;

    setEmailInputBox((prev) => ({
      ...prev,
      [attrName[1]]: { value: newValue, warning: isEmpty(value) },
    }));
  };

  const emailHandler = () => {
    const onSuccess = (res: any) => {
      const message = res.message;
      if (message.includes("successfully")) {
        toast.success("Email Sent Successfully!.");
        setOpen(false);
      } else {
        toast.error("Email Failed!");
      }
    };
    const onError1 = (res: any) => {
      console.log("onError...", res);
      toast.error("Email Failed");
    };
    let bData;
    let isValidEmail = validateEmail(emailInputBox.email.value);
    setEmailInputBox((prev) => ({
      email: {
        ...prev.email,
        warning: !validateEmail(prev.email.value),
      },
    }));
    if (isValidEmail && emailInputBox.email.value) {
      dispatch(PIR_UPDATE_EMAIL_IN_REPORT(emailInputBox.email.value));
      setOpen(false);
      bData = {
        email: emailInputBox.email.value,
        bodyData: {
          // healthInsurance: {
          //   existingCoverage: formatToCurrencyPir(
          //     Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
          //   )
          //     .split(" ")
          //     .slice(1)
          //     .join(" "),
          //   recommendedCoverage: formatToCurrencyPir(
          //     Number(
          //       calculateRoundOffSumInsured(
          //         formatToCurrency(Number(PIR.sumInsured)),
          //         DROPDOWN_DATA.SUM_INSURED
          //       )
          //     )
          //   )
          //     .split(" ")
          //     .slice(1)
          //     .join(" "),
          //   shortage: calculateShortOff(
          //     formatToCurrencyPir(
          //       Number(
          //         calculateRoundOffSumInsured(
          //           formatToCurrency(Number(PIR.sumInsured)),
          //           DROPDOWN_DATA.SUM_INSURED
          //         )
          //       )
          //     ),
          //     formatToCurrencyPir(
          //       Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
          //     )
          //   )
          //     .toString()
          //     .split(" ")
          //     .slice(1)
          //     .join(" "),
          // },
          ...(PIR.sumInsured > PIR.INSURANCEPOLICY.healthCoverageAmount.value
            ? {
                healthInsurance: {
                  existingCoverage: formatToCurrencyPir(
                    Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
                  )
                    .split(" ")
                    .slice(1)
                    .join(" "),
                  recommendedCoverage: formatToCurrencyPir(
                    Number(
                      calculateRoundOffSumInsured(
                        formatToCurrency(Number(PIR.sumInsured)),
                        DROPDOWN_DATA.SUM_INSURED
                      )
                    )
                  )
                    .split(" ")
                    .slice(1)
                    .join(" "),
                  shortage: calculateShortOff(
                    formatToCurrencyPir(
                      Number(
                        calculateRoundOffSumInsured(
                          formatToCurrency(Number(PIR.sumInsured)),
                          DROPDOWN_DATA.SUM_INSURED
                        )
                      )
                    ),
                    formatToCurrencyPir(
                      Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
                    )
                  )
                    .toString()
                    .split(" ")
                    .slice(1)
                    .join(" "),
                },
              }
            : {}),
          // termInsurance: {
          //   existingCoverage: formatToCurrencyPir(
          //     Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
          //   )
          //     .split(" ")
          //     .slice(1)
          //     .join(" "),
          //   recommendedCoverage: formatToCurrencyPir(
          //     Number(
          //       calculateRoundOffSumInsuredTerm(
          //         formatToCurrency(Number(PIR.termSumInsured)),
          //         sumAssuredDataa
          //       )
          //     )
          //   )
          //     .split(" ")
          //     .slice(1)
          //     .join(" "),
          //   shortage: calculateShortOff(
          //     formatToCurrencyPir(
          //       Number(
          //         calculateRoundOffSumInsuredTerm(
          //           formatToCurrency(Number(PIR.termSumInsured)),
          //           sumAssuredDataa
          //         )
          //       )
          //     ),
          //     formatToCurrencyPir(
          //       Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
          //     )
          //   )
          //     .toString()
          //     .split(" ")
          //     .slice(1)
          //     .join(" "),
          // },
          ...(PIR.termSumInsured > PIR.INSURANCEPOLICY.termCoverageAmount.value
            ? {
                termInsurance: {
                  existingCoverage: formatToCurrencyPir(
                    Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
                  )
                    .split(" ")
                    .slice(1)
                    .join(" "),
                  recommendedCoverage: formatToCurrencyPir(
                    Number(
                      calculateRoundOffSumInsuredTerm(
                        formatToCurrency(Number(PIR.termSumInsured)),
                        sumAssuredDataa
                      )
                    )
                  )
                    .split(" ")
                    .slice(1)
                    .join(" "),
                  shortage: calculateShortOff(
                    formatToCurrencyPir(
                      Number(
                        calculateRoundOffSumInsuredTerm(
                          formatToCurrency(Number(PIR.termSumInsured)),
                          sumAssuredDataa
                        )
                      )
                    ),
                    formatToCurrencyPir(
                      Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
                    )
                  )
                    .toString()
                    .split(" ")
                    .slice(1)
                    .join(" "),
                },
              }
            : {}),
          ...(PIR.HAVEANYVEHICLE.haveAnyVehicle.value === "Yes"
            ? {
                motorInsurance: PIR.HAVEVEHICLE.map((veh) => {
                  console.log("veh", veh);
                  let today = new Date();
                  let expiryDate = new Date(veh.policyExpiryDate.value as any);
                  let policyStatus =
                    expiryDate < today ? "Expired" : "About to Expire";
                  if (expiryDate.toDateString() === today.toDateString()) {
                    policyStatus = "About to Expire";
                  }
                  return {
                    vehicle: veh.makeModel.value,
                    varient: veh.variantCC.value,
                    policyStatus: policyStatus,
                  };
                }),
              }
            : {}),
          reportLink: window.location.href,
        },
      };
      PIR_FORM_SERVICES.PIR_EMAIL(onSuccess, onError1, bData);
    }
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent xsWidth">
          <Box className="loginPopup">
            <Button
              className="closePopup"
              onClick={() => {
                setOpen(false);
              }}
            />

            <Grid container spacing={3}>
              <Grid xs={12} textAlign="center">
                <img src="./images/customer_login_icon1.svg" alt="" />
              </Grid>
              <Grid xs={12} textAlign="center">
                <h3 className="mb-2">Edit Your Email Details</h3>
              </Grid>
              <Grid xs={12} textAlign="center" position={"relative"}>
                <RKTextField
                  class_name="inputField"
                  title={"Enter Email"}
                  value={emailInputBox.email.value}
                  validation_type="EMAIL"
                  attrName={["emailInputBox", "email"]}
                  // value={email}
                  value_update={updateMasterState}
                  warn_status={emailInputBox.email.warning}
                  error_message={
                    emailInputBox.email.warning
                      ? isEmpty(emailInputBox.email.value)
                        ? "Enter Email"
                        : "Enter Valid Email"
                      : ""
                  }
                  required
                />
                {/* <p style={{ position: "absolute", top: "23px", right: "15px" }}>
                  <Link className="editBtn" onClick={emailEditHandler}>
                    Edit
                  </Link>
                </p> */}
              </Grid>
              <Grid xs={12} textAlign="center" className="ctaBtn">
                <Button
                  onClick={emailHandler}
                  className="greenXlBtn"
                  style={{ width: "100%" }}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailSendPopup;
