import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  FORMAT_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  calculateAge,
  formatCurrencyAccToUser,
  isEmpty,
  validateEmail,
  validateMobileNumber,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import { useEffect, useState } from "react";
import { subYears } from "date-fns";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  PIR_DATA,
  PIR_DATA_PERSONAL,
  PIR_STEP,
} from "../../../../Store/Slice_Reducer/PIR/PirSlice";
import { TDropdown } from "../../../../Type/Common/TDropdown";

interface IPersonal {
  // step: (s: (v: number) => number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
}
const PIRPersonal = ({ incrementStep, decrementStep }: IPersonal) => {
  const [coverageUptoData, setCoverageUptoData] = useState([
    { value: "", label: "" },
  ]);
  const [sumAssuredDataa, setSumAssuredDataa] = useState([
    { value: "", label: "" },
  ]);
  const { PERSONAL } = useAppSelector((state) => state.PIR);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const [personal, setPersonal] = useState<{
    firstname: { value: string | any; warning: boolean };
    lastname: { value: string | any; warning: boolean };
    mobile: { value: string | any; warning: boolean };
    email: { value: string | any; warning: boolean };
    pincode: { value: string | any; warning: boolean };
    gender: { value: string; warning: boolean };
    dob: { value: Date | null; warning: boolean };
    incomeAmount: { value: number; warning: boolean };
  }>({
    firstname: { value: "", warning: false },
    lastname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    pincode: { value: "", warning: false },
    gender: { value: "", warning: false },
    dob: { value: null, warning: false },
    incomeAmount: { value: 0, warning: false },
  });

  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const convertDateFormat = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };
  const convertDateToOriginalFormat = (dateString: string | null) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const updateMasterState = (attrName: string[], value: string) => {
    let newValue: string | number = value;
    if (attrName[0] === "personal") {
      let newValue: string | number = value;
      if (["dob"].includes(attrName[1])) {
        newValue = convertDateFormat(value);
      }
      if (["incomeAmount"].includes(attrName[1])) {
        // newValue = value.replaceAll(",", "");
        // const data = newValue.split(" ");

        // if (data[1]) {
        //   newValue = +data[1];
        // }
        const d = value.split(" ");

        newValue = +d[1];
      }
      setPersonal((prev) => ({
        ...prev,
        [attrName[1]]: { value: newValue, warning: isEmpty(value) },
      }));
    }
  };
  useEffect(() => {
    setPersonal(PERSONAL);
  }, [PERSONAL]);
  const handlePrevious = () => {
    // step((s: number) => s - 1);
    // setPersonal(PERSONAL);
    decrementStep();
  };
  const handleContinue = () => {
    let local_personal_state = { ...personal };

    local_personal_state.gender = {
      ...local_personal_state.gender,
      warning: !personal.gender.value,
    };
    local_personal_state.dob = {
      ...local_personal_state.dob,
      warning: !personal.dob.value,
    };
    local_personal_state.firstname = {
      ...local_personal_state.firstname,
      warning: !personal.firstname.value,
    };
    // local_personal_state.lastname = {
    //   ...local_personal_state.lastname,
    //   warning: !personal.lastname.value,
    // };
    local_personal_state.email = {
      ...local_personal_state.email,
      warning:
        !validateEmail(personal.email.value) ||
        personal.email.value.includes(" "),
    };
    local_personal_state.mobile = {
      ...local_personal_state.mobile,
      warning: !validateMobileNumber(personal.mobile.value),
    };
    local_personal_state.pincode = {
      ...local_personal_state.pincode,
      warning: !validatePincode(personal.pincode.value),
    };
    local_personal_state.incomeAmount = {
      ...local_personal_state.incomeAmount,
      warning:
        !personal.incomeAmount.value ||
        personal.incomeAmount.value < 200000 ||
        personal.incomeAmount.value > 90000000,
    };
    let isValid = Object.values(local_personal_state).every(
      (field) => !field.warning
    );
    setPersonal(local_personal_state);

    // return isValid;
    if (isValid) {
      // step((s: number) => s + 1);

      dispatch(PIR_DATA_PERSONAL(personal));
      incrementStep();
    }
  };

  const formatCurrency = (value: number) => {
    const stringValue = value.toString();
    const ruppeeValue = "₹ ";

    if (stringValue === "" || stringValue === "0") {
      return ruppeeValue.concat("0");
    }
    return ruppeeValue.concat(stringValue);
    // return `${("₹", value.toString())}`;
  };
  const data = DROPDOWN_DATA.ANNUAL_INCOME.map((e) => {
    return {
      label: ` ${e.label}`,
      value: `₹ ${e.value}`,
    };
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <h2>Fill Personal Details</h2>
          <p className="mt-4">
            To ensure the report reflects your unique requirements, I'll need
            some details from you. Your information helps tailor the
            recommendations and insights specifically to your situation, making
            this report a precise fit for your insurance goals and preferences.
          </p>

          <Box
            margin="24px -16px"
            sx={{ ".MuiFormLabel-asterisk": { color: "#D9132E" } }}
          >
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Full Name"}
                  validation_type="NAME"
                  value={personal.firstname.value}
                  attrName={["personal", "firstname"]}
                  value_update={updateMasterState}
                  warn_status={personal.firstname.warning}
                  error_message={"Enter Full Name"}
                  required
                  max_length={50}
                  // alphabet_only={true}
                />
              </Grid>
              {/* <Grid xs={12} md={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Last Name"}
                  validation_type="FIRSTNAME"
                  value={personal.lastname.value}
                  attrName={["personal", "lastname"]}
                  value_update={updateMasterState}
                  warn_status={personal.lastname.warning}
                  error_message={" Enter last Name"}
                  required
                />
              </Grid> */}

              <Grid xs={12} md={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  validation_type="EMAIL"
                  value={personal.email.value}
                  attrName={["personal", "email"]}
                  value_update={updateMasterState}
                  warn_status={personal.email.warning}
                  error_message={
                    isEmpty(personal.email.value)
                      ? "Enter Email"
                      : "Enter Valid Email "
                  }
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={personal.mobile.value}
                  attrName={["personal", "mobile"]}
                  value_update={updateMasterState}
                  warn_status={personal.mobile.warning}
                  error_message={
                    isEmpty(personal.mobile.value)
                      ? "Enter Mobile Number"
                      : "Enter Valid mobile number"
                  }
                  max_length={10}
                  validation_type="NUMBER"
                  required
                />
              </Grid>

              <Grid xs={12} md={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Pincode"}
                  value={personal.pincode.value}
                  attrName={["personal", "pincode"]}
                  value_update={updateMasterState}
                  warn_status={personal.pincode.warning}
                  error_message={
                    isEmpty(personal.pincode.value)
                      ? "Enter Pincode"
                      : "Enter Valid pincode"
                  }
                  max_length={6}
                  validation_type="NUMBER"
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Gender"
                  value={personal.gender.value}
                  attrName={["personal", "gender"]}
                  value_update={updateMasterState}
                  data={gender_data}
                  custom="custom check"
                  warn_status={personal.gender.warning}
                  error_message="Select Gender"
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <DatePicker
                  class_name="inputField"
                  title={"Dob"}
                  value={convertDateToOriginalFormat(
                    personal.dob.value as unknown as string
                  )}
                  attrName={["personal", "dob"]}
                  value_update={updateMasterState}
                  error_message="Select  Dob"
                  warn_status={personal.dob.warning}
                  min_date={110}
                  max_date={18}
                  date_validation_type="YEARS"
                  default_date={subYears(new Date(), 18)}
                  required
                />
              </Grid>
              {/* <Grid xs={12} sm={6} className="bottomSpaceNone">
                <h6>What's your annual income?</h6>
              </Grid> */}
              <Grid xs={12} md={6}>
                {/* <RKTextField
                  class_name="inputField"
                  title={"Annual Income Amount"}
                  validation_type="NUMERIC"
                  // value={formatCurrencyAccToUser(personal.incomeAmount.value)}
                  value={
                    personal.incomeAmount.value
                      ? formatCurrencyAccToUser(personal.incomeAmount.value)
                      : ""
                  }
                  attrName={["personal", "incomeAmount"]}
                  value_update={updateMasterState}
                  warn_status={personal.incomeAmount.warning}
                  error_message={"Enter 2 lakhs min"}
                  required
                  max_length={13}
                 
                /> */}
                <SelectDropdown
                  class_name="inputField"
                  title={"Enter Annual Income"}
                  value={
                    personal.incomeAmount.value
                      ? formatCurrency(personal.incomeAmount.value)
                      : ""
                  }
                  attrName={["personal", "incomeAmount"]}
                  value_update={updateMasterState}
                  warn_status={personal.incomeAmount.warning}
                  error_message={"Select Amount"}
                  data={data}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} md={6} textAlign="center" className="mHide">
          <img
            src="./images/fill-details.svg"
            width="100%"
            style={{ maxWidth: "430px" }}
            alt="img"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Box className="ctaBtn my-6 bottomButtons" display="flex" gap="24px">
          <Buttons
            onClickFunction={handlePrevious}
            class_name="textMdBtn"
            text_name="Previous"
          />
          <Buttons
            onClickFunction={handleContinue}
            class_name="greenMdBtn roundedBtn"
            text_name="Continue"
            loading={false}
          />
        </Box>
      </Grid>
    </>
  );
};

export default PIRPersonal;
