import { PayloadAction } from "@reduxjs/toolkit";
import { TTWSlice } from "../../../Type/TTWSlice";
import { TTWProduct } from "../../../Type/TwoWheeler/TTWProduct";

const SET_SELECTED_QUOTE_DATA = (
  state: TTWSlice,
  action: PayloadAction<TTWProduct>
) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const SAVE_ALL_QUOTES_DATA = (
  state: TTWSlice,
  action: PayloadAction<TTWProduct[]>
) => {
  const data = {
    ...state,
    ALL_QUOTES_DATA: action.payload,
  };

  return data;
};

const UPDATE_SORT_BY = (
  state: TTWSlice,
  action: PayloadAction<"LOW_TO_HIGH" | "HIGH_TO_LOW">
) => {
  const data = {
    ...state,
    SORT_BY: action.payload,
  };

  return data;
};
const OLD_QUOTE_DATA = (state: TTWSlice, action: PayloadAction<TTWProduct>) => {
  console.log("action.payload", action.payload);
  const data = {
    ...state,
    OLD_QUOTE_DATA: action.payload,
  };

  return data;
};
const SET_PROCESS_DATA = (
  state: TTWSlice,
  action: PayloadAction<Array<any>>
) => {
  console.log(action.payload, "ooooo");
  const data = {
    ...state,
    PROCESS_DATA: action.payload,
  };

  return data;
};
export const TW_QUOTE_REDUCERS = {
  SET_SELECTED_QUOTE_DATA,
  SAVE_ALL_QUOTES_DATA,
  UPDATE_SORT_BY,
  OLD_QUOTE_DATA,
  SET_PROCESS_DATA,
};

export type TWQuoteReducers = typeof TW_QUOTE_REDUCERS;
