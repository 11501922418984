import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { EHomeSectionName } from "../../../Services/Enum/EHome";
import { useAppSelector } from "../../../Store/hooks";
import {
  TAwards,
  THowItWorks,
  TOurClients,
  TPartners,
  TProducts,
  TTestimonials,
  TTopRated,
} from "../../../Type/Home/THomePage";
import { FRONTEND_DOMAIN, STRAPI_DOMAIN } from "../../../URLCollection/Domain";
import "./HomePage.scss";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";

// Lazy-loaded components
const Footer = lazy(() => import("../../../Component/Desktop/Footer/Footer"));
const Navbar = lazy(() => import("../../../Component/Desktop/Navbar/Navbar"));
const HowItWorks = lazy(() => import("./HowItWorks/HowItWorks"));
const Awards = lazy(() => import("./Awards/Awards"));
const TopRated = lazy(() => import("./TopRated/TopRated"));
const OurClients = lazy(() => import("./OurClients/OurClients"));
const OurProducts = lazy(() => import("./OurProducts/OurProducts"));
const Testimonial = lazy(() => import("./Testimonial/Testimonial"));
const OurPartners = lazy(() => import("./OurPartners/OurPartners"));

const HomePage = ({ navigateForm }: { navigateForm: Function }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { Home } = useAppSelector((state) => state);
  const [arrayOfSection, setArrayOfSection] = useState(Object.entries(Home));

  useEffect(() => {
    setArrayOfSection(Object.entries(Home));
  }, [Home]);

  const returnSectionByIndex = (index: number) => {
    const visibleSection = arrayOfSection.find(
      ([, section]) =>
        (
          section as
            | THowItWorks
            | TTopRated
            | TOurClients
            | TAwards
            | TProducts
            | TTestimonials
            | TPartners
        )?.section_index === index &&
        (
          section as
            | THowItWorks
            | TTopRated
            | TOurClients
            | TAwards
            | TProducts
            | TTestimonials
            | TPartners
        )?.visible === true
    );

    if (visibleSection) {
      switch (
        (
          visibleSection[1] as
            | THowItWorks
            | TTopRated
            | TOurClients
            | TAwards
            | TProducts
            | TTestimonials
            | TPartners
        )?.section_name
      ) {
        case EHomeSectionName.HOW_IT_WORKS:
          return <HowItWorks />;
        case EHomeSectionName.AWARDS:
          return <Awards />;
        case EHomeSectionName.TOP_RATED:
          return <TopRated />;
        case EHomeSectionName.OUR_CLIENTS:
          return <OurClients />;
        case EHomeSectionName.OUR_PRODUCTS:
          return <OurProducts />;
        case EHomeSectionName.TESTIMONIALS:
          return <Testimonial />;
        case EHomeSectionName.OUR_PARTNERS:
          return <OurPartners />;

        default:
          return null;
      }
    } else {
      return null;
    }
  };

  console.log(Home.our_products.data.data, "Home");

  return (
    <Box className="HomePageWrapper">
      <Helmet>
        <title>Car, Bike, Health, Term & Travel Insurance | Bimastreet</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Protect your future with the best car, bike, health, term, and travel insurance. Discover affordable, comprehensive plans with hassle-free claim assistance."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Two wheeler insurance online, Affordable Bike Insurance, Comprehensive Term Insurance, Affordable Car Insurance, Affordable Health Insurance, Comprehensive Travel Insurance , Insurance Plans, Bimastreet Insurance"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bimastreet" />
        <meta
          property="og:title"
          content="Car, Bike, Health, Term & Travel Insurance | Bimastreet"
        />
        <meta
          property="og:description"
          content="Get top-rated insurance plans for car, bike, health, term, and travel. Affordable coverage with free claim assistance."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FRONTEND_DOMAIN}${location.pathname}`}
        />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
        <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} />
      </Helmet>

      <Suspense>
        <Navbar />
      </Suspense>
      <div className="head-section">
        <Grid container spacing={3} className="row">
          <Grid xs={8}>
            <h1 dangerouslySetInnerHTML={{ __html: Home?.header?.heading }} />
            <p dangerouslySetInnerHTML={{ __html: Home?.header?.desc }} />
          </Grid>
          <Grid xs={12}>
            <div className="product-section">
              <ul>
                {Home.our_products?.data?.data.map((data, index) => (
                  <li id={data.attributes.type} key={index}>
                    <Link
                      className="product-type"
                      onClick={() => {
                        navigateForm(data.attributes.type);
                      }}
                    >
                      <img
                        src={
                          STRAPI_DOMAIN +
                          data?.attributes?.image?.data?.attributes?.url
                        }
                        alt="img"
                      />
                      <p>{data.attributes.title}</p>
                    </Link>
                  </li>
                ))}
                {process.env.REACT_APP_ENV === "DEV" ||
                process.env.REACT_APP_ENV === "STG" ? (
                  <>
                    <li>
                      <Link
                        className="product-type"
                        onClick={() => {
                          navigateForm("Home Insurance");
                        }}
                      >
                        <img src={"./images/home-ins-icon.svg"} alt="img" />
                        <p>Home Insurance</p>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="product-type"
                        onClick={() => {
                          navigateForm("travel");
                        }}
                      >
                        <img src={"./images/travel-ins-icon.svg"} alt="img" />
                        <p>Travel Insurance</p>
                      </Link>
                    </li>
                  </>
                ) : null}
              </ul>
            </div>
          </Grid>
        </Grid>
        <div className="bikeWrapper">
          <div className="bikebox">
            <img src="images/biker.svg" className="rider" alt="img" />
            <img src="images/hair.svg" className="hair" alt="img" />
          </div>
        </div>

        <div className="carWrapper">
          <div className="carbox">
            <img src="images/car.svg" className="carbody" alt="img" />
            <img src="images/wheel.svg" className="rearWheel" alt="img" />
            <img src="images/wheel.svg" className="frontWheel" alt="img" />
          </div>
        </div>

        <div className="aeroplaneWrapper">
          <div className="aeroplanebox">
            <img src="images/aeroplane.svg" className="" alt="img" />
          </div>
        </div>
      </div>
      {arrayOfSection.map((data, index) => (
        <Suspense key={index}> {returnSectionByIndex(index)}</Suspense>
      ))}
      <Suspense>
        <Footer />
      </Suspense>
    </Box>
  );
};

export default HomePage;
